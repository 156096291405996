<template>
  <div class="configuration-pdf hedifys-21">
    <!-- TABLE (pour avoir un footer sur chaque page) -->
    <table class="report-container" v-if="!isLoading">
      <thead class="report-header">
        <tr>
          <th class="report-header-cell">
            <div class="header-info"></div>
          </th>
        </tr>
      </thead>
      <!-- FOOTER -->
      <tfoot class="report-footer">
        <tr>
          <td class="report-footer-cell">
            <div class="footer-info">
              <div class="footer grid-x">
                <div class="cell shrink">
                  <img class="logo" src="/img/configuration/logo-black-alysia.png" />
                </div>
                <div class="cell auto">
                  <h3>
                    <strong><span>{{ version.name }}</span> / <span class="reference-footer">{{
                      `${version.reference}${
                        optionsReferences && optionsReferences.length
                          ? `_${optionsReferences}`
                          : ""
                      }`
                    }}</span></strong>
                  </h3>
                  <p>
                    <strong class="upper"
                      >Descriptif du projet maison "{{ getCustomersNames(customer) }}" -
                      version "{{ projectDraft.name }}"
                      {{ $dayjs(new Date()).format("DD/MM/YYYY") }}</strong
                    >
                  </p>
                  <p>
                    <small
                      >Cette indication de prix est basée sur les tarifs en vigueur le
                      {{ $dayjs(projectDraft.createdAt).format("DD/MM/YYYY") }}, elle ne
                      constitue pas une offre commerciale.</small
                    >
                  </p>
                  <p>
                    <small
                      ><span class="upper">{{ brand.name }}</span> @ Toute reproduction
                      interdite</small
                    >
                  </p>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tfoot>
      <tbody class="report-content">
        <tr>
          <td class="report-content-cell">
            <div class="main">
              <div class="body" v-if="!isLoading">
                <!-- FIRST PAGE - PRESENTATION -->
                <div class="version-presentation page-print">
                  <div class="header grid-x">
                    <div class="cell auto">
                      <img class="logo" src="/img/configuration/logo-black-alysia.png" />
                    </div>
                    <div class="cell auto">
                      <p class="catch-phrase">
                        Le plus court chemin<br />entre vous et votre maison
                      </p>
                    </div>
                  </div>
                  <div class="heading">
                    <h1 class="name">Descriptif du projet maison</h1>
                    <h3>{{ getCustomersNames(customer) }}</h3>
                    <h4>« {{ projectDraft.name }} »</h4>
                  </div>
                  <img :src="version.versionMedia" />

                  <div class="grid-x reference">
                    <div class="cell auto">
                      <p class="label-name">Nom de la version</p>
                      <h3> {{version.name}}</h3>
                      <p class="label-name">Référence de la version</p>
                      <p>
                        {{
                          `${version.reference}${
                            optionsReferences && optionsReferences.length
                              ? `_${optionsReferences}`
                              : ""
                          }`
                        }}
                      </p>
                      <p class="label-name">Pack</p>
                      <h3>{{ projectDraft.pack.name }}</h3>
                      <p class="label-name">Conformité</p>
                      <h3>RE2020</h3>
                    </div>
                  </div>
                  <div class="grid-x bottom">
                    <div
                      class="cell shrink"
                      v-if="projectDraft.land && projectDraft.land.landId"
                    >
                      <p class="label-name">Commune</p>
                      <h3>
                        {{ projectDraft.land.address.city }} ({{
                          projectDraft.land.address.postalCodeInseeNumber
                            ? projectDraft.land.address.postalCodeInseeNumber.split(
                                "-"
                              )[0]
                            : ""
                        }})
                      </h3>
                    </div>
                    <div
                      class="cell shrink"
                      v-if="
                        projectDraft.customerLand &&
                        projectDraft.customerLand.price &&
                        projectDraft.customerLand.width &&
                        projectDraft.customerLand.address
                      "
                    >
                      <p class="label-name">Commune</p>
                      <h3>
                        {{ projectDraft.customerLand.address.city }} ({{
                          projectDraft.customerLand.address.postalCodeInseeNumber
                            ? projectDraft.customerLand.address.postalCodeInseeNumber.split(
                                "-"
                              )[0]
                            : ""
                        }})
                      </h3>
                    </div>
                    <div class="cell auto"></div>
                    <div class="cell shrink right">
                      <h3>
                        {{ projectDraft.reference ? `N°${projectDraft.reference}` : "" }}
                      </h3>
                      <h3>{{ $dayjs(new Date()).format("DD/MM/YYYY") }}</h3>
                    </div>
                  </div>
                </div>

                <!-- SECOND PAGE - YOUR PROJECT -->
                <div class="project-introduction page-print">
                  <div class="page-title">
                    <h1>Votre projet</h1>
                  </div>
                  <div class="introduction grid-x">
                    <div class="cell auto">
                      <p>
                        <strong
                          >Félicitations ! Vous venez de configurer votre projet de
                          contruction sur notre site... c’est un grand pas qui vous permet
                          d’avancer un peu plus vers la maison de vos rêves</strong
                        >
                      </p>
                      <p>
                        <strong
                          >Cette notice descriptive comprend la totalité des composants
                          tarifaires de votre projet de construction (à valider selon le
                          terrain) accompagnée des frais annexes consignés dans le
                          récapitulatif (tels que les frais de notaire, les taxes, les
                          assurances, les frais de raccordement standard... ).</strong
                        >
                      </p>
                      <p>
                        <strong
                          >Ce document de référence vous sera utile pour rencontrer votre
                          constructeur en agence mais également et par soucis de
                          transparence, pour comparer les offres de nos
                          concurrents.</strong
                        >
                      </p>
                      <p>
                        <strong
                          >Vous souhaitez aller plus loin, retrouvez les coordonnées de
                          votre agence à la fin de ce document.</strong
                        >
                      </p>
                      <p><strong>A très vite </strong></p>
                    </div>
                    <div class="cell auto">
                      <p><strong>Voici le contenu de votre descriptif :</strong></p>
                      <br />
                      <ul>
                        <li><span>Le plan</span></li>
                        <li><span>La distribution (superficie et cotes)</span></li>
                        <li><span>Le descriptif du terrain</span></li>
                      </ul>
                      <br />
                      <p>La notice descriptive comprenant :</p>
                      <ul>
                        <li><span>Les ouvrages et fournitures</span></li>
                        <li><span>Les options</span></li>
                        <li><span>Les aménagements optionnés</span></li>
                      </ul>
                      <br />
                      <ul>
                        <li><span>Le récapitulatif budgétaire</span></li>
                        <li><span>Votre agence Alysia</span></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <!-- BLUEPRINTS -->
                <div
                  class="floor-blueprint page-print"
                  v-for="(floor, index) in floors"
                  :key="floor.floorId"
                >
                  <div class="page-title">
                    <h1>{{ floor.name }}</h1>
                  </div>
                  <div class="centered">
                    <img
                      v-if="blueprints && blueprints.length > index"
                      :src="blueprints[index].url"
                    />
                  </div>
                  <p class="note">
                    <strong>A NOTER : </strong>Ce plan est celui du modèle de base. Il n’a
                    pas de valeur contractuelle. Il est donné à titre indicatif. Il ne
                    tient pas compte des éventuels agrandissements effectués dans le
                    configurateur. Selon vos besoins et vos souhaits, nous vous invitons à
                    étudier les éventuelles possibilités de personnalisation avec votre
                    constructeur Maisons Alysia.
                  </p>
                </div>

                <!-- SURFACES -->
                <div
                  class="surfaces-page page-print"
                  v-if="
                    projectDraft && projectDraft.surfaces && projectDraft.surfaces.length
                  "
                >
                  <div class="page-title">
                    <h1>Distribution</h1>
                  </div>
                  <h2>Superficies Habitables</h2>
                  <div
                    class="grid-x line"
                    v-for="surface in projectDraft.surfaces.filter(
                      (surface) => surface.room.type === 'HABITABLE' && surface.area > 0
                    )"
                    :key="surface.surfaceId"
                  >
                    <div class="cell shrink">
                      <p>{{ surface.room.name }}</p>
                    </div>
                    <div class="cell auto"></div>
                    <div v-if="surface.extensionSurface" class="cell shrink">
                      <p>
                        <strong>{{ surface.extensionSurface.toFixed(2) }} m²</strong>
                      </p>
                    </div>
                    <div v-else class="cell shrink">
                      <p>
                        <strong
                          >{{ surface.area ? surface.area.toFixed(2) : 0 }} m²</strong
                        >
                      </p>
                    </div>
                  </div>
                  <div class="total grid-x">
                    <div class="cell shrink">
                      <h4>Total habitable</h4>
                    </div>
                    <div class="cell auto"></div>
                    <div class="cell shrink">
                      <h4>{{ totalHabitable }} m²</h4>
                    </div>
                  </div>
                  <h2
                    v-if="
                      projectDraft.surfaces.find(
                        (surface) => surface.room.type !== 'HABITABLE'
                      )
                    "
                  >
                    Superficies annexes
                  </h2>
                  <div
                    class="grid-x line"
                    v-for="surface in projectDraft.surfaces.filter(
                      (surface) => surface.room.type !== 'HABITABLE'
                    )"
                    :key="surface.surfaceId"
                  >
                    <div class="cell shrink">
                      <p>{{ surface.room.name }}</p>
                    </div>
                    <div class="cell auto"></div>
                    <div class="cell shrink">
                      <p v-if="surface.extensionSurface">
                        {{ surface.extensionSurface.toFixed(2) }} m²
                      </p>
                      <p v-else>{{ surface.area ? surface.area.toFixed(2) : 0 }} m²</p>
                    </div>
                  </div>
                  <h2>Façade</h2>
                  <div class="grid-x line">
                    <div class="cell shrink"><p>largeur</p></div>
                    <div class="cell auto"></div>
                    <div class="cell shrink">
                      <p>
                        <strong
                          >{{
                            (
                              version.width +
                              (firstExtensionCustomerWidth
                                ? firstExtensionCustomerWidth
                                : 0) +
                              (secondExtensionCustomerWidth
                                ? secondExtensionCustomerWidth
                                : 0)
                            ).toFixed(2)
                          }}
                          m</strong
                        >
                      </p>
                    </div>
                  </div>
                  <br />
                  <p class="note">
                    Ce descriptif est celui du modèle de base ou adapté par un
                    agrandissement. Il n’a pas de valeur contractuelle. Il est donné à
                    titre indicatif.
                  </p>
                </div>

                <!-- LAND -->
                <div
                  class="land-page page-print"
                  v-if="
                    !(
                      projectDraft.customerLand &&
                      projectDraft.customerLand.surface === 0 &&
                      projectDraft.customerLand.price === 0
                    )
                  "
                >
                  <div class="page-title">
                    <h1>Terrain</h1>
                  </div>
                  <div
                    class="grid-x line"
                    v-if="
                      (projectDraft.customerLand && projectDraft.customerLand.address) ||
                      (projectDraft.land &&
                        projectDraft.land.landId &&
                        projectDraft.land.address)
                    "
                  >
                    <div class="cell shrink"><p>Nom de la commune</p></div>
                    <div class="cell auto"></div>
                    <div class="cell shrink">
                      <p
                        v-if="
                          projectDraft.customerLand && projectDraft.customerLand.address
                        "
                      >
                        <strong>{{ projectDraft.customerLand.address.city }}</strong>
                      </p>
                      <p v-else-if="projectDraft.land && projectDraft.land.address">
                        <strong>{{ projectDraft.land.address.city }}</strong>
                      </p>
                    </div>
                  </div>
                  <div
                    class="grid-x line"
                    v-if="
                      projectDraft.land &&
                      projectDraft.land.landId &&
                      projectDraft.land.allotment
                    "
                  >
                    <div class="cell shrink"><p>Nom du lotissement</p></div>
                    <div class="cell auto"></div>
                    <div class="cell shrink">
                      <p>
                        <strong>"{{ projectDraft.land.allotment.name }}"</strong>
                      </p>
                    </div>
                  </div>
                  <div
                    class="grid-x line"
                    v-if="
                      projectDraft.land &&
                      projectDraft.land.landId &&
                      projectDraft.land.lotNumber
                    "
                  >
                    <div class="cell shrink"><p>N° du lot</p></div>
                    <div class="cell auto"></div>
                    <div class="cell shrink">
                      <p>
                        <strong>N° {{ projectDraft.land.lotNumber }}</strong>
                      </p>
                    </div>
                  </div>
                  <div
                    class="grid-x line"
                    v-if="
                      (projectDraft.land && projectDraft.land.surface) ||
                      (projectDraft.customerLand && projectDraft.customerLand.surface)
                    "
                  >
                    <div class="cell shrink"><p>Superficie</p></div>
                    <div class="cell auto"></div>
                    <div class="cell shrink">
                      <p
                        v-if="
                          projectDraft.customerLand && projectDraft.customerLand.surface
                        "
                      >
                        <strong>{{ projectDraft.customerLand.surface }} m²</strong>
                      </p>
                      <p v-else-if="projectDraft.land && projectDraft.land.surface">
                        <strong>{{ projectDraft.land.surface }} m²</strong>
                      </p>
                    </div>
                  </div>
                  <div
                    class="grid-x line"
                    v-if="
                      (projectDraft.land && projectDraft.land.width) ||
                      (projectDraft.customerLand && projectDraft.customerLand.width)
                    "
                  >
                    <div class="cell shrink"><p>Largeur de façade</p></div>
                    <div class="cell auto"></div>
                    <div class="cell shrink">
                      <p
                        v-if="
                          projectDraft.customerLand && projectDraft.customerLand.width
                        "
                      >
                        <strong>{{ projectDraft.customerLand.width }} m</strong>
                      </p>
                      <p v-else-if="projectDraft.land && projectDraft.land.width">
                        <strong>{{ projectDraft.land.width }} m</strong>
                      </p>
                    </div>
                  </div>
                  <div class="total grid-x">
                    <div class="cell shrink">
                      <h4>Prix</h4>
                    </div>
                    <div class="cell auto"></div>
                    <div class="cell shrink">
                      <h4
                        v-if="
                          projectDraft.customerLand && projectDraft.customerLand.price
                        "
                      >
                        {{
                          utils.formatCentToEuro(
                            projectDraft.customerLand.price,
                            true,
                            true
                          )
                        }}
                        TTC
                      </h4>
                      <h4 v-else-if="projectDraft.land && projectDraft.land.price">
                        {{
                          utils.formatCentToEuro(projectDraft.land.price, true, true)
                        }}
                        TTC
                      </h4>
                      <h4 v-else>0 € TTC</h4>
                    </div>
                  </div>
                  <p class="note">
                    Sous réserve de disponibilité du terrain chez notre partenaire foncier
                    ; Maisons Alysia n'est pas le vendeur du terrain et n'est pas mandaté
                    pour réaliser la vente du terrain.
                    <br /><br />
                    En cas d’indisponibilité de terrain référencé sur la commune le tarif
                    indiqué dans le projet est issu de la base de données cadastrales
                    constaté en n-1.
                    <br /><br />
                    Nos terrains sont majoritairement des terrains en lotissements donc
                    prêt-à-bâtir et viabilisés (chaque lot est déjà raccordé aux réseaux
                    de distribution : eau, électricité, Télécoms). Si vous avez effectué
                    votre projet dans notre configurateur avec votre propre terrain ou un
                    terrain simplement optionné, votre constructeur Maisons Alysia en
                    évaluera la viabilité et la validera lors de la visite terrain.
                  </p>
                </div>
                <!-- CATEGORIES -->
                <div class="category-page page-print">
                  <div class="page-title">
                    <h1>Ouvrages et fournitures</h1>
                  </div>
                  <p>Compris dans le prix convenu (intégrés au contrat)</p>
                  <div class="categories">
                    <div
                      class="category"
                      v-for="category in checkCategories()"
                      :key="category.categoryId"
                    >
                      <div class="category-name grid-x">
                        <div class="cell auto">
                          <h1>{{ category.name }}</h1>
                        </div>
                        <div class="cell shrink">
                          <img v-if="category.icon" :src="category.icon" />
                        </div>
                      </div>
                      <div
                        class="lot grid-x"
                        v-for="lot in lots.filter(
                          (l) => l.categoryId === category.categoryId && l.lotId !== 'b7f6d4be-5cc7-472b-9bea-44d99d35dc35' && l.lotId !== 'a6fef712-5d4a-46f4-8866-c92e16baf391'
                        )"
                        :key="lot.lotId"
                      >
                        <div class="cell shrink">
                          <img class="rounded" :src="lot.image" alt="" />
                        </div>
                        <div class="cell auto">
                          <h2>{{ lot.name }}</h2>
                          <p class="text-with-break">
                            <strong>{{ lot.description }}</strong>
                          </p>
                          <p
                            v-for="lotSurvey in lot.lotSurveys"
                            :key="lotSurvey.lotSurveyId"
                            class="text-with-break"
                          >
                            <strong>{{ lotSurvey.description }}</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="total grid-x">
                    <div class="cell shrink">
                      <h4>Total coût initial de la maison</h4>
                    </div>
                    <div class="cell auto"></div>
                    <div class="cell shrink">
                      <h4>
                        {{
                          projectDraft.initialPrice
                            ? utils.formatCentToEuro(
                                projectDraft.initialPrice -
                                  (projectDraft.land && projectDraft.land.priceLandAdaptation
                                    ? projectDraft.land.priceLandAdaptation
                                    : 0) -
                                  (projectDraft.land && projectDraft.land.priceLocalRegulation
                                    ? projectDraft.land.priceLocalRegulation
                                    : 0) -
                                  (projectDraft.extensionsProperties.price
                                    ? projectDraft.extensionsProperties.price
                                    : 0),
                                true
                              )
                            : "0 €"
                        }}
                      </h4>
                    </div>
                  </div>
                  <div v-if="projectDraft.land && (projectDraft.land.priceLandAdaptation || projectDraft.land.priceLocalRegulation)" class="category">
                    <div v-if="!lots.find((l) => l.lotId === '39ec5b82-34a3-4627-b7e6-0960113697ac' || l.lotId === 'f867381d-16c8-4f26-b81a-abbf0a67eb5f')" class="category-name grid-x">
                      <div class="cell auto">
                        <h1 v-if="categories.find((c) => c.categoryId === 'c68c8545-09fd-4d57-a013-d4bfcfb673c2')">{{ categories.find((c) => c.categoryId === 'c68c8545-09fd-4d57-a013-d4bfcfb673c2').name }}</h1>
                      </div>
                      <div class="cell shrink">
                        <img v-if="categories.find((c) => c.categoryId === 'c68c8545-09fd-4d57-a013-d4bfcfb673c2')" :src="categories.find((c) => c.categoryId === 'c68c8545-09fd-4d57-a013-d4bfcfb673c2').icon" />
                      </div>
                    </div>
                    <div class="lot grid-x">
                      <div class="cell shrink">
                        <img v-if="lots.find((l) => l.lotId === 'b7f6d4be-5cc7-472b-9bea-44d99d35dc35')" class="rounded" :src="lots.find((l) => l.lotId === 'b7f6d4be-5cc7-472b-9bea-44d99d35dc35').image" alt="" />
                      </div>
                      <div class="cell auto" v-if="lots.find((l) => l.lotId === 'b7f6d4be-5cc7-472b-9bea-44d99d35dc35')">
                        <h2 v-if="lots.find((l) => l.lotId === 'b7f6d4be-5cc7-472b-9bea-44d99d35dc35')">{{ lots.find((l) => l.lotId === 'b7f6d4be-5cc7-472b-9bea-44d99d35dc35').name }}</h2>
                        <p class="text-with-break">
                          <strong v-if="lots.find((l) => l.lotId === 'b7f6d4be-5cc7-472b-9bea-44d99d35dc35')">{{ lots.find((l) => l.lotId === 'b7f6d4be-5cc7-472b-9bea-44d99d35dc35').description }}</strong>
                        </p>
                      </div>
                    </div>
                    <div class="total grid-x">
                      <div class="cell shrink">
                        <h4>Frais d'adaptation au terrain</h4>
                      </div>
                      <div class="cell auto"></div>
                      <div class="cell shrink">
                        <h4>
                          {{
                            projectDraft.land && projectDraft.land.priceLandAdaptation
                              ? utils.formatCentToEuro(projectDraft.land.priceLandAdaptation, true)
                              : "0 €"
                          }}
                        </h4>
                      </div>
                    </div>
                    <div class="lot grid-x">
                      <div class="cell shrink">
                        <img v-if="lots.find((l) => l.lotId === 'a6fef712-5d4a-46f4-8866-c92e16baf391')" class="rounded" :src="lots.find((l) => l.lotId === 'a6fef712-5d4a-46f4-8866-c92e16baf391').image" alt="" />
                      </div>
                      <div class="cell auto">
                        <h2 v-if="lots.find((l) => l.lotId === 'a6fef712-5d4a-46f4-8866-c92e16baf391')">{{ lots.find((l) => l.lotId === 'a6fef712-5d4a-46f4-8866-c92e16baf391').name }}</h2>
                        <p class="text-with-break">
                          <strong v-if="lots.find((l) => l.lotId === 'a6fef712-5d4a-46f4-8866-c92e16baf391')">{{ lots.find((l) => l.lotId === 'a6fef712-5d4a-46f4-8866-c92e16baf391').description }}</strong>
                        </p>
                      </div>
                    </div>
                    <div class="total grid-x">
                      <div class="cell shrink">
                        <h4>Frais règlementation locale</h4>
                      </div>
                      <div class="cell auto"></div>
                      <div class="cell shrink">
                        <h4>
                          {{
                            projectDraft.land && projectDraft.land.priceLocalRegulation
                              ? utils.formatCentToEuro(projectDraft.land.priceLocalRegulation, true)
                              : "0 €"
                          }}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="extensions-page page-print"
                  v-if="firstExtensionCustomerWidth || secondExtensionCustomerWidth"
                >
                  <div class="page-title">
                    <h1>Agrandissement</h1>
                  </div>
                  <p>
                    <strong
                      >Cet agrandissement manuel ou automatique du plan de la maison a été
                      eff ectué dans le configurateur. Ce plan modifi é est à valider
                      selon le terrain par votre constructeur (selon la réglementation en
                      vigueur dans le lotissement, si vous possédez déjà un terrain, ou si
                      vous n'en avez pas associé à votre projet).</strong
                    >
                  </p>
                  <div class="grid-x main-grid">
                    <div class="cell auto image-container">
                      <div v-if="blueprints && blueprints.length">
                        <img
                          v-for="blueprint in blueprints"
                          :src="blueprint.url"
                          :key="blueprint.versionMediaId"
                        />
                        <div
                          class="line-container"
                          ref="firstExtension"
                          v-if="
                            version.isFirstExtensionActive &&
                            blueprints &&
                            blueprints.length
                          "
                          :style="{
                            left: `${
                              ((version.firstExtensionBlueprintPosition + 12) * 300) / 360
                            }px`,
                          }"
                        >
                          <div class="line"></div>
                          <div class="slider-container grid-x">
                            <div class="slider">1</div>
                          </div>
                        </div>
                        <div
                          class="line-container"
                          ref="secondExtension"
                          v-if="
                            version.isSecondExtensionActive &&
                            blueprints &&
                            blueprints.length
                          "
                          :style="{
                            left: `${
                              ((version.secondExtensionBlueprintPosition + 12) * 300) /
                              360
                            }px`,
                          }"
                        >
                          <div class="line"></div>
                          <div class="slider-container grid-x">
                            <div class="slider">2</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="cell auto">
                      <div v-if="firstExtensionCustomerWidth > 0">
                        <h2>Agrandissement ligne 1</h2>
                        <div class="grid-x total-extension">
                          <div class="cell auto">
                            <p><strong>Longueur d'agrandissement 1</strong></p>
                          </div>
                          <div class="cell shrink">
                            <p>
                              <strong
                                >{{ firstExtensionCustomerWidth.toFixed(2) }} m</strong
                              >
                            </p>
                          </div>
                        </div>
                        <div
                          class="grid-x surface line"
                          v-for="surface in projectDraft.surfaces.filter(
                            (surface) =>
                              surface.firstExtensionDepth &&
                              surface.firstExtensionDepth > 0
                          )"
                          :key="surface.surfaceId"
                        >
                          <div class="cell auto">
                            <p>{{ surface.room.name }}</p>
                          </div>
                          <div class="cell shrink">
                            <p v-if="surface.extensionSurface">
                              {{ surface.extensionSurface.toFixed(2) }} m²
                            </p>
                            <p v-else>
                              {{ surface.area ? surface.area.toFixed(2) : 0 }} m²
                            </p>
                          </div>
                        </div>
                      </div>
                      <div v-if="secondExtensionCustomerWidth > 0">
                        <h2>Agrandissement ligne 2</h2>
                        <div class="grid-x total-extension">
                          <div class="cell auto">
                            <p><strong>Longueur d'agrandissement 2</strong></p>
                          </div>
                          <div class="cell shrink">
                            <p>
                              <strong
                                >{{ secondExtensionCustomerWidth.toFixed(2) }} m</strong
                              >
                            </p>
                          </div>
                        </div>
                        <div
                          class="grid-x surface line"
                          v-for="surface in projectDraft.surfaces.filter(
                            (surface) =>
                              surface.secondExtensionDepth &&
                              surface.secondExtensionDepth > 0
                          )"
                          :key="surface.surfaceId"
                        >
                          <div class="cell auto">
                            <p>{{ surface.room.name }}</p>
                          </div>
                          <div class="cell shrink">
                            <p v-if="surface.extensionSurface">
                              {{ surface.extensionSurface.toFixed(2) }} m²
                            </p>
                            <p v-else>
                              {{ surface.area ? surface.area.toFixed(2) : 0 }} m²
                            </p>
                          </div>
                        </div>
                      </div>
                      <h2>Façade</h2>
                      <div class="grid-x total-extension">
                        <div class="cell auto">
                          <p><strong>Largeur</strong></p>
                        </div>
                        <div class="cell shrink">
                          <p>
                            <strong
                              >{{
                                (
                                  version.width +
                                  (firstExtensionCustomerWidth
                                    ? firstExtensionCustomerWidth
                                    : 0) +
                                  (secondExtensionCustomerWidth
                                    ? secondExtensionCustomerWidth
                                    : 0)
                                ).toFixed(2)
                              }}
                              m</strong
                            >
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="total grid-x">
                    <div class="cell shrink">
                      <h4>Total coût d'agrandissement</h4>
                    </div>
                    <div class="cell auto"></div>
                    <div class="cell shrink">
                      <h4>
                        {{
                          getExtensionPrice()
                            ? utils.formatCentToEuro(getExtensionPrice(), true)
                            : "0 €"
                        }}
                      </h4>
                    </div>
                  </div>
                </div>
                <!-- OPTIONS CUSTOMER -->
                <div
                  class="options-page page-print"
                  v-if="
                    projectDraft.selectedOptions.find(
                      (option) =>
                        option.isActive &&
                        option.theme.isContractIncluded &&
                        (option.theme.themeId !== '9ab2cfd7-3e28-4e2b-a26e-b23c97009b5d' &&
                         option.theme.themeId !== '1c96da9a-2555-488e-b082-819be2eade35' &&
                         option.theme.themeId !== '33c0bd60-3143-45db-a5dc-a1d89d8142f8' &&
                         option.theme.themeId !== 'eabbf99c-72c9-4330-9308-d0babe538212' &&
                         option.theme.themeId !== '590604eb-127f-4fe6-a35e-a9ff549a4875') &&
                        option.type === 'STANDARD'
                    )
                  "
                >
                  <div class="page-title">
                    <h1>Options</h1>
                  </div>
                  <p>Options choisies en remplacement de la notice de base.</p>
                  <div class="options">
                    <div
                      class="option grid-x"
                      v-for="option in projectDraft.selectedOptions.filter(
                        (option) =>
                          option.isActive &&
                          option.theme.isContractIncluded &&
                          (option.theme.themeId !== '9ab2cfd7-3e28-4e2b-a26e-b23c97009b5d' &&
                           option.theme.themeId !== '1c96da9a-2555-488e-b082-819be2eade35' &&
                           option.theme.themeId !== '33c0bd60-3143-45db-a5dc-a1d89d8142f8' &&
                           option.theme.themeId !== 'eabbf99c-72c9-4330-9308-d0babe538212' &&
                           option.theme.themeId !== '590604eb-127f-4fe6-a35e-a9ff549a4875') &&
                          option.type === 'STANDARD'
                      )"
                      :key="option.optionId"
                    >
                      <div class="cell shrink">
                        <img
                          v-if="option.image"
                          class="rounded"
                          :src="option.image"
                          alt=""
                        />
                        <div v-else class="rounded empty" alt=""></div>
                      </div>
                      <div class="cell auto">
                        <h2>{{ option.name }}</h2>
                        <p class="text-with-break">{{ option.description }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="total grid-x">
                    <div class="cell shrink">
                      <h4>Total options et équipements</h4>
                    </div>
                    <div class="cell auto"></div>
                    <div class="cell shrink">
                      <h4>
                        {{
                          utils.formatCentToEuro(
                            projectDraft.selectedOptions.reduce(
                              (a, option) =>
                                option.isActive &&
                                option.theme.isContractIncluded &&
                                option.category.categoryId !==
                                  "c68c8545-09fd-4d57-a013-d4bfcfb673c2" &&
                                option.type === "STANDARD"
                                  ? a + option.price
                                  : a,
                              0
                            ),
                            true
                          )
                        }}
                      </h4>
                    </div>
                  </div>
                </div>

                <!-- OPTIONS CUSTOMER - HORS CONTRAT -->
                <div
                  class="options-page page-print"
                  v-if="
                    projectDraft.selectedOptions.find(
                      (option) =>
                        option.isActive &&
                        !option.theme.isContractIncluded &&
                        option.category.categoryId !==
                          'c68c8545-09fd-4d57-a013-d4bfcfb673c2' &&
                        option.type === 'STANDARD'
                    )
                  "
                >
                  <div class="page-title">
                    <h1>Aménagements optionnés</h1>
                  </div>
                  <p>(hors contrat, intrégrés au financement)</p>
                  <div class="options">
                    <div
                      class="option grid-x"
                      v-for="option in projectDraft.selectedOptions.filter(
                        (option) =>
                          option.isActive &&
                          !option.theme.isContractIncluded &&
                          option.category.categoryId !==
                            'c68c8545-09fd-4d57-a013-d4bfcfb673c2' &&
                          option.type === 'STANDARD'
                      )"
                      :key="option.optionId"
                    >
                      <div class="cell shrink">
                        <img
                          v-if="option.image"
                          class="rounded"
                          :src="option.image"
                          alt=""
                        />
                        <div v-else class="rounded empty" alt=""></div>
                      </div>
                      <div class="cell auto">
                        <h2>{{ option.name }}</h2>
                        <p class="text-with-break">{{ option.description }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="total grid-x">
                    <div class="cell shrink">
                      <h3>Total options et équipements hors contrat</h3>
                    </div>
                    <div class="cell auto"></div>
                    <div class="cell shrink">
                      <h3>
                        {{
                          utils.formatCentToEuro(
                            projectDraft.selectedOptions.reduce(
                              (a, option) =>
                                option.isActive &&
                                !option.theme.isContractIncluded &&
                                option.category.categoryId !==
                                  "c68c8545-09fd-4d57-a013-d4bfcfb673c2" &&
                                option.type === "STANDARD"
                                  ? a + option.price
                                  : a,
                              0
                            ),
                            true
                          )
                        }}
                      </h3>
                    </div>
                  </div>
                </div>
                <!-- SUMMARY -->
                <div class="summary-page page-print">
                  <div class="page-title">
                    <h1>Récapitulatif</h1>
                  </div>
                  <h2>Maison</h2>
                  <div class="grid-x line">
                    <div class="cell auto">
                      <p>Coût initial de la maison</p>
                    </div>
                    <div class="cell shrink">
                      <p v-if="projectDraft.initialPrice">
                        {{
                          utils.formatCentToEuro(
                            projectDraft.initialPrice -
                              (projectDraft.land && projectDraft.land.priceLandAdaptation
                                ? projectDraft.land.priceLandAdaptation
                                : 0) -
                              (projectDraft.land && projectDraft.land.priceLocalRegulation
                                ? projectDraft.land.priceLocalRegulation
                                : 0) -
                              (projectDraft.extensionsProperties.price
                                ? projectDraft.extensionsProperties.price
                                : 0),
                            true
                          )
                        }}
                      </p>
                    </div>
                  </div>
                  <div v-if="projectDraft.land && projectDraft.land.priceLandAdaptation" class="grid-x line">
                    <div class="cell auto">
                      <p>Frais adaptation au sol</p>
                    </div>
                    <div class="cell shrink">
                      <p>{{ utils.formatCentToEuro(projectDraft.land.priceLandAdaptation, true) }}</p>
                    </div>
                  </div>
                  <div v-if="projectDraft.land && projectDraft.land.priceLocalRegulation" class="grid-x line">
                    <div class="cell auto">
                      <p>Frais règlementation locale liés au terrain</p>
                    </div>
                    <div class="cell shrink">
                      <p>{{ utils.formatCentToEuro(projectDraft.land.priceLocalRegulation, true) }}</p>
                    </div>
                  </div>
                  <div class="grid-x line" v-if="projectDraft.extensionsProperties">
                    <div class="cell auto">
                      <p>Plus-value agrandissement</p>
                    </div>
                    <div class="cell shrink">
                      <p>
                        {{
                          utils.formatCentToEuro(
                            projectDraft.extensionsProperties.price,
                            true
                          )
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="grid-x line">
                    <div class="cell auto">
                      <p>Options et équipements</p>
                    </div>
                    <div class="cell shrink">
                      <p>
                        {{
                          utils.formatCentToEuro(
                            projectDraft.selectedOptions.reduce(
                              (a, option) =>
                                option.isActive &&
                                option.theme.isContractIncluded &&
                                option.category.categoryId !==
                                  "c68c8545-09fd-4d57-a013-d4bfcfb673c2" &&
                                option.type === "STANDARD"
                                  ? a + option.price
                                  : a,
                              0
                            ),
                            true
                          )
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="total dark grid-x">
                    <div class="cell shrink">
                      <h4>Prix de la maison seule</h4>
                    </div>
                    <div class="cell auto"></div>
                    <div class="cell shrink">
                      <h4>
                        {{
                          utils.formatCentToEuro(
                            projectDraft.initialPrice +
                              projectDraft.selectedOptions.reduce(
                                (a, option) =>
                                  option.isActive &&
                                  option.theme.isContractIncluded &&
                                  option.category.categoryId !==
                                    "c68c8545-09fd-4d57-a013-d4bfcfb673c2" &&
                                  option.type === "STANDARD"
                                    ? a + option.price
                                    : a,
                                0
                              ),
                            true
                          )
                        }}
                      </h4>
                    </div>
                  </div>
                  <h2>Travaux réservés</h2>
                  <div class="summary-total grid-x">
                    <div class="cell shrink">
                      <p><strong>Options et équipements hors contrat</strong></p>
                    </div>
                    <div class="cell auto"></div>
                    <div class="cell shrink">
                      <p>
                        <strong>{{
                          utils.formatCentToEuro(
                            projectDraft.selectedOptions.reduce(
                              (a, option) =>
                                option.isActive &&
                                !option.theme.isContractIncluded &&
                                option.type === "STANDARD" &&
                                option.category.categoryId !==
                                  "c68c8545-09fd-4d57-a013-d4bfcfb673c2"
                                  ? a + option.price
                                  : a,
                              0
                            ),
                            true
                          )
                        }}</strong>
                      </p>
                    </div>
                  </div>
                  <h2>Terrain</h2>
                  <div class="summary-total grid-x">
                    <div class="cell shrink">
                      <p><strong>Coût total du terrain</strong></p>
                    </div>
                    <div class="cell auto"></div>
                    <div class="cell shrink">
                      <p>
                        <strong>{{
                          utils.formatCentToEuro(projectDraft.landPrice, true)
                        }}</strong>
                      </p>
                    </div>
                  </div>
                  <h2>Frais annexes</h2>
                  <div
                    class="grid-x line"
                    v-for="option in projectDraft.selectedOptions.filter(
                      (option) => option.type === 'ANNEX' && option.isActive
                    )"
                    :key="option.optionId"
                  >
                    <div class="cell auto">
                      <p>
                        {{ option.name }}
                        {{
                          option.descriptionCount ? `(${option.descriptionCount})` : ""
                        }}
                      </p>
                    </div>
                    <div class="cell shrink">
                      <p>{{ utils.formatCentToEuro(option.price, true) }}</p>
                    </div>
                  </div>
                  <div class="summary-total grid-x">
                    <div class="cell shrink">
                      <p><strong>Coût total des frais annexes</strong></p>
                    </div>
                    <div class="cell auto"></div>
                    <div class="cell shrink">
                      <p>
                        <strong>{{
                          utils.formatCentToEuro(
                            projectDraft.selectedOptions.reduce(
                              (a, option) =>
                                option.type === "ANNEX" && option.isActive
                                  ? a + option.price
                                  : a,
                              0
                            ),
                            true
                          )
                        }}</strong>
                      </p>
                    </div>
                  </div>
                  <div class="summary-total grid-x">
                    <div class="cell shrink">
                      <p><strong>Coût total du projet</strong></p>
                    </div>
                    <div class="cell auto"></div>
                    <div class="cell shrink">
                      <p>
                        <strong>{{
                          utils.formatCentToEuro(projectDraft.totalPrice, true)
                        }}</strong>
                      </p>
                    </div>
                  </div>
                  <div class="annex-notes">
                    <small
                      class="annex-note"
                      v-for="(option, index) in projectDraft.selectedOptions.filter(
                        (opt) =>
                          opt.descriptionCount && opt.type === 'ANNEX' && opt.isActive
                      )"
                      :key="`${index}-${option.optionId}`"
                    >
                      ({{ option.descriptionCount }}) : {{ option.description }} -
                    </small>
                  </div>
                </div>
                <!-- FUNDING -->
                <div v-if="fundingPlan" class="funding-page page-print">
                  <div class="page-title">
                    <h1>Simulation de mensualités</h1>
                  </div>
                  <h2>Base de calcul</h2>
                  <div class="grid-x line">
                    <div class="cell auto">
                      <p>Apport personnel</p>
                    </div>
                    <div class="cell shrink">
                      <p>{{ fundingPlan.deposit.toFixed(2) }} €</p>
                    </div>
                  </div>
                  <div class="grid-x line">
                    <div class="cell auto">
                      <p>Revenu annuel net du ménage</p>
                    </div>
                    <div class="cell shrink">
                      <p>{{ fundingPlan.incomeN.toFixed(2) }} €</p>
                    </div>
                  </div>
                  <div class="grid-x line">
                    <div class="cell auto">
                      <p>Revenu fiscal de référence en {{ new Date().getFullYear() - 1 }}</p>
                    </div>
                    <div class="cell shrink">
                      <p>{{ fundingPlan.incomeN.toFixed(2) }} €</p>
                    </div>
                  </div>
                  <div class="grid-x line">
                    <div class="cell auto">
                      <p>Nombre de personnes dans le ménage</p>
                    </div>
                    <div class="cell shrink">
                      <p>{{ fundingPlan.familySize }}</p>
                    </div>
                  </div>
                  <h2>Détails du financement</h2>
                  <div class="grid-x line">
                    <div class="cell auto">
                      <p>Montant du prêt principal</p>
                    </div>
                    <div class="cell shrink">
                      <p>{{ fundingPlan.primaryLoanAmount.toFixed(2) }} €</p>
                    </div>
                  </div>
                  <div class="grid-x line">
                    <div class="cell auto">
                      <p>Durée du prêt principal</p>
                    </div>
                    <div class="cell shrink">
                      <p>{{ fundingPlan.duration }} ans</p>
                    </div>
                  </div>
                  <div class="grid-x line">
                    <div class="cell auto">
                      <p>Taux proposé</p>
                    </div>
                    <div class="cell shrink">
                      <p>{{ fundingPlan.interestRate }} %</p>
                    </div>
                  </div>
                  <div class="grid-x line">
                    <div class="cell auto">
                      <p>Coût du crédit hors assurance</p>
                    </div>
                    <div class="cell shrink">
                      <p>{{ fundingPlan.primaryLoanCoast.toFixed(2) }} €</p>
                    </div>
                  </div>
                  <h2>Prêt à taux zéro (PTZ)</h2>
                  <div class="grid-x line">
                    <div class="cell auto">
                      <p>Éligibilité</p>
                    </div>
                    <div class="cell shrink">
                      <p>{{ fundingPlan.ptzEligibility ? "OUI" : "NON" }}</p>
                    </div>
                  </div>
                  <div v-if="fundingPlan.ptzEligibility" class="grid-x line">
                    <div class="cell auto">
                      <p>Montant estimé</p>
                    </div>
                    <div class="cell shrink">
                      <p>{{ fundingPlan.ptzLoanAmount.toFixed(2) }} €</p>
                    </div>
                  </div>
                  <div v-if="fundingPlan.ptzEligibility" class="grid-x line">
                    <div class="cell auto">
                      <p>Durée du différé</p>
                    </div>
                    <div class="cell shrink">
                      <p>{{ fundingPlan.ptzLoanDifferedMonth }} mois</p>
                    </div>
                  </div>
                  <div v-if="fundingPlan.ptzEligibility" class="grid-x line">
                    <div class="cell auto">
                      <p>Durée d'amortissement</p>
                    </div>
                    <div class="cell shrink">
                      <p>{{ fundingPlan.ptzLoanPayingMonth }} mois</p>
                    </div>
                  </div>
                  <div class="total dark grid-x">
                    <div class="cell shrink">
                      <h4>Future mensualité*</h4>
                    </div>
                    <div class="cell auto"></div>
                    <div class="cell shrink">
                      <h4>{{ fundingPlan.monthlyPayment.toFixed(2) }} € / mois *</h4>
                    </div>
                  </div>
                  <div class="annex-notes">
                    <h2>
                      Affinez votre financement personnalisé avec votre agence et nos
                      partenaires bancaires !
                    </h2>
                    <small class="annex-note">
                      *Mensualité hors assurance. Le résultat issu du configurateur de
                      projet et le coût total estimé du projet sont fonctions des
                      informations indiquées par défaut ou renseignées par l’utilisateur
                      (options, équipements, agrandissement...). Elle ne saurait être
                      interprété comme tout document ayant force contractuelle.
                      Cette approche financière vous est donnée à titre indicatif.
                      Maisons Alysia se réserve le droit de modifier ou de mettre à jour
                      les composantes de cette simulation financière, comme de vous proposer
                      de réétudier votre simulation en 2024 en cas de suppression du
                      dispositif du prêt à taux zéro par l'Etat, de refonte ou de
                      modification de ses conditions d'octroi.
                    </small>
                  </div>
                </div>
                <!-- CONTACT -->
                <div class="contact-page page-print" v-if="agency">
                  <div class="page-title">
                    <h1>Et après ?</h1>
                  </div>
                  <p>
                    Besoin de renseignements, de valider votre projet avec un expert ou
                    d’évaluer vos possibilités de financement ?
                  </p>
                  <p>
                    <span v-for="(member, index) in members" :key="index"
                      >{{ member.firstname }} {{ member.lastname }}
                      {{ index >= members.length - 1 ? "" : " et " }}</span
                    >, {{ members.length > 1 ? "seront" : "sera" }} ravis de vous
                    accompagner dans votre projet de construction. Alors, n’hésitez pas à
                    contacter votre agence Maisons Alysia à
                    {{ agency.address ? agency.address.city : "..." }} pour obtenir plus
                    d’informations ou prendre rendez-vous
                  </p>

                  <h2>Contactez votre expert-constructeur</h2>
                  <div class="agency-section">
                    <h3>1 - En ligne</h3>
                  </div>
                  <ul>
                    <li>
                      <p>
                        Connectez-vous à <strong>votre espace personnel</strong> sur le
                        site maisons-alysia.com
                      </p>
                    </li>
                    <li><p>Sélectionnez votre projet préféré</p></li>
                    <li>
                      <p>
                        Remplissez le formulaire “Nous contacter” ou “Valider mon projet
                      </p>
                    </li>
                    <li><p>Votre projet sera envoyé à votre expert-construteur.</p></li>
                  </ul>
                  <h4><strong>Réponse assurée sous 72 heures</strong></h4>
                  <div class="agency-section">
                    <h3>2 - Par téléphone ou par mail</h3>
                  </div>
                  <p v-if="agency.secondaryPhone">{{ agency.secondaryPhone }}</p>
                  <p v-else>{{ agency.phone }}</p>
                  <p>{{ agency.email }}</p>
                  <div class="divider"></div>
                  <div class="grid-x">
                    <div class="cell auto agency-info">
                      <h3>{{ brand.name }} / {{ agency.name }}</h3>
                      <p>{{ agency.address.addressLine1 }}</p>
                      <p v-if="agency.address.addressLine2">
                        {{ agency.address.addressLine2 }}
                      </p>
                      <p>
                        {{
                          agency.address.postalCodeInseeNumber
                            ? agency.address.postalCodeInseeNumber.split("-")[0]
                            : ""
                        }}
                        {{ agency.address.city }}
                      </p>
                      <p>Horaires d'ouverture :</p>
                      <p>{{ agency.openingHours }}</p>
                    </div>
                    <div class="cell shrink">
                      <div class="image-container">
                        <div v-for="member in members" :key="member.memberId">
                          <img v-if="member.photo" :src="member.photo" />
                          <avatar v-else />
                        </div>
                      </div>
                      <p class="agency-members">
                        <span v-for="(member, index) in members" :key="index"
                          >{{ member.firstname }} {{ member.lastname }}, </span
                        ><br />
                        <span v-for="member in members" :key="member.memberId"
                          >{{
                            member.jobTitle &&
                            agencyJobs.find((j) => j.name === member.jobTitle)
                              ? agencyJobs.find((j) => j.name === member.jobTitle).label
                              : member.jobTitle
                          }},
                        </span>
                      </p>
                    </div>
                  </div>
                  <div class="divider"></div>
                  <h3 class="catch-phrase">
                    "Maisons Alysia, le plus court chemin entre vous et votre maison.”
                  </h3>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import categoryApi from '@/services/api/category';
import lotApi from '@/services/api/lot';
import themeApi from '@/services/api/theme';
import projectDraftApi from '@/services/api/projectDraft';
import floorApi from '@/services/api/floor';
import lotSurveySpecificationApi from '@/services/api/lotSurveySpecification';
import brandApi from '@/services/api/brand';
import calculatorApi from '@/services/api/calculator';

import versionApi from '@/services/api/version';
import versionMediaApi from '@/services/api/versionMedia';
import memberApi from '@/services/api/member';
import customerApi from '@/services/api/customer';
import agencyApi from '@/services/api/agency';
import utils from '@/services/utils/utils';
import agencyJobs from '@/services/data/agencyJobs.json';

import avatar from '@/assets/img/avatar.svg?inline';

export default {
  name: 'configuration-pdf',
  components: {
    avatar,
  },
  data() {
    return {
      agencyJobs,

      themes: null,
      annexThemes: null,
      annexLot: null,
      annexOptions: null,
      lots: null,
      categories: null,

      allThemes: null,
      allLots: null,
      allCategories: null,

      projectDraft: null,
      versionMedias: null,
      version: null,
      customer: null,
      members: null,
      brand: null,
      lotSurveySpecifications: null,
      agency: null,

      allFloors: null,
      floors: null,

      totalHabitable: null,
      firstExtensionCustomerWidth: null,
      secondExtensionCustomerWidth: null,

      city: null,

      isLoading: true,
      utils,
      optionsReferences: null,
      fundingPlan: null,
    };
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    checkCategories() {
      return this.lots.find((l) => l.lotId === '39ec5b82-34a3-4627-b7e6-0960113697ac' || l.lotId === 'f867381d-16c8-4f26-b81a-abbf0a67eb5f') ? this.categories : this.categories.filter((c) => c.categoryId !== 'c68c8545-09fd-4d57-a013-d4bfcfb673c2');
    },
    async getData() {
      this.isLoading = true;
      await this.getBrand();
      await this.getFloors();
      await this.getProjectDraft();

      await this.getVersion();
      await this.getVersionMedias();
      await this.getLotsSuveysSpecifications();

      await this.getThemes();
      await this.getLots();
      await this.getCategories();

      this.sortOptions();

      await this.getCustomer();
      await this.getAgency();
      await this.getAgencyMembers();

      if (this.projectDraft) {
        this.totalHabitable = this.projectDraft.surfaces
          .reduce(
            (a, surface) => (surface.room.type === 'HABITABLE'
              ? a
                  + surface.area
                  + surface.firstExtensionDepth
                    * this.projectDraft.extensionsProperties.firstExtensionCustomerWidth
                  + surface.secondExtensionDepth
                    * this.projectDraft.extensionsProperties.secondExtensionCustomerWidth
              : a),
            0,
          )
          .toFixed(2);
        await this.getFundingPlan();
      }

      this.isLoading = false;
    },
    async getFundingPlan() {
      try {
        const interestRates = await calculatorApi.getRates();
        let postalCode = null;
        if (
          this.projectDraft.land
          && this.projectDraft.land.address
          && this.projectDraft.land.address.postalCodeInseeNumber
        ) {
          [, postalCode] = this.projectDraft.land.address.postalCodeInseeNumber.split(
            '-',
          );
          postalCode = postalCode.substring(0, 2);
        } else if (
          this.projectDraft.customerLand
          && this.projectDraft.customerLand.address
          && this.projectDraft.customerLand.address.postalCodeInseeNumber
        ) {
          [,
            postalCode,
          ] = this.projectDraft.customerLand.address.postalCodeInseeNumber.split('-');
          postalCode = postalCode.substring(0, 2);
        } else {
          postalCode = 'national';
        }
        const rate = interestRates.find((r) => r.zone === postalCode);

        this.fundingPlan = await calculatorApi.getFundingPlan({
          incomeN: 30000,
          zipCode: rate.zone === 'national' ? '44' : rate.zone,
          duration: 25,
          interestRate: rate.rates.find((r) => r.duration === 25).avgRate,
          deposit: 0,
          incomeN2: 30000,
          isOwner: false,
          familySize: 4,
          landPrice: this.projectDraft.landPrice ? this.projectDraft.landPrice / 100 : 0,
          versionPrice:
            (this.projectDraft.totalPrice - this.projectDraft.landPrice) / 100,
          familySituation: 'MARRIED',
        });
      } catch (err) {
        throw err;
      }
    },
    async getBrand() {
      try {
        this.brand = await brandApi.get();
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération de la marque',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    sortOptions() {
      if (
        this.projectDraft
        && this.projectDraft.selectedOptions
        && this.projectDraft.selectedOptions.length
      ) {
        // on ajoute les numéros des notes pour les options annexes
        let descriptionCount = 0;
        this.projectDraft.selectedOptions = this.projectDraft.selectedOptions.map(
          (option) => {
            if (
              option
              && option.isActive
              && option.type === 'ANNEX'
              && option.description
              && option.description.length
            ) {
              descriptionCount += 1;
              return {
                ...option,
                descriptionCount,
              };
            }
            return option;
          },
        );

        this.projectDraft.selectedOptions = this.projectDraft.selectedOptions.map(
          (option) => {
            const optionLot = this.allLots && this.allLots.length
              ? this.allLots.find((lot) => lot.lotId === option.lot.lotId)
              : null;
            const optionTheme = this.allThemes && this.allThemes.length
              ? this.allThemes.find((theme) => theme.themeId === option.theme.themeId)
              : null;
            const optionCategory = this.allCategories && this.allCategories.length
              ? this.allCategories.find(
                (category) => category.categoryId === option.category.categoryId,
              )
              : null;

            return {
              ...option,
              lot: optionLot || option.lot,
              theme: optionTheme || option.theme,
              category: optionCategory || option.category,
            };
          },
        );

        this.projectDraft.selectedOptions = this.projectDraft.selectedOptions.sort(
          (a, b) => a.category.rank - b.category.rank
            || a.lot.rank - b.lot.rank
            || a.theme.rank - b.theme.rank,
        );
      }
    },
    async getProjectDraft() {
      try {
        this.projectDraft = await projectDraftApi.getPublicById(
          this.$route.params.projectDraftId,
        );

        // on sélectionne les niveaux qui correspondent au projet
        if (
          this.projectDraft
          && this.projectDraft.surfaces
          && this.projectDraft.surfaces.length
        ) {
          if (this.allFloors && this.allFloors.length) {
            this.floors = this.allFloors.filter((floor) => this.projectDraft.surfaces.find(
              (surface) => surface.floorId === floor.floorId,
            ));
          }
        }

        // On récupère les agrandissements (auto ou custom)
        if (
          this.projectDraft.extensionsProperties
          && this.projectDraft.extensionsProperties.isActive
        ) {
          this.firstExtensionCustomerWidth = this.projectDraft.extensionsProperties
            .selectedByCustomer
            ? this.projectDraft.extensionsProperties.firstExtensionCustomerWidth
            : this.projectDraft.extensionsProperties.firstExtensionMaxWidth;

          this.secondExtensionCustomerWidth = this.projectDraft.extensionsProperties
            .selectedByCustomer
            ? this.projectDraft.extensionsProperties.secondExtensionCustomerWidth
            : this.projectDraft.extensionsProperties.secondExtensionMaxWidth;
        }

        // On ajoute les surfaces agrandies
        if (this.projectDraft && this.projectDraft.surfaces.length) {
          this.projectDraft.surfaces = this.projectDraft.surfaces.map((surface) => {
            let extensionSurface = surface.area;
            if (this.firstExtensionCustomerWidth && surface.firstExtensionDepth) {
              extensionSurface
                += this.firstExtensionCustomerWidth
                * (surface.firstExtensionDepth ? surface.firstExtensionDepth : 0);
            }
            if (this.secondExtensionCustomerWidth && surface.secondExtensionDepth) {
              extensionSurface
                += this.secondExtensionCustomerWidth
                * (surface.secondExtensionDepth ? surface.secondExtensionDepth : 0);
            }

            return {
              ...surface,
              extensionSurface:
                extensionSurface !== surface.area ? extensionSurface : null,
            };
          });
        }

        if (
          this.projectDraft
          && this.projectDraft.selectedOptions
          && this.projectDraft.selectedOptions.length
        ) {
          let optionsWithReferences = this.projectDraft.selectedOptions.filter(
            (option) => option.isActive && option.reference && option.reference.length,
          );
          optionsWithReferences = optionsWithReferences && optionsWithReferences.length
            ? optionsWithReferences.map((option) => option.reference)
            : null;
          this.optionsReferences = optionsWithReferences
            ? optionsWithReferences.join('_')
            : null;
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: "Il y a eu un problème lors de la récupération de l'avant-projet",
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    getExtensionPrice() {
      const habitableSurface = this.projectDraft.surfaces.reduce(
        (a, surface) => (surface.room.type === 'HABITABLE'
          ? a
              + surface.firstExtensionDepth * this.firstExtensionCustomerWidth
              + surface.secondExtensionDepth * this.secondExtensionCustomerWidth
          : a),
        0,
      );

      const nonHabitableSurface = this.projectDraft.surfaces.reduce(
        (a, surface) => (surface.room.type === 'NON_HABITABLE'
          ? a
              + surface.firstExtensionDepth * this.firstExtensionCustomerWidth
              + surface.secondExtensionDepth * this.secondExtensionCustomerWidth
          : a),
        0,
      );

      const carportSurface = this.projectDraft.surfaces.reduce(
        (a, surface) => (surface.room.type === 'CARPORT'
          ? a
              + surface.firstExtensionDepth * this.firstExtensionCustomerWidth
              + surface.secondExtensionDepth * this.secondExtensionCustomerWidth
          : a),
        0,
      );

      return (
        (habitableSurface || 0)
          * (this.brand.habitableExtensionPrice ? this.brand.habitableExtensionPrice : 0)
        + (nonHabitableSurface || 0)
          * (this.brand.nonHabitableExtensionPrice
            ? this.brand.nonHabitableExtensionPrice
            : 0)
        + (carportSurface || 0)
          * (this.brand.carportExtensionPrice ? this.brand.carportExtensionPrice : 0)
      );
    },
    async getThemes() {
      try {
        const themes = await themeApi.getAll();
        this.allThemes = themes;
        let allOptions = null;

        if (
          this.projectDraft
          && this.projectDraft.selectedOptions
          && this.projectDraft.selectedOptions.length
        ) {
          allOptions = this.projectDraft.selectedOptions.filter(
            (option) => option.theme.isPublic,
          );
        }

        if (themes && themes.length && allOptions && allOptions.length) {
          this.themes = themes.filter((theme) => {
            const optionMatch = allOptions.find(
              (option) => option.theme.themeId === theme.themeId && option.type !== 'ANNEX',
            );
            return optionMatch;
          });
        } else {
          this.themes = null;
        }

        if (
          themes
          && themes.length
          && this.projectDraft.selectedOptions
          && this.projectDraft.selectedOptions.length
        ) {
          this.annexOptions = this.projectDraft.selectedOptions.filter(
            (option) => option.type === 'ANNEX' && option.isActive,
          );
          if (this.annexOptions && this.annexOptions.length) {
            this.annexThemes = themes.filter((theme) => {
              const optionMatch = this.annexOptions.find(
                (option) => option.theme.themeId === theme.themeId,
              );
              return optionMatch;
            });
          }
        } else {
          this.annexOptions = null;
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des themes',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getLots() {
      try {
        const lots = await lotApi.getAll(null);
        this.allLots = lots;

        // On tri pou n'avoir que les lots présents dans la configuration
        if (lots && lots.length) {
          this.lots = lots.filter((lot) => {
            let themeMatch = null;
            if (this.themes && this.themes.length) {
              themeMatch = this.themes.find((theme) => theme.lot.lotId === lot.lotId);
            }

            if (
              this.projectDraft.lotsSurveysSpecifications
              && this.projectDraft.lotsSurveysSpecifications.length
            ) {
              const specMatch = this.projectDraft.lotsSurveysSpecifications.find(
                (lotSurveySpecification) => lotSurveySpecification.lot.lotId === lot.lotId
                  && lotSurveySpecification.price > 0,
              );
              return (
                (themeMatch
                  && lot.categoryId === 'c68c8545-09fd-4d57-a013-d4bfcfb673c2')
                || specMatch
              );
            }
            return (
              themeMatch && lot.categoryId === 'c68c8545-09fd-4d57-a013-d4bfcfb673c2'
            );
          });
        } else {
          this.lots = null;
        }
        if (lots && lots.length && this.annexThemes && this.annexThemes.length) {
          this.annexLot = lots.find((lot) => {
            const themeMatch = this.annexThemes.find(
              (theme) => theme.lot.lotId === lot.lotId,
            );
            return themeMatch;
          });
        } else {
          this.annexLot = null;
        }

        // on ajoute les descriptions des lots métrés de la version
        if (
          this.lots
          && this.lots.length
          && this.lotSurveySpecifications
          && this.lotSurveySpecifications.length
        ) {
          this.lots = this.lots.map((lot) => {
            // On récupère les lots surveys avec des descriptions
            let lotSurveys = this.lotSurveySpecifications.filter(
              (lotSurvey) => lotSurvey.lot.lotId === lot.lotId
                && lotSurvey.description
                && lotSurvey.description.length > 0,
            );

            if (lotSurveys && lotSurveys.length) {
              lotSurveys = lotSurveys.map((l) => ({
                ...l,
                description:
                  l.description && l.description.length
                    ? l.description.replace(/•/g, '✓').replace(/\n */g, '\n')
                    : '',
              }));
            }

            return {
              ...lot,
              description:
                lot.description && lot.description.length
                  ? lot.description.replace(/•/g, '✓').replace(/\n */g, '\n')
                  : '',
              lotSurveys,
            };
          });
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: `Il y a eu un problème lors de la récupération des lots ${er}`,
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getCategories() {
      try {
        const categories = await categoryApi.getAll();
        this.allCategories = categories;

        if (categories && categories.length && this.lots && this.lots.length) {
          this.categories = categories.filter((category) => {
            const lotMatch = this.lots.find(
              (lot) => lot.categoryId === category.categoryId,
            );
            return lotMatch;
          });
        } else {
          this.categories = null;
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des catégories',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getLotsSuveysSpecifications() {
      try {
        if (this.version && this.version.versionId) {
          this.lotSurveySpecifications = await lotSurveySpecificationApi.getPublicByVersionId(
            this.version.versionId,
          );
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text:
            'Il y a eu un problème lors de la récupération des spécifications de lots métrés',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getFloors() {
      try {
        this.allFloors = await floorApi.getAll();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: "Il y a eu un problème lors de la récupération de l'avant-projet",
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getVersionMedias() {
      try {
        if (this.projectDraft && this.projectDraft.version) {
          this.versionMedias = await versionMediaApi.getAll(
            this.projectDraft.version.versionId,
          );
          if (this.versionMedias) {
            this.blueprints = this.versionMedias.filter(
              (media) => media.type === 'BLUEPRINT',
            );
          }
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: "Il y a eu un problème lors de la récupération de l'avant-projet",
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getCustomer() {
      try {
        if (this.projectDraft && this.projectDraft.customer) {
          this.customer = await customerApi.getMe();
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du client',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    getCustomersNames(customer) {
      return customerApi.getCustomersNames(customer);
    },
    async getVersion() {
      try {
        if (this.projectDraft && this.projectDraft.version) {
          this.version = await versionApi.getPublicById(
            this.projectDraft.version.versionId,
          );
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération de la version',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getAgency() {
      try {
        let postalCode = this.projectDraft.land && this.projectDraft.land.address
          ? this.projectDraft.land.address.postalCodeInseeNumber
          : null;
        postalCode = !postalCode
          && this.projectDraft.customerLand
          && this.projectDraft.customerLand.postalCodeInseeNumber
          ? this.projectDraft.customerLand.postalCodeInseeNumber
          : postalCode;
        if (postalCode) {
          this.agency = await agencyApi.getByPostalCode(postalCode);
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: "Il y a eu un problème lors de la récupération de l'agence",
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getAgencyMembers() {
      try {
        if (this.agency) {
          this.members = await memberApi.getPublicByAgency(this.agency.agencyId);
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des membres',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
  },
};
</script>

<style lang="sass" scoped>
@page
  format: A4 portrait
  margin-top: 0.9cm
.configuration-pdf
  height: auto
  max-width: 1000px
  margin: auto
  background: white
  padding: 0 50px
  html, body, #app, p, a, span, ul li, button, input, textarea, h1, h2, h3, h4
    font-family: 'Ubuntu', sans-serif !important
  h1, h2, h3, h4, h5, h6
    color: #222
  h1
    font-weight: 400
    font-size: 32px
    line-height: 40px
  h3
    font-size: 18px
    font-weight: 500
    line-height: 22px
  h4
    font-size: 14px
    font-weight: 400
    line-height: 22px
    margin: 0
  p, span
    margin: 0
    font-size: 12px
    line-height: 18px
    color: #3E4558
  strong
    color: black
    margin: 0
    font-weight: 500
    font-size: 12px
    letter-spacing: 0.02rem
    line-height: 14px
  .upper
    text-transform: uppercase
  // GENERAL
  .body
    width: 100%
  .row
    margin: 0 0 24px 0
  .header
    .logo
      margin: 10px auto 20px auto
      width: 280px
    .cell
      display: flex
      align-items: center
    .catch-phrase
      font-size: 16px
      line-height: 22px
      font-weight: 400
      padding: 0 50px
      border-left: 1px solid $pdf-background
      height: 100px
      display: flex
      align-items: center
  .page-title
    background-color: $pdf-background!important
    padding: 10px 20px
    margin-bottom: 20px
    border-radius: 8px
    h1
      color: white!important
  .page-print
    margin-bottom: 50px
  .note
    margin-top: 50px
    p, string, span
      color: $pdf-note
  .line
    padding: 5px 0
    margin-bottom: 5px
    border-bottom: solid 1px $pdf-line
    break-inside: avoid-page
  .total
    background-color: $pdf-light-background!important
    padding: 15px
    margin: 15px 0
    break-inside: avoid-page
    h4
      font-weight: 600
    &.dark
      background-color: $pdf-background!important
      h4
        color: white!important
    .cell:first-child h4
      text-transform: uppercase
  .text-with-break
    white-space: pre-wrap
  .rounded
    width: 120px
    height: 120px
    border-radius: 14px
    justify-content: center
    margin-right: 30px
    box-shadow: 0 1px 11px 0 #c4c9cae8!important

  // PAR PAGE
  .version-presentation
    max-width: 950px
    margin: auto
    .heading
      background-color: $pdf-background!important
      padding: 20px
      border-top-left-radius: 8px
      border-top-right-radius: 8px
      h1, p, h3, h4
        color: white!important
      h3
        padding: 10px 0
    .reference
      margin-top: 10px
      h3
        margin-bottom: 5px
    .bottom, .reference
      align-items: flex-end
      .right
        text-align: right
        h3
          font-weight: 500
          font-size: 16px
      h3
        font-weight: 600
    .label-name
      color: $pdf-label
      font-weight: 400
    img
      width: 100%
      margin-bottom: 10px
      object-fit: cover
      border-bottom-left-radius: 8px
      border-bottom-right-radius: 8px
  .floor-blueprint
    img
      max-width: 550px
    .centered
      display: flex
      justify-content: center
  .project-introduction
    margin-top: 30px
    .cell:first-child
      padding-right: 20px
      border-right: solid 1px $pdf-line
      p
        margin-bottom: 10px
    .cell:last-child
      padding-left: 20px
    ul li
      list-style: disc
      span
        color: $pdf-light-text
    .introduction
      strong, p, li, span
        font-size: 14px
        line-height: 18px
  .surfaces-page
    h2
      margin: 20px 0 10px 0
    .total
      background-color: $pdf-light-background!important
      padding: 20px
      margin: 10px 0
      break-inside: avoid-page
  .category-page
    .category
      break-inside: avoid-page
    .category:not(:first-child)
      break-before: page
    .category .category-name
      padding: 20px 0 5px 0
      border-bottom: solid 2px $pdf-background!important
      img
        width: 65px
      .cell:first-child
        align-items: center
        display: flex
      h1
        font-weight: 500
    .lot
      margin: 40px 0
      break-inside: avoid-page
      h2
        margin-bottom: 10px
        text-transform: uppercase
      .cell:last-child
        border-top: solid 1px $pdf-line
        padding: 10px 0
  .extensions-page
    h2
      margin: 20px 0
    .main-grid
      margin-top: 30px
      .image-container
        padding: 0 30px
        > div
          position: relative
        img
          max-width: 300px
    .total-extension
      background-color: $pdf-light-background!important
      padding: 10px
      margin: 20px 0
    .line-container
      position: absolute
      bottom: 30px
      top: 0
      .line
        width: 2px
        background-color: $pdf-background!important
        height: 94%
        margin: auto
      .arrow
        height: 20px
        margin: 0 3px
      .slider-container
        display: flex
        align-items: center
        justify-content: center
        margin-top: -2px
      .slider
        color: white!important
        background-color: $pdf-background!important
        padding: 5px 12px
        border-radius: 20px
  .options-page
    .option
      margin: 40px 0
      break-inside: avoid-page
      h2
        margin-bottom: 10px
      .cell:last-child
        border-top: solid 1px $pdf-line
        padding: 10px 0
  .summary-total
    background-color: $pdf-light-background!important
    padding: 10px
    margin: 15px 0 10px 0
  .summary-page
    .page-title
      margin-bottom: 5px
    .total
      margin: 5px 0
    .summary-total
      margin-top: 5px
    .annex-notes
      line-height: 75%
  .funding-page
    .annex-notes
      line-height: 75%
      h2
        margin-bottom: 10px
  .contact-page
    h2
      margin-top: 20px
    .agency-section
      background-color: $pdf-light-background!important
      padding: 10px
      margin: 20px 0
      border-radius: 8px
    ul li
      list-style: disc
    h4
      margin: 10px
    .catch-phrase
      text-align: center
    .image-container
      display: flex
      align-items: center
      justify-content: center
      margin-bottom: 15px
      img
        width: 80px
        height: 80px
        border-radius: 80px
        margin: 0 5px
        box-shadow: 0 1px 11px 0 #c4c9cae8!important
    .agency-members
      text-align: center
    svg
      width: 80px
      height: 80px
      fill: #e5e3df
      border: 2px solid $pdf-line
      border-radius: 40px
      box-shadow: 0 1px 11px 0 #c4c9cae8!important
      path, circle
        fill: $pdf-line
    .agency-info
      h3
        margin-bottom: 10px
      p
        line-height: 18px
    p, strong
      font-size: 14px
      line-height: 18px
  .divider
    margin: 20px 0
    border-top: 1px solid $pdf-line
    width: 100%
  .footer
    width: 100%
    padding-bottom: 10px
    img
      height: 65px
    p, h3, small
      text-align: right
      margin: 0
      padding: 0
    p
      line-height: 16px
    h3
      margin-top: 5px
    .upper
      font-size: 10px
      line-height: 12px
    small, small span
      font-size: 10px!important
      line-height: 12px
      font-weight: 500
      color: black!important
    .reference-footer
      font-size: 10px!important
  // gestion de l'impression
  thead.report-header
    display: table-header-group
    .header-info
      height: 30px
  tfoot.report-footer
    display: table-footer-group !important
  .footer-info
    border-top: 1px solid $pdf-line
    padding-top: 5px
  .page-print:not(.version-presentation)
    break-inside: avoid-page
    break-before: page
  .annex-note
    font-size: 10px
</style>
