import axios from 'axios';

const { VUE_APP_API_URL, VUE_APP_BRAND_ID } = process.env;

/**
 * Récupère la liste des configurations
 * @param {string} brandId: id de la marque
 * @param {string} postalCodeInseeNumber: postal code insee number
 * @param {boolean} emptyLand: booléen pour savoir si on doit prendre en compte un térrain ou non
 * @param {number} landWidth: largeur du terrain du client
 * @param {number} landSurface: surface du terrain du client
 * @param {number} landPrice: prix du terrain du client
 * @param {boolean} isMainDrainagePlanned: booléen pour savoir si le terrain, sera au tout à l'égoût
 * @param {boolean} isServiced: booléen pour savoir si le terrain est viabilisé
 * @param {boolean} isClayZone: booléen pour savoir si le terrain est dans une zone argileuse
 * @param {string} landId: id du terrain
 * @param {string} packId: id du pack
 */
const getAll = async (
  postalCodeInseeNumber,
  emptyLand,
  landWidth,
  landSurface,
  landPrice,
  isMainDrainagePlanned,
  isServiced,
  isClayZone,
  landId,
  packId,
) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/public/brands/${VUE_APP_BRAND_ID}/configurations/`, {
      params: {
        ...(postalCodeInseeNumber && { postalCodeInseeNumber }),
        ...(emptyLand && { emptyLand }),
        ...(landWidth && { landWidth }),
        ...(landSurface && { landSurface }),
        ...(landPrice && { landPrice }),
        ...(typeof isMainDrainagePlanned === 'boolean' && { isMainDrainagePlanned }),
        ...(typeof isServiced === 'boolean' && { isServiced }),
        ...(typeof isClayZone === 'boolean' && { isClayZone }),
        ...(landId && { landId }),
        ...(packId && { packId }),
      },
    });
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupère un prix de configuration
 * @param {string} brandId: id de la marque
 * @param {string} postalCodeInseeNumber: postal code insee number
 * @param {boolean} emptyLand: booléen pour savoir si on doit prendre en compte un térrain ou non
 * @param {number} landWidth: largeur du terrain du client
 * @param {number} landSurface: surface du terrain du client
 * @param {number} landPrice: prix du terrain du client
 * @param {boolean} isMainDrainagePlanned: booléen pour savoir si le terrain, sera au tout à l'égoût
 * @param {boolean} isServiced: booléen pour savoir si le terrain est viabilisé
 * @param {boolean} isClayZone: booléen pour savoir si le terrain est dans une zone argileuse
 * @param {string} versionId: id de la version de la configuration
 * @param {string} landId: id du terrain de la configuration
 * @param {string} packId: id du pack
 */
const getPrice = async (
  postalCodeInseeNumber,
  emptyLand,
  landWidth,
  landSurface,
  landPrice,
  isMainDrainagePlanned,
  isServiced,
  isClayZone,
  versionId,
  landId,
  packId,
) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/public/brands/${VUE_APP_BRAND_ID}/configurations/price/`, {
      params: {
        ...(postalCodeInseeNumber && { postalCodeInseeNumber }),
        ...(emptyLand && { emptyLand }),
        ...(landWidth && { landWidth }),
        ...(landSurface && { landSurface }),
        ...(landPrice && { landPrice }),
        ...(typeof isMainDrainagePlanned === 'boolean' && { isMainDrainagePlanned }),
        ...(typeof isServiced === 'boolean' && { isServiced }),
        ...(typeof isClayZone === 'boolean' && { isClayZone }),
        ...(versionId && { versionId }),
        ...(landId && { landId }),
        ...(packId && { packId }),
      },
    });
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupère une configuration
 * @param {string} brandId: id de la marque
 * @param {string} configuration: objet de la configuration
 */
const getConfiguration = async (
  configuration,
) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/public/brands/${VUE_APP_BRAND_ID}/configure/`, configuration);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const configuration = {};
configuration.getAll = getAll;
configuration.getPrice = getPrice;
configuration.getConfiguration = getConfiguration;

export default configuration;
