import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupère la liste des projets d'un client
 */
const getAll = async () => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/customers/me/project-drafts`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/*
 * Récupérér un projet publique
 * @param {object} projectDraftId: id de l'avant projet
 */
const getPublicById = async (
  projectDraftId,
) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/customers/me/project-drafts/${projectDraftId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/*
 * Supprimer un projet publique
 * @param {object} projectDraftId: id de l'avant projet
 */
const deleteById = async (
  projectDraftId,
) => {
  try {
    const response = await axios.delete(`${VUE_APP_API_URL}/customers/me/project-drafts/${projectDraftId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Sauvegarder un projet
 * @param {object} project: projet / configuration à sauvegarder
 */
const create = async (project) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/customers/me/project-drafts`, project);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Mise à jour d'un projet
 * @param {string} projectDraftId: id de l'avant projet
 * @param {object} data: données de l'avant projet
 */
const update = async (projectDraftId, data) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/customers/me/project-drafts/${projectDraftId}`, data);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const projectDraft = {};
projectDraft.getAll = getAll;
projectDraft.getPublicById = getPublicById;
projectDraft.delete = deleteById;
projectDraft.create = create;
projectDraft.update = update;

export default projectDraft;
