import axios from 'axios';

const { VUE_APP_API_URL, VUE_APP_BRAND_ID } = process.env;

/**
 * Récupère une gamme
 * @param {string} rangeId: id de la gamme à récupérer
 */
const getById = async (rangeId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/public/brands/${VUE_APP_BRAND_ID}/ranges/${rangeId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupère la liste des gammes
 * @param {string} search: paramètre de recherche, sur le nom de la gamme
 */
const getAll = async (search) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/public/brands/${VUE_APP_BRAND_ID}/ranges/`, {
      params: { ...(search && { search }) },
    });
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const range = {};
range.getAll = getAll;
range.getById = getById;

export default range;
