<template>
  <div class="calculator-form" :class="isFundingPlan && 'funding-form'">
    <div class="card">
      <form class="grid-x grid-margin-x grid-margin-y" ref="borrowingCapacityForm" @submit.prevent="$emit('submit', borrowingCapacityForm)">
        <div class="cell auto" v-if="!isFundingPlan">
          <app-input label="Mensualité souhaitée" v-model="borrowingCapacityForm.monthlyPaymentMax" type="number" placeholder="1000" unit="€/mois" required></app-input>
        </div>
        <!-- <div class="cell small-12" v-if="!isFundingPlan">
          <app-input label="Commune du projet (CP)" v-model="borrowingCapacityForm.zipCode" placeholder="Code postal..." required></app-input>
        </div> -->
        <div class="cell sall-12" v-if="!isFundingPlan">
          <div class="cell auto">
              <app-search-city
                label="Commune du projet"
                placeholder="Nantes - 44000"
                @select="setZipCode"
                @clear="setZipCode"
                :selectedPostalCode="borrowingCapacityForm.zipCode.label"
                noSubmitBtn
                required
              />
            </div>
        </div>
        <div class="cell small-12">
          <app-select label="Durée d'emprunt" v-model="borrowingCapacityForm.duration" :options="durationOptions" required></app-select>
        </div>
        <div class="cell medium-6 small-12">
          <app-input label="Taux d'intérêt proposé" v-model="borrowingCapacityForm.interestRate" unit="%" :step="0.01" :min="0.5" required type="number" placeholder="3,8"></app-input>
        </div>
        <div class="cell medium-6 small-12">
          <app-input label="Apport personnel" v-model="borrowingCapacityForm.deposit" unit="€" required type="number" placeholder="2000"></app-input>
        </div>
        <div class="cell small-12" v-if="isFundingPlan">
          <app-input label="Revenu annuel net du ménage" v-model="borrowingCapacityForm.incomeN" unit="€" type="number" placeholder="30000" required></app-input>
        </div>
        <div class="cell small-12">
          <app-input :label="`Revenu fiscal de référence en ${yearN1}`" v-model="borrowingCapacityForm.incomeN2" unit="€" type="number" placeholder="30000" required></app-input>
        </div>
        <div class="cell small-12" :class="isFundingPlan ? 'medium-12' : 'medium-6'">
          <app-select label="Vous êtes" v-model="borrowingCapacityForm.isOwner" :options="isOwnerOptions" required></app-select>
        </div>
        <div class="cell medium-6 small-12" v-if="isFundingPlan">
          <app-select label="Situation familiale" v-model="borrowingCapacityForm.familySituation" :options="familyOptions" required></app-select>
        </div>
        <div class="cell medium-6 small-12">
          <app-input label="Nombre de personnes dans le ménage" type="number" :min="(isFundingPlan && borrowingCapacityForm.familySituation !== 'SINGLE') ? 2 : 1" v-model="borrowingCapacityForm.familySize" required></app-input>
        </div>
        <div class="cell small-12">
          <app-button type="submit" theme="warning">Calculer</app-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import calculatorApi from '@/services/api/calculator';

export default {
  name: 'calculator-form',
  emits: ['submit'],
  props: {
    oldForm: Object,
    isFundingPlan: {
      type: Boolean,
      default: false,
    },
    landZipCode: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      interestRates: [],
      borrowingCapacityForm: {
        monthlyPaymentMax: null,
        duration: null,
        interestRate: null, // minimum à 0.5 sinon paramètre taux incorrect
        deposit: null,
        zipCode: this.landZipCode ? { name: this.landZipCode } : {},
        isOwner: 'false',
        incomeN2: null,
        familySize: 1,
        familySituation: null,
        incomeN: null,
      },
      durationOptions: [15, 20, 25].map((v) => ({ label: `${v} ans`, name: v })),
      isOwnerOptions: [{ label: 'Locataire', name: 'false' }, { label: 'Propriétaire', name: 'true' }],
      familyOptions: [
        { label: 'Marié(e)', name: 'MARRIED' },
        { label: 'Pacsé(e)', name: 'PACSED' },
        { label: 'Union libre', name: 'COMMON_LAW' },
        { label: 'Célibataire', name: 'SINGLE' },
      ],
      yearN1: new Date().getFullYear() - 1,
    };
  },
  created() {
    this.getRates();
    if (this.oldForm) this.borrowingCapacityForm = this.oldForm;
  },
  watch: {
    'borrowingCapacityForm.duration': {
      handler() { this.getAppliedRate(); },
    },
    'borrowingCapacityForm.zipCode': {
      handler() { this.getAppliedRate(); },
    },
    landZipCode() {
      if (this.landZipCode) {
        this.zipCode = { name: this.landZipCode };
      }
    },
  },
  methods: {
    async getRates() {
      this.interestRates = await calculatorApi.getRates();
    },
    getAppliedRate() {
      if (this.borrowingCapacityForm.duration && this.interestRates.length) {
        let zoneRate = this.interestRates.find((rate) => this.borrowingCapacityForm.zipCode.name.startsWith(rate.zone));
        if (this.borrowingCapacityForm.zipCode.length < 2 || !zoneRate) {
          zoneRate = this.interestRates.find((rate) => rate.zone === 'national');
        }
        const interestRate = zoneRate.rates.find((rate) => rate.duration === this.borrowingCapacityForm.duration);
        this.borrowingCapacityForm.interestRate = interestRate.avgRate;
      }
    },
    setZipCode(postalCode) {
      this.borrowingCapacityForm.zipCode = postalCode || {};
    },
  },
};
</script>

<style lang="sass">
.calculator-form
  .card
    @include card
    max-width: 480px
    padding: 1.5rem 2rem
    .app-button
      width: 100%
  &.funding-form
    .card
      box-shadow: none
  .app-search-city
    .position-icon
      border-radius: 4px 0 0 4px
    .cancel-btn
      border-radius: 0 4px 4px 0
</style>
