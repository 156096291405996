import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupère les membres d'une agence publique
 * @param {uuid} agencyId : id de l'agence concernée
 */
const getPublicByAgency = async (agencyId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/public/agencies/${agencyId}/members`);
    return response.data.data;
  } catch (er) {
    return er;
  }
};

const member = {};
member.getPublicByAgency = getPublicByAgency;

export default member;
