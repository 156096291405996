<template>
  <div class="side-modal">
    <!-- MODAL --- -->
    <transition name="modal">
      <div class="modal-wrapper" v-if="isOpen">
        <div class="container">
          <div class="header grid-x auto shrink">
            <div class="cell shrink close-modal">
              <close @click="close" />
            </div>
            <div class="title cell auto">
              <h3>{{ title }}</h3>
            </div>
          </div>
          <div class="body cell auto">
            <slot></slot>
          </div>
        </div>
      </div>
    </transition>
    <transition name="screen">
      <div class="modal-screen" v-if="isOpen" @click="close"/>
    </transition>
  </div>
</template>

<script>
import close from '@/assets/img/close.svg?inline';

export default {
  name: 'side-modal',
  components: {
    close,
  },
  props: {
    isOpen: {
      type: Boolean,
    },
    title: {
      type: String,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang='sass' scoped>
.side-modal
  .modal-wrapper
    position: fixed
    top: 0
    right: 0
    bottom: 0
    width: 250px
    background: $off-white
    box-shadow: $drop-max
    z-index: 10
    transition: all .4s ease-in-out
    .row
      @include row
    .header
      padding: 24px 16px
      @include centered-container
      box-shadow: none
      h6
        @include hyperlink
        text-decoration: none
        cursor: pointer
      .title
        margin: 0 8px
    .container
      height: 100%
      display: flex
      flex-direction: column
      justify-content: space-between
    .body
      flex: 1
      overflow-y: auto
    .footer
      @include centered-container
      padding: 16px
      button
        width: 100%
    .close-modal
      svg
        cursor: pointer
        width: 14px
        height: 14px
        stroke: $title

  // écran grisé sous la modale
  .modal-screen
    position: fixed
    top: 0
    bottom: 0
    left: 0
    right: 0
    background-color: rgba($body, 0.7)
    z-index: 5

  // animations pour apparition, et la disparition de la modale
  .screen-enter-active
    transition: opacity .9s

  .screen-leave-active
    transition: opacity .3s

  .screen-enter, .screen-leave-to
    opacity: 0

  .modal-enter-active
    transition: all 0s

  .modal-enter-active, .modal-leave-active
    right: -250px
</style>
