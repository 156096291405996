<template>
    <router-link
        :to="goToConfiguration(matching)"
        class="matching card"
        :class="{
            'selected': $route.name.includes('edit') && selectedMatching && selectedMatching.version && matching && matching.version && selectedMatching.version.versionId === matching.version.versionId && (
            ((!matching.land || !matching.land.landId) && (!selectedMatching.land || !selectedMatching.land.landId)) ||
            (matching.land && selectedMatching.land && matching.land.landId === selectedMatching.land.landId)
            ),
            'no-land': matching && (!matching.land || ($route.query.landId && matching.land.landId !== $route.query.landId)) && landChoice !== 'no-land',
        }"
        @click.native="() => selectMatching(matching)"
        >
        <favorite id="Share-Favorite" v-if="matching && matching.version" @click.prevent="toggleFavorite(matching)" class="favorite" :class="{'favorite-active': matchingFavorite.some((m) => m === matching.version.versionId), 'favorite-inactive': !matchingFavorite.some((m) => m === matching.version.versionId)}" />
        <gallery-carousel v-if="matching && matching.version && matching.versionMedias" :images="matching.versionMedias" :imagesType="imagesType"/>
        <div v-else class="image-not-found">
            <upload class="icon-upload" />
        </div>
        <div class="card-content">
          <div class="model-version-container" v-if="matching">
            <h4 v-if="matching.version" class="model">Version {{ matching.version.name }}</h4>
            <h3 v-if="matching.version">Maison {{ matching.version.numberOfRooms }} chambre{{ matching.version.numberOfRooms > 1 ? 's' : '' }}</h3>
            <p class="land" v-if="matching.land && matching.land.allotment && matching.land.allotment.name && matching.land">Lotissement "{{ matching.land.allotment.name }}" n°{{ matching.land.lotNumber }} - {{ matching.land.surface }}m²</p>
            <h4 class="land" v-else-if="matching.land && !matching.land.landId && matching.land.width">Mon terrain Larg. {{ matching.land.width }} m</h4>
            <h4 class="land" v-else-if="matching.land && matching.land.landId">Terrain - {{ matching.land.surface }}m²</h4>
            <h4 class="land no-land" v-else-if="landChoice !== 'no-land'">Aucun terrain compatible</h4>
          </div>
          <div class="price-container">
            <div>
              <div class="price" v-if="matching && matching.priceWithoutAdditionalFees && landChoice === 'no-land'">
                <h3>{{ matching.priceWithoutAdditionalFees || matching.priceWithoutAdditionalFees === 0 ? utils.formatCentToEuro(matching.priceWithoutAdditionalFees, true, true)  : '' }}</h3>
              </div>
              <div class="price" v-else-if="matching && matching.price && landChoice !== 'no-land'">
                <h3>{{ matching.price || matching.price === 0 ? utils.formatCentToEuro(matching.price, true, true)  : '' }}</h3>
              </div>
              <div v-else class="price">
                <div class="white-spinner">
                  <app-spinner small />
                  <h3>€</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
    </router-link>
</template>
<script>
import GalleryCarousel from '@/components/general/GalleryCarousel.vue';
import favorite from '@/assets/img/favorite.svg?inline';
import ls from 'local-storage';
import utils from '@/services/utils/utils';

export default {
  name: 'matching-card',
  components: {
    GalleryCarousel,
    favorite,
  },
  props: {
    matching: null,
    landChoice: null,
    imagesType: null,
  },
  data() {
    return {
      matchingFavorite: ls('MATCHING_FAVORITE') || [],
      utils,
      selectedMatching: null,
    };
  },
  methods: {
    selectMatching(matching) {
      if (this.$route.name.includes('edit')) {
        this.selectedMatching = matching;
      }
    },
    goToConfiguration(matching) {
      let isClayZone = null;
      if (Array.isArray(this.$route.query.isClayZone)) {
        isClayZone = this.$route.query.isClayZone.length ? this.$route.query.isClayZone[0] : undefined;
      } else {
        isClayZone = this.$route.query.isClayZone;
      }

      // Définition du postalCode
      let postalCode;

      if (this.$route.query.postalCodeInseeNumber) {
        postalCode = this.$route.query.postalCodeInseeNumber;
      }

      if (matching.land && matching.land.address && matching.land.address.postalCodeInseeNumber) {
        postalCode = matching.land.address.postalCodeInseeNumber;
      }

      if (matching.land && matching.land.postalCodeInseeNumber) {
        postalCode = matching.land.postalCodeInseeNumber;
      }

      if (!this.$route.name.includes('edit')) {
        const queries = {
          // id terrain
          ...(matching.land && matching.land.landId && { landId: matching.land.landId }),
          // id version
          ...(matching.version && matching.version.versionId && { versionId: matching.version.versionId }),
          // les params pour le terrain custom
          ...(this.$route.query.landWidth && matching.land && { landWidth: this.$route.query.landWidth }),
          ...(this.$route.query.landSurface && matching.land && { landSurface: this.$route.query.landSurface }),
          ...(this.$route.query.landPrice && matching.land && { landPrice: this.$route.query.landPrice }),
          ...(this.$route.query.isMainDrainagePlanned && matching.land && { isMainDrainagePlanned: this.$route.query.isMainDrainagePlanned }),
          ...(this.$route.query.isServiced && matching.land && { isServiced: this.$route.query.isServiced }),
          ...(this.$route.query.isClayZone && matching.land && { isClayZone }),
          // id avant-projet
          ...(this.$route.query.projectDraftId && { projectDraftId: this.$route.query.projectDraftId }),
          // id client
          ...(this.$route.query.customerId && { customerId: this.$route.query.customerId }),
          // id code postal
          ...({ postalCodeInseeNumber: postalCode }),
          //
          ...(this.$route.query.emptyLand && { emptyLand: this.$route.query.emptyLand }),
          // Paramètres de recherche
          ...(this.$route.query.modelId && { modelId: this.$route.query.modelId }),
          ...(this.$route.query.orderBy && { orderBy: this.$route.query.orderBy }),
          ...(this.$route.query.priceMin && { priceMin: this.$route.query.priceMin }),
          ...(this.$route.query.priceMax && { priceMax: this.$route.query.priceMax }),
          ...(this.$route.query.roomCount && { roomCount: this.$route.query.roomCount }),
          ...(this.$route.query.bathroomCount && { bathroomCount: this.$route.query.bathroomCount }),
          ...(this.$route.query.floorId && { floorId: this.$route.query.floorId }),
          ...(this.$route.query.selectedRange && { selectedRange: this.$route.query.selectedRange }),
          ...(this.$route.query.surfaceMin && { surfaceMin: this.$route.query.surfaceMin }),
          ...(this.$route.query.surfaceMax && { surfaceMax: this.$route.query.surfaceMax }),
          ...(this.$route.query.garage && { garage: this.$route.query.garage }),
        };

        return {
          name: 'configuration',
          query: queries,
          params: {
            ...(this.$route.query.projectDraftId && { projectDraftId: this.$route.query.projectDraftId }),
            tab: 'version',
          },
        };
      }
      return {
        ...this.$route,
      };
    },
    toggleFavorite(matching) {
      this.matchingFavorite = ls('MATCHING_FAVORITE') || [];
      if (this.matchingFavorite.length > 0 && this.matchingFavorite.some((m) => m === matching.version.versionId)) {
        this.matchingFavorite = this.matchingFavorite.filter((m) => m !== matching.version.versionId);
        ls('MATCHING_FAVORITE', this.matchingFavorite);
      } else {
        this.matchingFavorite.push(matching.version.versionId);
        ls('MATCHING_FAVORITE', this.matchingFavorite);
        this.$emit('share', matching);
      }
    },
  },
};

</script>

<style lang="sass" scoped>
.price-container
  display: flex
  justify-content: flex-end!important
</style>
