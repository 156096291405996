import { render, staticRenderFns } from "./AppDoubleSlider.vue?vue&type=template&id=6a220d87&scoped=true&"
import script from "./AppDoubleSlider.vue?vue&type=script&lang=js&"
export * from "./AppDoubleSlider.vue?vue&type=script&lang=js&"
import style0 from "./AppDoubleSlider.vue?vue&type=style&index=0&id=6a220d87&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a220d87",
  null
  
)

export default component.exports