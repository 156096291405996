<template>
  <div class="configurator-search">
    <div class="grid-x row">
      <div class="cell auto">
        <div class="search-bar">

        <!-- Recherche de la commune -->
          <div class="search-bar-city" v-if="!isCityValidated">
            <h3>Démarrez votre configuration ici !</h3>
            <button v-if="!isMobile" class="construction-zones" @click="isConstructionModalOpen = true">Voir nos zones de construction</button>
            <app-search-city
              placeholder="Commune ou code postal"
              @select="setSelectedPostalCode"
              @clear="resetSelectedPostalCode"
              @submit-city="submitCity"
              :selectedPostalCode="selectedPostalCode"
              :required="true"
              size="large"
            />
            <p>Le chiffrage de votre projet de construction en moins de 2 minutes ! <button @click="isEstimateModalOpen = true">En savoir plus</button></p>
          </div>
          <div class="search-bar-type" v-else-if="!isGammeValidated && !isMobile">
            <h3>Quel est votre projet ?</h3>
            <div class="grid-x">
              <div class="cell large-auto medium-12 small-12" @click="submitGamme('0b02191d-e01c-431d-a822-7b00d23fc0e1')">
                <div class="rangeCard">
                  <img src="@/assets/img/pages/configurator/pre_filtrage_invest.jpg"/>
                  <div class="description desktop">
                    <span class="title invest">GAMME INVEST</span>
                    <p>Pour de l'investissement locatif</p>
                  </div>
                </div>
              </div>
              <div class="cell large-auto medium-12 small-12" @click="submitGamme('a6bc3b29-cfa6-4fcb-96a0-946e17985373')">
                <div class="rangeCard">
                  <img src="@/assets/img/pages/configurator/pre_filtrage_residentielle.jpg"/>
                  <div class="description desktop">
                    <span class="title residentielle">GAMME RÉSIDENTIELLE</span>
                    <p>Nos maisons ultra-personnalisables</p>
                  </div>
                </div>
              </div>
              <div class="cell large-auto medium-12 small-12" @click="submitGamme('83d94b0b-f726-44fd-b77b-5939311e9c93')">
                <div class="rangeCard">
                  <img src="@/assets/img/pages/configurator/pre_filtrage_access.jpg"/>
                  <div class="description desktop">
                    <span class="title access">SÉLECTION ACCESS'</span>
                    <p>Nos modèles à petits prix</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!---->

          <!-- Recherce Terrain  -->
          <div class="search-bar-type" v-else>
            <h3>Que recherchez vous ?</h3>
            <div class="grid-x">
              <div class="cell large-auto medium-12 small-12">
                <router-link
                  :to="{ name: 'matchings', query: { landChoice: 'custom-land', postalCodeInseeNumber: postalCodeInseeNumber, selectedRange: selectedGamme }}">
                  <card-search-bar title="Je possède un terrain" subtitle="Trouver une maison">
                    <findhouse />
                  </card-search-bar>
                </router-link>
              </div>
              <div class="cell large-auto medium-12 small-12">
                <router-link
                  :to="{ name: 'matchings', query: { landChoice: 'land', postalCodeInseeNumber: postalCodeInseeNumber, selectedRange: selectedGamme }}">
                  <card-search-bar title="Un terrain + Une maison" subtitle="Rechercher">
                    <lookforhouse />
                  </card-search-bar>
                </router-link>
              </div>
              <div class="cell large-auto medium-12 small-12">
                <router-link
                  :to="{ name: 'matchings', query: { landChoice: 'no-land', postalCodeInseeNumber: postalCodeInseeNumber, emptyLand: true, selectedRange: selectedGamme }}">
                  <card-search-bar title="Toutes les maisons" subtitle="Afficher">
                    <onlyHouse />
                  </card-search-bar>
                </router-link>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!-- Overlay -->
    <div class="overlay" v-if="isConstructionModalOpen || isEstimateModalOpen" @click="isConstructionModalOpen = false; isEstimateModalOpen = false"/>

    <!-- Modale Zone de construction -->
    <div v-if="isConstructionModalOpen" class="construction-modal custom-modal">
      <div class="modal-content">
        <button @click="isConstructionModalOpen = false" aria-label="fermer"><close /></button>
        <img src="@/assets/img/zones-de-construction.jpg"
          alt="Zone de construction : 14, 17, 22 (partiel), 27, 28, 29 (partiel), 33 (partiel), 35, 37, 44, 49, 56, 72, 79, 85, 86" />
        <div class="text-container">
          <h3>Un projet dans une zone limitrophe ?</h3>
          <p>Contactez l'agence la plus proche !</p>
        </div>
      </div>
    </div>

    <!-- Modale  Chiffrage-->
    <div v-if="isEstimateModalOpen" class="estimate-modal custom-modal">
      <div class="modal-content">
        <button @click="isEstimateModalOpen = false" aria-label="fermer"><close /></button>
        <div class="text-container">
          <h3>Votre chiffrage en moins de 2 minutes ?</h3>
          <p>
            <strong>Rien de plus facile avec notre incroyable configurateur.</strong>
          </p>
          <p>
            <ul>
              <li>Plus de 2000 annonces terrains</li>
              <li>Des centaines de modèles de maisons</li>
              <li>Un large choix de finitions</li>
              <li>Votre descriptif complet à télécharger</li>
              <li>Votre future mensualité personnalisée</li>
            </ul>
          </p>
          <app-button @click="$router.push({ name: 'configurator' })">En savoir plus</app-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import findhouse from '@/assets/img/findhouse.svg?inline';
import lookforhouse from '@/assets/img/lookforhouse.svg?inline';
import onlyHouse from '@/assets/img/only-house.svg?inline';
import close from '@/assets/img/close.svg?inline';

import CardSearchBar from '@/components/general/CardSearchBar.vue';

export default {
  name: 'configurator-search',
  components: {
    findhouse,
    lookforhouse,
    onlyHouse,
    close,
    CardSearchBar,
  },
  data() {
    return {
      isCityValidated: null,
      isConstructionModalOpen: false,
      isGammeValidated: null,
      isModalOpen: false,
      isEstimateModalOpen: false,

      selectedPostalCode: null,
      postalCodeInseeNumber: null,
      selectedGamme: null,
      isMobile: false,
    };
  },
  props: {
    inputPostalCode: {
      type: String,
      default: null,
    },
  },
  mounted() {
    this.isMobile = window.innerWidth < 768;
    if (this.inputPostalCode) {
      this.postalCodeInseeNumber = this.inputPostalCode;
      this.submitCity();
    }
  },
  methods: {
    submitCity() {
      if (this.postalCodeInseeNumber && this.postalCodeInseeNumber.length > 4) {
        this.isCityValidated = true;
        if (window.innerWidth < 768 && this.$route.name !== 'account') {
          this.$router.push({ name: 'mobile-matchings', query: { postalCodeInseeNumber: this.postalCodeInseeNumber } });
        }
      }
    },
    submitGamme(selecte) {
      this.isGammeValidated = true;
      this.selectedGamme = selecte;
    },
    scrollToTop() {
      const top = document.querySelector('.home');
      top.scrollIntoView({ behavior: 'smooth' });
    },
    resetSelectedPostalCode() {
      this.selectedPostalCode = null;
      this.postalCodeInseeNumber = null;
    },
    setSelectedPostalCode(value) {
      this.selectedPostalCode = value.label;
      this.postalCodeInseeNumber = value.name;
    },
  },
};
</script>

<style lang="sass" scoped>
.configurator-search
  .grid-x
    margin: 0
  .search-bar
    @include search-bar
    margin: auto
    text-align: left
    .search-bar-city
      display: flex
      justify-content: space-between
      flex-wrap: wrap
      row-gap: 16px
      button
        all: unset
        @include link
        color: #56E7F3
        cursor: pointer
        &:focus
          outline: 1px solid currentcolor
          border-radius: 8px
      h3
        color: $white
        font-weight: bold
      .app-search-city
        flex-grow: 2
        width: 100%
      p
        @include small-body
        color: $line
        margin: O
    .search-bar-type
      h3
        max-width: 100%
        color: $white
        font-weight: bold
        margin-bottom: 1rem
        text-align: center
      a
        text-decoration: none
      .banner
        width: 100%
        height: 120px
        display: inline-block
        border-radius: $global-border-radius
        margin-top: 1rem
        overflow: hidden
        img
          width: 100%

    @media (max-width: 1024px)
      width: 100%
      .search-bar-city
        row-gap: 9px
        h3
          @include subtitle-mobile
        p
          text-align: left
        a
          text-align: center
          width: 100%
          margin-top: 10px
          display: block
        .app-search-city
          margin: 10px 0

  .overlay
    width: 100vw
    height: 100vh
    position: fixed
    top: 0
    bottom: 0
    right: 0
    left: 0
    background: rgba(0, 0, 0, 0.7)
    z-index: 10

  .custom-modal
    position: fixed
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    z-index: 11
    border-radius: $global-border-radius
    overflow: hidden
    .modal-content
      position: relative
      > button
          all: unset
          position: absolute
          right: 0
          top: 0
          padding: 1rem
          cursor: pointer
          svg
            width: 18px
            stroke: $black

    @media (max-width: 1024px)
      width: 80vw
      max-width: 80vw
      margin: auto
  .construction-modal
    width: 400px
    background: $white
    .modal-content
      width: 100%
      img
        width: 100%
      .text-container
        padding: 32px
        text-align: center
        h3
          font-size: 29px
          color: $black
          margin-bottom: 0.5rem
        p
          font-size: 14px
          color: $subtitle

  .estimate-modal
    .modal-content
      width: 900px
      background: url(~@/assets/img/background-estimate.png) no-repeat center center
      background-size: cover
      .text-container
        width: 50%
        box-sizing: border-box
        margin-left: auto
        padding: 5rem 3rem
        background: rgba(255, 255, 255, 0.9)
        h3
          font-size: 29px
          color: $black
          margin-bottom: 0.5rem
        p
          margin: 2rem 0
          color: $subtitle
          strong
            @include subtitle
            font-weight: 700
          ul
            padding: 0
            li
              list-style: outside disc
              transform: translateX(1rem)
              text-align: left !important
    @media (max-width: 1024px)
      .modal-content
        width: 100%
        .text-container
          width: 100%
          padding: 2.5rem 1.5rem
          h3
            font-size: 24px
          p
            margin: 1rem 0
            text-align: center
          a
            text-align: center
            width: 100%
            margin-top: 10px
            display: block
  .config-type
    flex-direction: row !important
    padding: 0 16px !important
    .icon
      display: flex
      justify-content: center
      align-items: center
  .rangeCard
    cursor: pointer
    display: flex
    flex-direction: column
    border-radius: 10px
    background: white
    overflow: hidden
    img:hover
      z-index: 1
      transform: scale(1.15)
      transition: transform 0.5s
    @media (max-width: 768px)
      img
        display: none
      .description
        text-align: left!important
        padding-left: 15px
        .invest-mobile
          color: $marine !important
        .residentielle-mobile
          color: $primary !important
        .access-mobile, .orange
          color: $warning !important
        p
          text-align: left!important
          &.text
            font-size: 12px
            line-height: 16px
            margin-bottom: 16px
    .description
      &.desktop
        background: white
        padding-top: 0!important
        z-index: 2
        p
          font-size: 16px
          padding-top: 24px
        .title
          padding: 12px 20px
          color: white
          border-radius: 8px
          margin-bottom: 16px
          &.invest
          background-color: $marine
          &.residentielle
            background-color: $primary
          &.access
            background-color: $warning
      text-align: center
      padding-top: 15px
      p
        color: $subtitle
        font-size: 14px
      .title
        @include body-bold
        color: $primary
</style>
