import axios from 'axios';

const { VUE_APP_API_URL, VUE_APP_BRAND_ID } = process.env;

/**
 * Récupère un modèle
 * @param {string} modelId: id du modèle à récupérer
 */
const getById = async (modelId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/public/brands/${VUE_APP_BRAND_ID}/models/${modelId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupère la liste des modèles
 * @param {number} limit: nombre d'éléments à récupérer
 * @param {number} offset: offset de la liste
 * @param {string} search: paramètre de recherche, sur le nom du modèle
 * @param {string} rangeId: id de la gamme
 * @param {boolean} noLimit: bool, pour savoir si on veut utiliser la pagination ou non
 * @param {string} packId: id du pack
 */
const getAll = async (limit, offset, search, rangeId, nolimit, packId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/public/brands/${VUE_APP_BRAND_ID}/models`, {
      params: {
        ...(limit && { limit }),
        ...(offset && { offset }),
        ...(search && { search }),
        ...(rangeId && { rangeId }),
        ...(nolimit && { nolimit }),
        ...(packId && { packId }),
      },
    });
    return response.data;
  } catch (er) {
    throw er;
  }
};

const model = {};
model.getAll = getAll;
model.getById = getById;

export default model;
