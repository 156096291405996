<template>
  <footer class="footer">
    <div class="grid-y">

      <div class="footer1">
        <div class="footer-top">
          <div class="logo-container">
            <img src="~@/assets/logos/logo-white.svg" />
          </div>
          <div class="cta-container">
            <!-- scroll jusqu'au configurateur quand on est sur la home -->
            <app-button hasArrow @click="$route.name !=='home' ? $router.push({ name: 'home', hash: '#hero' }) : scrollTo('hero')">Configurer</app-button>
            <!-- scroll jusqu'à la carte si on est déjà sur la page -->
            <app-button theme="secondary" @click="$route.name !=='constructor-network' ? $router.push({ name: 'constructor-network', hash: '#carte' }) : scrollTo('carte')">Nos agences</app-button>
          </div>
          <div class="link-container">
            <router-link :to="{ name: 'project-house-construction' }">Où faire construire sa maison</router-link>
            <router-link :to="{ name: 'our-house-models' }">Nos modèles de maisons</router-link>
            <router-link :to="{ name: 'who-we-are' }">Qui sommes-nous ?</router-link>
            <router-link :to="{ name: 'securities' }">Les garanties</router-link>
          </div>
          <div class="contact-container">
            <div>
              <a href="https://group-alysia.com/" target="_blank" >Rejoindre la franchise</a>
              <p>Envie de devenir votre propre patron ? Rejoignez la marque qui révolutionne l’achat maison.</p>
            </div>
            <div>
              <strong>Suivez-nous</strong>
              <div class="social-network-container">
                <a href="https://fr-fr.facebook.com/maisonsalysia/" target="_blank"><img src="@/assets/img/facebook.svg" alt="Facebook"></a>
                <a href="https://www.instagram.com/maisonsalysia/?hl=fr" target="_blank"><img src="@/assets/img/instagram.svg" alt="Instagram"></a>
               <a href="https://fr.linkedin.com/company/maisons-alysia" target="_blank"><img src="@/assets/img/linkedin.svg" alt="Linkedin"></a>
              </div>
            </div>
          </div>
        </div>

        <div class="footer-top departments">
          <ul>
            <li v-for="department in departments" :key="department.id">
              <router-link :to="{ name: 'department', params: { dpt: `${department.slug}-${department.content.number}` } }">Construire en {{ department.name }}</router-link>
            </li>
          </ul>
        </div>

      </div>
    </div>
    <div class="footer-bottom grid-x">
      <div class="credits cell medium-3">
        <p>© {{ new Date().getFullYear() }} - <a href="https://group-alysia.com/" target="_blank">Group Alysia</a></p>
      </div>
      <div class="useful-links cell medium-6">
        <p class="infos">
          <a href="/docs/mentions-legales.pdf" target="blank">Mentions légales</a> | <a href="/docs/conditions-genenales-d-utilisation.pdf" target="blank">Conditions générales d’utilisation</a> | <router-link :to="{ name: 'sitemap', params: {tab: 'maisons-alysia'} }">Plan du site</router-link>
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import authApi from '@/services/auth';
import Storyblok from '@/services/storyblok';

export default {
  name: 'app-footer',
  data() {
    return {
      isLoggedIn: null,
      departments: [],
    };
  },
  watch: {
    $route() {
      this.isLoggedIn = authApi.isLoggedIn();
    },
  },
  async mounted() {
    await this.getDepartments();
  },
  methods: {
    async logout() {
      await authApi.logout();
      this.isLoggedIn = authApi.isLoggedIn();
    },
    scrollTo(anchorName) {
      const anchor = document.getElementById(anchorName);
      if (anchor) {
        anchor.scrollIntoView();
      }
    },
    openShare() {
      this.$socialShares.show({});
    },
    async getDepartments() {
      try {
        const response = await Storyblok.get('cdn/stories/', {
          version: process.env.VUE_APP_STORYBLOK_ENV === 'development' ? 'draft' : 'published',
          starts_with: 'departments/',
        });
        this.departments = response.data.stories.length !== 0 ? response.data.stories.sort((a, b) => a.slug.localeCompare(b.slug)) : [];
      } catch (err) {
        throw err;
      }
    },
  },
};

</script>

<style lang="sass" scoped>
.footer
  .footer1
    .footer-top
      background: $subtitle
      padding-top: 65px
      display: flex
      justify-content: space-around
      flex-wrap: nowrap
      &.departments
        padding-left: 200px
      ul
        display: grid
        grid-template-columns: repeat(3, 1fr)
        gap: 2rem 6rem
        padding: 0 0 100px 0
        li > a
          font-weight: 400
      a
        @include cta
        text-decoration: none
        color: $white
      .logo-container
        img
          width: 200px
      .cta-container
        display: flex
        flex-direction: column
        gap: 40px
      .link-container
        display: flex
        flex-direction: column
        gap: 2rem
      .contact-container
        display: flex
        flex-direction: column
        gap: 2rem
        p
          max-width: 20rem
          margin-top: 0.75rem
          color: $line
        strong
          display: block
          @include cta
          color: $white
          margin-bottom: 0.75rem
        .social-network-container
          display: flex
          justify-content: flex-start
          gap: 1rem
          img
            width: 32px
            height: 32px

  .footer-bottom
    background: $black
    padding: 24px 0
    p, a
        color: $line
        text-decoration: none
        font-size: 12px
        line-height: 16px
    .credits
      display: flex
      justify-content: center
      p, a
        font-weight: 300
      a
        color: $primary
    .useful-links
      display: flex
      justify-content: center
  .light-button button
    background-color: transparent
    border: $white 2px solid
  .light-button button:hover
    background-color: $primary
    border: $primary 2px solid
  .share
    margin-top: 20px
// Responsive mobile
@media (max-width: 1024px)
  .footer
    .footer1
      display: flex
      flex-direction: column
      justify-content: flex-start
      .footer-top
        flex-direction: column
        align-items: center
        padding: 1rem 1rem!important
        ul
          margin-top: 20px
          grid-template-columns: repeat(1, 1fr)
          gap: 2rem
          padding-bottom: 50px
        > div
          align-items: center
          text-align: center
        .link-container
          gap: 1.5rem
          margin: 50px 0px
        .logo-container
          width: fit-content
          padding-top: 50px
        .cta-container
          button
            width: fit-content
          margin: 40px 0px
        .link-container
          width: fit-content
          gap: 32px
        .contact-container
          width: fit-content

          .social-network-container
            gap: 1rem
        .footer-right
          padding: 40px 0
          border-left: none
          position: relative
          p
            width: 80%
            text-align: center
            font-size: 12px
            line-height: 16px
            font-weight: 300
            color: $white
          .small-title
            font-weight: 700
    .footer-bottom
      padding: 30px 24px
      p
        text-align: center
      .useful-links
        margin-top: 18px
</style>
