<!--
****************************************************************************************************
***                                      Collapse                                                ***
****************************************************************************************************

Collapse

*************
*** PROPS ***
*************

  Exemple :
  <app-collapse v-model="activeTab" :name="1">
    Lorem Ipsum
  </app-collapse>

  <app-collapse v-model="activeTab" :name="2">
    Lorem Ipsum
  </app-collapse>

  <app-collapse v-model="activeTab" :name="3">
    Lorem Ipsum
  </app-collapse>

-->
<template>
  <div class="app-collapse">
    <div class="app-collapse-content" :style="{ height: scrollHeight !== null ? `${scrollHeight}px` : 'auto', transition }">
      <div ref="collapse" v-if="$refs"><slot></slot></div>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'input',
    event: 'input',
  },
  props: {
    input: [String, Number, Array],
    name: [String],
  },
  data() {
    return {
      scrollHeight: 0,
      transition: 'none',
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    input: 'update',
  },
  methods: {
    update() {
      this.transition = 'all 0.3s linear';
      this.init();
    },
    init() {
      const { clientHeight } = this.$refs.collapse;
      if (Array.isArray(this.input)) {
        this.scrollHeight = this.input.includes(this.name) ? clientHeight : 0;
      } else {
        this.scrollHeight = (this.input === this.name) ? clientHeight : 0;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.app-collapse
  .app-collapse-content
    overflow: hidden
</style>
