<template>
  <section class="mobile-matchings-list">
    <div class="list-header">
      <p v-if="filteredMatchings">{{ filteredMatchings.length }} projets sur votre commune</p>
      <app-button title="Ouvrir les filtres" icon="filter" size="small" @click="isFiltersOpen = true" />
    </div>
    <app-spinner v-if="isLoading" />

    <div class="list-content">
      <template v-for="matching in filteredMatchings">
        <mobile-matching-card
          v-if="!matching.isAd"
          :key="`${matching.version.versionId}`"
          :matching="matching"
          v-intersection="handleCardIntersection"
          :data-version="matching.version.versionId" />
        <mobile-matching-vignette
          v-else
          :key="`${matching.slug}`"
          :ad="matching" />
      </template>
    </div>

    <mobile-matchings-filters @update:isOpen="isFiltersOpen = false" :isOpen="isFiltersOpen" />
  </section>
</template>

<script>
import configurationApi from '@/services/api/configuration';
import postalCodeApi from '@/services/api/postalCode';
import agencyApi from '@/services/api/agency';
import Storyblok from '@/services/storyblok';

import MobileMatchingCard from './MobileMatchingCard.vue';
import MobileMatchingsFilters from './MobileMatchingsFilters.vue';
import MobileMatchingVignette from './MobileMatchingVignette.vue';

export default {
  name: 'mobile-matchings-list',
  components: {
    MobileMatchingCard,
    MobileMatchingsFilters,
    MobileMatchingVignette,
  },
  data() {
    return {
      matchings: [],
      agencyCode: null,
      ads: [],
      isLoading: false,
      isPriceLoaded: false,
      isFiltersOpen: false,
      filters: {
        orderBy: this.$route.query.orderBy,
        priceRange: this.$route.query.priceRange,
        selectedRange: this.$route.query.selectedRange,
        habitableSurfaceRange: this.$route.query.habitableSurfaceRange,
        floorId: this.$route.query.floorId,
        modelId: this.$route.query.modelId,
      },
      postalCode: null,
      customerLand: {
        width: 30,
        surface: 250,
        price: 0,
      },
      pricedVersions: {},
      toPriceVersions: {},
      filteredMatchings: null,
    };
  },
  async mounted() {
    this.getAgency();
    this.getAds();
    this.getMainData();
  },
  watch: {
    $route() {
      this.filters = {
        orderBy: this.$route.query.orderBy,
        priceRange: this.$route.query.priceRange,
        selectedRange: this.$route.query.selectedRange,
        habitableSurfaceRange: this.$route.query.habitableSurfaceRange,
        floorId: this.$route.query.floorId,
        modelId: this.$route.query.modelId,
      };
      this.filterAndSortMatchings();
    },
    postalCode() {
      this.customerLand.price = this.postalCode.landToBuildAveragePrice * this.customerLand.surface;
    },
  },
  methods: {
    async handleCardIntersection(event) {
      if (!this.toPriceVersions[event.target.dataset.version]) {
        this.toPriceVersions[event.target.dataset.version] = true;
      }
      this.getMatchingsPrices();
    },
    filterAndSortMatchings() {
      let filteredMatchings = this.matchings.filter((m) => {
        if (m.version) {
          // Filtre : nombre de chambres
          if (m.version.numberOfRooms !== +this.$route.query.nbRooms) {
            return false;
          }

          // Filtre surface habitable
          if (this.filters.habitableSurfaceRange) {
            const [minSurface, maxSurface] = this.filters.habitableSurfaceRange.split('-');
            if (m.totalSurfaceHabitable < minSurface) {
              return false;
            }
            if (m.totalSurfaceHabitable > maxSurface) {
              return false;
            }
          }

          // Filtre prix
          if (this.filters.priceRange) {
            const [minPrice, maxPrice] = this.filters.priceRange.split('-');
            if ((m.totalPrice || m.totalPricePrimary) < +minPrice * 100) {
              return false;
            }
            if (maxPrice !== 'infinite' && (m.totalPrice || m.totalPricePrimary) > +maxPrice * 100) {
              return false;
            }
          }

          // Filtre Niveaux
          // Si c'est le rez-de-chaussée, ça soit être le seul niveau (sauf si c'est des combles aménageables)
          if (this.filters.floorId === 'd0e1fedc-c9a8-4f59-8f30-66d897b0a951') {
            const surfaces = m.version.surfaces.filter((surface) => surface.floorId !== this.filters.floorId && surface.floorId !== 'c166aea2-c111-4ed6-97bf-0ec3697c9cf2');
            if (surfaces && surfaces.length) {
              return false;
            }
          } else if (this.filters.floorId) {
            const surfaces = m.version.surfaces.filter((surface) => surface.floorId === this.filters.floorId);
            if (!surfaces || !surfaces.length) {
              return false;
            }
          }

          // Filtre Model
          if (this.filters.modelId && m.version.model.modelId !== this.filters.modelId) {
            return false;
          }

          // Filtre gamme
          if (
            this.filters.selectedRange
            && m.version.range
            && this.filters.selectedRange !== m.version.range.rangeId
          ) {
            return false;
          }
          if (!this.filters.selectedRange && m.version.range) {
            // S'il n'y a pas de gamme sélectionnée, on affiche tous les modèles sauf ceux de la gamme INVEST
            return m.version.range.rangeId !== '0b02191d-e01c-431d-a822-7b00d23fc0e1';
          }
        }

        return true;
      });

      // Tri Prix
      filteredMatchings = filteredMatchings.sort((matchingA, matchingB) => {
        const priceA = matchingA.totalPrice || matchingA.version.pricePrimary;
        const priceB = matchingB.totalPrice || matchingB.version.pricePrimary;
        if (this.filters.orderBy === '-price') {
          return priceB - priceA;
        }
        return priceA - priceB;
      });

      // Ajout des ads
      let addedAds = 0;
      const filterAds = this.ads.filter((el) => el.agencyCode === this.agencyCode || el.agencyCode === null);

      filteredMatchings.forEach((_, index) => {
        // La première pub est en 3ème position, puis les autres toutes les 5 versions
        if ((index === 3 || (index - 3) % 5 === 0) && addedAds < filterAds.length) {
          filteredMatchings.splice(index + addedAds, 0, filterAds[addedAds]);
          addedAds += 1;
        }
      });

      this.filteredMatchings = filteredMatchings;
    },
    initMatchings() {
      let sortedMatchings = this.matchings.sort((matchingA, matchingB) => matchingA.totalSurfaceHabitable - matchingB.totalSurfaceHabitable);

      if (!this.filters.selectedRange) {
        const sortedAccess = sortedMatchings.filter((matching) => matching.version.range && matching.version.range.rangeId === '83d94b0b-f726-44fd-b77b-5939311e9c93');
        const sortedResidentielle = sortedMatchings.filter((matching) => matching.version.range && matching.version.range.rangeId === 'a6bc3b29-cfa6-4fcb-96a0-946e17985373');
        sortedMatchings = [...sortedAccess, ...sortedResidentielle];
      }

      this.filteredMatchings = sortedMatchings;
      this.filterAndSortMatchings();
    },
    initPricingSystems() {
      this.pricedVersions = {};
      this.toPriceVersions = {};
    },
    async getMainData() {
      // Récupération des données principales
      this.isLoading = true;
      await this.getPostalCode();
      await this.getMatchings();
      this.initMatchings();

      this.isLoading = false;

      // Récupération des prix exacts des matchings
      this.getMatchingsPrices();
    },
    async getAgency() {
      if (!this.$route.query.postalCodeInseeNumber) {
        this.agencyCode = null;
      } else {
        try {
          const agency = await agencyApi.getByPostalCode(this.$route.query.postalCodeInseeNumber);
          this.agencyCode = agency.code;
        } catch (err) {
          this.agencyCode = null;
        }
      }
    },
    async getAds() {
      try {
        const response = await Storyblok.get('cdn/stories/', {
          version: process.env.VUE_APP_STORYBLOK_ENV === 'development' ? 'draft' : 'published',
          starts_with: 'vignettes/',
          sort_by: 'created_at:desc',
          per_page: 100,
        });
        this.ads = response.data.stories.map((story) => ({
          slug: story.slug || null,
          name: story.content.name || null,
          agencyCode: story.content.agencyCode || null,
          backgroundColor: story.content.backgroundColor || null,
          description: story.content.description || null,
          finePrints: story.content.finePrints || null,
          image: story.content.image,
          isFlashNews: story.content.isFlashNews,
          link: story.content.link,
          isAd: true,
        }));
      } catch (error) {
        // L'absence de publicité ne doit pas gêner la navigation
        // eslint-disable-next-line no-empty
      }
    },
    async getPostalCode() {
      try {
        this.postalCode = await postalCodeApi.getOne(this.$route.query.postalCodeInseeNumber);
      } catch (e) {
        this.postalCode = {
          postalCodeInseeNumber: this.$route.query.postalCodeInseeNumber,
          landToBuildAveragePrice: 0,
        };
      }
    },
    async getMatchings() {
      try {
        const response = await configurationApi.getAll(
          this.$route.query.postalCodeInseeNumber,
          false,
          this.customerLand.width,
          this.customerLand.surface,
          this.customerLand.price,
          true,
          true,
          null,
          null,
          this.$route.query.packId,
        );
        this.matchings = response;
      } catch (err) {
        this.$message.show({ title: 'error', text: 'Une erreur est arrivée' });
      }
    },
    async getMatchingsPrices() {
      this.isPriceLoaded = false;

      // On tri les matchings par pricePrimary croissant ou l'inverse afin d'aller chercher le vrai prix des versions demandées en priorité
      let matchingsCopy = this.matchings.slice();

      // On filtre par les versions qui on besoin d un prix
      matchingsCopy = matchingsCopy.filter((matchingElement) => this.toPriceVersions[matchingElement.version?.versionId] && !this.pricedVersions[matchingElement.version?.versionId]);

      // Définition des chunks
      const chunks = [];
      const chunkSize = 12;
      for (let i = 0; i < matchingsCopy.length; i += chunkSize) {
        const chunk = matchingsCopy.slice(i, i + chunkSize);
        chunks.push(chunk);
      }

      // Récupération des prix par chunk
      /* eslint-disable no-await-in-loop */
      for (let i = 0; i < chunks.length; i += 1) {
        const chunkPromises = chunks[i].map(async (matching) => {
          // Récupération du prix exact de base
          const { price, priceWithoutAdditionalFees } = await this.getPrice(matching.version.versionId, matching.land ? matching.land.landId : null);

          // Mise à jour dans filtered matchings
          const fMatchingIndex = this.filteredMatchings.findIndex((m) => m.version?.versionId === matching.version?.versionId);
          this.$set(this.filteredMatchings, fMatchingIndex, {
            ...matching,
            totalPrice: price,
            priceHouseOnly: priceWithoutAdditionalFees - this.customerLand.price,
          });

          // Mise à jour dans matchings
          const matchingIndex = this.matchings.findIndex((m) => m.version?.versionId === matching.version?.versionId);
          this.$set(this.matchings, matchingIndex, {
            ...matching,
            totalPrice: price,
            priceHouseOnly: priceWithoutAdditionalFees - this.customerLand.price,
          });

          // On met à jour l objet des prix à jour
          this.pricedVersions[matching.version?.versionId] = true;
        });
        await Promise.all(chunkPromises);
      }

      this.isPriceLoaded = true;
    },
    // Récupère le prix exact d'une version
    async getPrice(versionId) {
      try {
        const { price, priceWithoutAdditionalFees } = await configurationApi.getPrice(
          this.$route.query.postalCodeInseeNumber,
          false,
          this.customerLand.width,
          this.customerLand.surface,
          this.customerLand.price,
          true,
          true,
          false,
          versionId,
          null,
          this.$route.query.packId,
        );
        return { price, priceWithoutAdditionalFees };
      } catch (er) {
        // On n'affiche pas le message d'erreur si on a un problème avec le prix
        // On l'enlève de la liste
        if (this.filteredMatchings && this.filteredMatchings.length) {
          const index = this.filteredMatchings.findIndex((matching) => matching && matching.version.versionId === versionId);
          this.filteredMatchings.splice(index, 1);
        }
        return {};
      }
    },
  },
};
</script>

<style lang="sass">
.mobile-matchings-list
  .list-header
    display: flex
    justify-content: space-between
    align-items: center
    padding: 0 5vw 5vw 5vw
    p
      color: $white
    .app-button.small
      padding: 12px
      .icon
        padding: 0

  > .app-spinner
    margin: 10vh auto

  .list-content
    display: flex
    flex-direction: column
    gap: 24px
    margin: 0 5vw
    margin-bottom: 5vw
</style>
