<template>
  <div class="mobile-matching-card">
    <div class="matching-img-container">
      <mobile-carousel :images="matching.version.versionMedias" />
    </div>
    <router-link class="matching-info-container" :to="{ name: 'mobile-configuration', query: { versionId: matching.version.versionId, packId: $route.query.packId, postalCodeInseeNumber: $route.query.postalCodeInseeNumber } }">
      <div>
        <div>
          <span>{{ matching.version.name }}</span>
          <br/>
          <strong>Maison {{ matching.version.numberOfRooms }} ch.</strong>
        </div>
        <div class="price">
          <template v-if="matching.totalPrice">{{ matching.totalPrice | displayPrice }}*</template>
        </div>
      </div>
      <p>
        Prix de la maison seule : <strong>{{ matching.priceHouseOnly | displayPrice }}</strong><br/>
        * Prix du projet total incluant un estimatif terrain
      </p>
    </router-link>
  </div>
</template>

<script>
import MobileCarousel from '@/components/general/MobileCarousel.vue';

export default {
  name: 'mobile-matching-card',
  components: {
    MobileCarousel,
  },
  props: {
    matching: {
      type: Object,
      default: () => {},
    },
  },
  filters: {
    displayPrice(price) {
      return price ? new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(Math.round(price / 100)) : '  - € ';
    },
  },
};
</script>

<style lang="sass">
.mobile-matching-card
  width: 100%
  border-radius: 8px
  overflow: hidden
  .matching-img-container
    background: $white
  .matching-info-container
    padding: 16px 20px
    background: $white
    text-decoration: none
    display: block
    > div
      display: flex
      justify-content: space-between
      align-items: center
      background: $white
      > div:first-child
        span
          @include label
          font-weight: 700
        strong
          color: $black
      .price
        padding: 4px 10px
        background: $warning
        @include body-bold
        color: $white
        border-radius: 24px
        flex-shrink: 0
        > .app-spinner
          padding: 4px 10px
          span
            border-color: $white
    > p
      width: 100%
      font-size: 12px
      font-weight: 400
      line-height: 18px
      margin-top: 6px
      strong, b
        color: $warning
        font-size: 12px
        line-height: 12px
      b
        color: $body-color

</style>
