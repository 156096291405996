<template>
  <form @submit.prevent="submitCity" :class="['app-search-city', size]">
    <label v-if="label" for="input-search-city">{{ label }}</label>
    <div class="search-input">
      <div class="position-icon">
        <position />
      </div>
      <input
        ref="search"
        type="text"
        class="input-search-city"
        v-model="search"
        :required="required"
        :placeholder="placeholder"
        :disabled="disabled"
        @input="updateSearch"
        autocomplete="no"
        id="input-search-city"
      />
      <div class="cancel-btn" :class="{ 'large-cancel': noSubmitBtn }">
        <button @click.prevent="clearSearch" v-if="search && search.length">
          <cancel />
        </button>
      </div>
      <div class="submit-btn" v-if="!noSubmitBtn">
        <button type="submit">
          <arrowRight />
        </button>
      </div>
    </div>
    <div class="submit-btn-mobile" v-if="!noSubmitBtn">
      <button type="submit">
        <arrowRight />
      </button>
    </div>
    <transition name="app-search-city-list">
      <ul v-show="isOpen">
        <app-scrollable v-show="postalCodeOptions.length > 0">
          <li
            role="option"
            v-for="option in postalCodeOptions"
            :key="option.name"
            @click="selectOption($event, option)"
            :class="{ 'clickable' : postalCodes.find((postalCode) => postalCode.postalCodeInseeNumber === option.name && postalCode.hasAgencies) }"
          >
            <span>{{ option.label }}</span>
          </li>
        </app-scrollable>
        <app-scrollable v-show="!postalCodeOptions || !postalCodeOptions.length">
          <li>
            <span>Nous ne sommes pas présent dans cette commune</span>
          </li>
        </app-scrollable>
      </ul>
    </transition>
  </form>
</template>

<script>
import position from '@/assets/img/position.svg?inline';
import arrowRight from '@/assets/img/arrow-right.svg?inline';
import cancel from '@/assets/img/cancel.svg?inline';
import postalCodeApi from '@/services/api/postalCode';
import utils from '@/services/utils/utils';

export default {
  name: 'app-search-city',
  components: {
    position,
    cancel,
    arrowRight,
  },
  props: {
    placeholder: {
      type: String,
      default: 'Rechercher...',
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selectedPostalCode: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      validator: (val) => ['large', 'auto'].indexOf(val) !== -1,
      default: 'auto',
    },
    noSubmitBtn: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isOpen: false,
      search: null,
      postalCodes: [],
      postalCodeOptions: [],
    };
  },

  watch: {
    search(value) {
      if (value === '') {
        this.clearSearch();
      }
    },
    selectedPostalCode(value) {
      this.search = value;
      if (this.$props.noSubmitBtn) {
        this.submitCity();
      }
    },
  },

  created() {
    this.debouncedUpdateSearch = utils.debounce(this.getPostalCodes, 500);
  },

  mounted() {
    if (this.selectedPostalCode) {
      this.search = this.selectedPostalCode;
    }
  },

  computed: {
    input() {
      return this.value;
    },
  },

  methods: {
    async getPostalCodes() {
      try {
        // On vérifie si le champ est remplie, si non, on vide les anciens résultats
        if (this.search && this.search.length >= 3) {
          this.postalCodes = await postalCodeApi.getAll(this.search);
          this.postalCodeOptions = utils.formatOptions(
            this.postalCodes,
            (option) => option.postalCodeInseeNumber,
            (option) => `${option.city} (${option.postalCodeInseeNumber.split('-')[0]})${option.secondaryCityName ? `, anciennement ${option.secondaryCityName.join(', ')}` : ''}${!option.hasAgencies ? ' - Cette commune n\'est pas dans notre zone de construction' : ''}`,
          );
        } else if (!this.search || this.search.length === 0) {
          this.postalCodes = [];
          this.postalCodeOptions = [];
        }

        // On ouvre la liste des propositions si il y a des codes postaux de trouvés
        this.isOpen = this.search && this.search.length >= 3;
      } catch (error) {
        throw error;
      }
    },

    updateSearch() {
      this.debouncedUpdateSearch();
    },

    // Ouvre ou ferme la liste des options
    showOptions() {
      if (!this.disabled) {
        this.isOpen = !this.isOpen;
      }
    },

    // Vide le champ
    clearSearch() {
      this.selected = null;
      this.search = null;
      this.isOpen = false;
      this.$emit('clear');
    },

    // Sélectionne le code postal choisi
    selectOption(event, option) {
      const match = this.postalCodes.find((postalCode) => postalCode.postalCodeInseeNumber === option.name && postalCode.hasAgencies);
      if (match && event.button === 0) {
        this.selected = option;
        this.search = option.label;
        this.isOpen = false;
        this.$emit('select', this.selected);
      }
    },
    submitCity() {
      this.$emit('submit-city', this.input);
    },
  },
};
</script>

<style lang='sass' scoped>
.app-search-city
  background-color: transparent
  position: relative
  &.large
    @media screen and (min-width: 768px)
      .input-search-city
        @include body
  label
    padding-bottom: 4px
  .search-input
    display: flex
    flex-direction: row
    border-radius: 4px
  .input-search-city
    @include paragraph
    padding: 10px 2px 10px 2px
    margin: 0
    outline: 0
    display: flex
    border-block: 1px solid $line
    border-inline: 0
    width: 100%
    border-radius: 0
    &::placeholder
      color: $info
    &:focus-visible
      outline: 0
  .position-icon
    border: 1px solid $line
    border-right: 0
    border-radius: $global-border-radius 0 0 $global-border-radius
    display: flex
    flex-basis: 3rem
    align-items: center
    justify-content: center
    background-color: $off-white
    svg
      transform: scale(0.8)
  .large-cancel
    border-right: 1px solid $line
    border-radius: 0 $global-border-radius $global-border-radius 0
  .cancel-btn
    display: flex
    flex-basis: 3rem
    border-block: 1px solid $line
    background-color: $off-white
    @media (max-width: 768px)
      border-radius: 0 $global-border-radius $global-border-radius 0
    button
      display: flex
      align-items: center
      justify-content: center
      cursor: pointer
      width: 100%
      border: 0
      padding: 0
      margin: 0
      background-color: transparent
      svg
        width: 10px
        height: 10px
  .submit-btn-mobile
    display: none
    @media (max-width: 768px)
      display: flex
    button
      display: flex
      align-items: center
      justify-content: center
      cursor: pointer
      width: 100%
      padding: 12px 0
      margin-top: 16px
      border: 0
      border-radius: $global-border-radius
      background-color: $primary
      transition: all 0.3s ease-in-out
      &:hover
        background-color: $primary-light
        transition: all 0.3s ease-in-out
      &:active
        background-color: $primary-dark
        transition: all 0.3s ease-in-out
  .submit-btn
    display: flex
    flex-basis: 5rem
    @media (max-width: 768px)
      display: none
    button
      display: flex
      align-items: center
      justify-content: center
      cursor: pointer
      width: 100%
      padding: 0
      margin: 0
      border: 0
      border-radius: 0 4px 4px 0
      background-color: $primary
      transition: all 0.3s ease-in-out
      &:hover
        background-color: $primary-light
        transition: all 0.3s ease-in-out
      &:active
        background-color: $primary-dark
        transition: all 0.3s ease-in-out
  .app-search-city-list-enter-active,
  .app-search-city-list-leave-active
    transition: transform .3s, opacity .3s, scale .3s ease-in-out
  .app-search-city-list-enter,
  .app-search-city-list-leave-to
    opacity: 0
  ul
    position: absolute
    top: 100%
    @media (max-width: 768px)
      top: 40%
    left: 0
    margin-top: 4px
    padding: unset
    display: flex
    flex-direction: column
    background-color: $off-white
    color: $label
    box-shadow: $drop-med
    width: 100%
    max-height: 148px
    border-radius: 4px
    z-index: 999
    overflow: hidden
  li
    padding: 0.5rem 1rem
    overflow: hidden
    span
      @include paragraph
      font-weight: 300
  li
    &.clickable
      cursor: pointer
      &:hover
        background-color: $loading
    &:not(.clickable)
      background-color: $loading
</style>
