import axios from 'axios';

const { VUE_APP_API_URL, VUE_APP_BRAND_ID } = process.env;

/**
 * Récupère la liste des catégories
 * @param {string} brandId: id de la marque
 */
const getAll = async () => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/public/brands/${VUE_APP_BRAND_ID}/categories/`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const category = {};
category.getAll = getAll;

export default category;
