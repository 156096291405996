<template>
  <div class="app-radio" :class="{ 'disabled': disabled }">
      <label>
        <input
          type="radio"
          :name="name"
          :checked="input === value"
          :disabled="disabled"
          @change="onChange()"
          @click="$emit('click')"
        />
        <span class="checkmark"></span>
        <slot></slot>
      </label>
  </div>
</template>

<script>

export default {
  model: {
    prop: 'input',
    event: 'input',
  },
  props: {
    input: {
      type: [String, Array, Number, Boolean],
    },
    value: {
      type: [String, Boolean],
    },
    name: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onChange() {
      this.$emit('input', this.value);
    },
  },
};
</script>

<style lang="sass" scoped>
.app-radio
  display: inline-flex
  min-height: 16px
  label
    @include paragraph
    color: $body
    position: relative
    user-select: none
    cursor: pointer
    padding-left: 24px
    display: flex
    align-items: center

    input[type="radio"]
      position: absolute
      top: 0
      left: 0
      height: 0
      width: 0
      display: none

      &:active ~ .checkmark
        @include drop-color($blue-button)
        border-color: $blue-button

        &::after
          opacity: 1

      &:checked ~ .checkmark
        border-color: $blue-button

        &::after
          opacity: 1

      &:focus ~ .checkmark
        @include drop-color($blue-button)

    .checkmark
      position: absolute
      top: 0
      left: 0
      height: 16px
      width: 16px
      border: 1px solid $line
      background-color: $off-white
      border-radius: 50%
      transition: 0.3s ease-in-out

      &::after
        content: ""
        position: absolute
        background-color: $blue-button
        opacity: 0
        top: 3px
        left: 3px
        width: 10px
        height: 10px
        border-radius: 50%
        transition: 0.15s ease-in-out

.disabled
  label
    color: $info
    cursor: not-allowed
    pointer-events: none

  .checkmark
    filter: grayscale(30%)
    background-color: $background !important

</style>
