import { render, staticRenderFns } from "./AppSwitch.vue?vue&type=template&id=430f4008&scoped=true&"
import script from "./AppSwitch.vue?vue&type=script&lang=js&"
export * from "./AppSwitch.vue?vue&type=script&lang=js&"
import style0 from "./AppSwitch.vue?vue&type=style&index=0&id=430f4008&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "430f4008",
  null
  
)

export default component.exports