import axios from 'axios';

const { VUE_APP_API_URL, VUE_APP_BRAND_ID } = process.env;

/**
 * Récupère tous les lots
 * @param {string?} categoryId: catégorie à rechercher
 * @param {string} brandId: id de la marque
 */
const getAll = async (categoryId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/public/brands/${VUE_APP_BRAND_ID}/lots`, {
      params: {
        ...(categoryId && { categoryId }),
      },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const lot = {};
lot.getAll = getAll;

export default lot;
