<template>
  <button class="card-search-bar">
    <slot></slot>
    <div class="content">
      <span>{{ subtitle }}</span>
      <p>{{ title }}</p>
    </div>
  </button>
</template>

<script>
export default {
  name: 'card-search-bar',
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
  },
};
</script>

<style lang="sass" scoped>
.card-search-bar
  background-color: $white
  display: flex
  flex-direction: column
  align-items: center
  box-shadow: $drop-shadow
  text-decoration: none
  border-radius: $global-border-radius
  padding: 32px
  border: 2px solid $white
  transition: all 0.3s ease-out
  cursor: pointer
  width: 100%
  height: 100%
  &:hover
    border: 2px solid $primary
    transition: all 0.3s ease-out

  svg
    margin: 0 0 0 8px
    height: 70px

  img
    width: 100%

  .content
    text-align: center

    span
      @include cta

    p
      @include body-bold
      color: $subtitle

  @media (max-width: 1024px)
    flex-direction: row
    padding: 16px
    div
      text-align: left
    .content, .content p, .content span
      text-align: left
    svg
      height: 40px
      margin: 0 16px 0 0
</style>
