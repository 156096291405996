/* eslint-disable import/first */
import Vue from 'vue';

import Router from 'vue-router';

import customerApi from '@/services/api/customer';
import authApi from '@/services/auth';

// Communes

const City = () => import('@/views/cities/City.vue');

// Agences
const Agency = () => import('@/views/agencies/Agency.vue');
const Agencies = () => import('@/views/agencies/Agencies.vue');

// Home
import Home from '@/views/Home.vue';

// Terrain
const SearchLand = () => import('@/views/land/SearchLand.vue');
const OwnLand = () => import('@/views/land/OwnLand.vue');
const LimitHisLand = () => import('@/views/land/LimitHisLand.vue');
const FlatOrSlopingGround = () => import('@/views/land/FlatOrSlopingGround.vue');
const HouseInAllotment = () => import('@/views/land/HouseInAllotment.vue');

// Projet
const RentalInvestment = () => import('@/views/project/RentalInvestment.vue');
const HouseBudget = () => import('@/views/project/HouseBudget.vue');
const HouseFunding = () => import('@/views/project/HouseFunding.vue');
const HouseConstruction = () => import('@/views/project/HouseConstruction.vue');
const BecomeOwner = () => import('@/views/project/BecomeOwner.vue');
const BuildToRent = () => import('@/views/project/BuildToRent.vue');
const BrickOrCinderBlock = () => import('@/views/project/BrickOrCinderBlock');
const HouseSteps = () => import('@/views/project/HouseSteps.vue');
const InterestFreeLoan = () => import('@/views/project/InterestFreeLoan.vue');
const BuildToRentFurnished = () => import('@/views/project/BuildToRentFurnished.vue');
const BuildIn2020 = () => import('@/views/project/BuildIn2020.vue');
const FirstTimeBuyer = () => import('@/views/project/FirstTimeBuyer.vue');

// Départements
const Department = () => import('@/views/departments/Department.vue');

// Maison
const HousePlan = () => import('@/views/house/HousePlan.vue');
const OurHouseModels = () => import('@/views/house/OurHouseModels.vue');
const PositiveHouse = () => import('@/views/house/PositiveHouse.vue');
const ModernHouse = () => import('@/views/house/ModernHouse.vue');
const ContemporaryHouse = () => import('@/views/house/ContemporaryHouse.vue');
const GroundFloorHouse = () => import('@/views/house/GroundFloorHouse.vue');
const Floor = () => import('@/views/house/Floor.vue');
const LowCostHouse = () => import('@/views/house/LowCostHouse.vue');
const NewRealEstate = () => import('@/views/house/NewRealEstate.vue');
const ConstructionMethod = () => import('@/views/house/ConstructionMethod.vue');
const SingleStoreyHouses = () => import('@/views/house/SingleStoreyHouses.vue');
const PriceSquareMeter = () => import('@/views/house/PriceSquareMeter.vue');

// Catégories
const SanitairesEtChauffage = () => import('@/views/house/Categories/SanitairesEtChauffage.vue');
const GrosOeuvre = () => import('@/views/house/Categories/GrosOeuvre.vue');
const Electricite = () => import('@/views/house/Categories/Electricite.vue');
const ReglesGeographiques = () => import('@/views/house/Categories/ReglesGeographiques.vue');
const IsolationEtPlaquisterie = () => import('@/views/house/Categories/IsolationEtPlaquisterie.vue');
const AutresAmenagements = () => import('@/views/house/Categories/AutresAmenagements.vue');
const Menuiseries = () => import('@/views/house/Categories/Menuiseries.vue');
const Divers = () => import('@/views/house/Categories/Divers.vue');
const SolsEtRevetements = () => import('@/views/house/Categories/SolsEtRevetements.vue');
const Toiture = () => import('@/views/house/Categories/Toiture.vue');

// Configuration
import MobileMatchings from '@/views/configuration/mobile/MobileMatchings.vue';
import MobileConfiguration from '@/views/configuration/mobile/MobileConfiguration.vue';
import Matchings from '@/views/configuration/Matchings.vue';
import Configuration from '@/views/configuration/Configuration.vue';
import ConfigurationPdf from '@/views/configuration/ConfigurationPdf.vue';

// Constructeur
const Network = () => import('@/views/constructor/Network.vue');

// Constructeur de maisons
const Securities = () => import('@/views/house-builder/Securities.vue');

// Constructeur maisons
const ConstructionContract = () => import('@/views/house-builder/ConstructionContract.vue');

// constructeur
const WhoWeAre = () => import('@/views/constructor/WhoWeAre.vue');

// Mon Compte
const Account = () => import('@/views/account/Account.vue');
const Login = () => import('@/views/account/Login.vue');
const Register = () => import('@/views/account/Register.vue');
const LostPassword = () => import('@/views/account/LostPassword.vue');
const ResetPassword = () => import('@/views/account/ResetPassword.vue');
const RegisterFinalization = () => import('@/views/account/RegisterFinalization.vue');

// Outils
const Configurator = () => import('@/views/tools/Configurator.vue');
const CalculatorBudget = () => import('@/views/tools/CalculatorBudget.vue');

// Sitemap
const Sitemap = () => import('@/views/sitemap/Sitemap.vue');

// Landings
const SocoocPartnership = () => import('@/views/landings/SocoocPartnership.vue');

// Routes
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        title: 'Constructeur maison individuelle - Conseil & Expertise | Alysia',
        description: 'Maisons Alysia : la référence de la construction de maisons individuelles de qualité à des prix compétitifs.',
        keywords: 'maison(s) alysia, construction maison, alysia',
        isDark: true,
      },
    },
    // Agences
    {
      path: '/agences/loire-atlantique/:slugName',
      name: 'loire-atlantique-page-agence',
      component: Agency,
      meta: {
        isDark: true,
      },
    },
    {
      path: '/agences/:slugDepartment',
      name: 'departement-agences',
      component: Agencies,
      meta: {
        isDark: true,
      },
    },
    {
      path: '/agences/charente-maritime/:slugName',
      name: 'charente-maritime-page-agence',
      component: Agency,
      meta: {
        isDark: true,
      },
    },
    {
      path: '/agences/finistere/:slugName',
      name: 'finistere-page-agence',
      component: Agency,
      meta: {
        isDark: true,
      },
    },
    {
      path: '/agences/gironde/:slugName',
      name: 'gironde-page-agence',
      component: Agency,
      meta: {
        isDark: true,
      },
    },
    {
      path: '/agences/maine-et-loire/:slugName',
      name: 'maine-et-loire-page-agence',
      component: Agency,
      meta: {
        isDark: true,
      },
    },
    {
      path: '/agences/morbihan/:slugName',
      name: 'morbihan-page-agence',
      component: Agency,
      meta: {
        isDark: true,
      },
    },
    {
      path: '/agences/vendee/:slugName',
      name: 'vendee-page-agence',
      component: Agency,
      meta: {
        isDark: true,
      },
    },
    {
      path: '/agences/vienne/:slugName',
      name: 'vienne-page-agence',
      component: Agency,
      meta: {
        isDark: true,
      },
    },
    {
      path: '/agences/calvados/:slugName',
      name: 'calvados-page-agence',
      component: Agency,
      meta: {
        isDark: true,
      },
    },
    {
      path: '/agences/cotes-darmor/:slugName',
      name: 'cotes-darmor-page-agence',
      component: Agency,
      meta: {
        isDark: true,
      },
    },
    {
      path: '/agences/eure/:slugName',
      name: 'eure-page-agence',
      component: Agency,
      meta: {
        isDark: true,
      },
    },
    {
      path: '/agences/eure-et-loir/:slugName',
      name: 'eure-et-loir-page-agence',
      component: Agency,
      meta: {
        isDark: true,
      },
    },
    {
      path: '/agences/ille-et-vilaine/:slugName',
      name: 'ille-et-vilaine-page-agence',
      component: Agency,
      meta: {
        isDark: true,
      },
    },
    {
      path: '/agences/indre-et-loire/:slugName',
      name: 'indre-et-loire-page-agence',
      component: Agency,
      meta: {
        isDark: true,
      },
    },
    {
      path: '/agences/seine-maritime/:slugName',
      name: 'seine-maritime-page-agence',
      component: Agency,
      meta: {
        isDark: true,
      },
    },
    {
      path: '/agences/deux-sevres/:slugName',
      name: 'deux-sevres-page-agence',
      component: Agency,
      meta: {
        isDark: true,
      },
    },
    {
      path: '/agences/sarthe/:slugName',
      name: 'sarthe-page-agence',
      component: Agency,
      meta: {
        isDark: true,
      },
    },

    // Le configurateur

    // mobile
    {
      path: '/mobile/matchings',
      name: 'mobile-matchings',
      component: MobileMatchings,
      meta: {
        noScroll: true,
        hideShare: true,
      },
    },
    {
      path: '/mobile/configuration',
      name: 'mobile-configuration',
      component: MobileConfiguration,
      meta: {
        noScroll: true,
        hideShare: true,
      },
    },

    // desktop
    {
      path: '/matchings',
      name: 'matchings',
      component: Matchings,
      meta: {
        noScroll: true,
        hideShare: true,
      },
    },
    {
      path: '/matchings/modification',
      name: 'matchings-edit',
      component: Matchings,
      meta: {
        noScroll: true,
        hideShare: true,
      },
    },
    {
      path: '/projet/:projectDraftId/modification',
      name: 'project-projectId-matchings-edit',
      component: Matchings,
      meta: {
        noScroll: true,
        hideShare: true,
      },
    },
    {
      path: '/configuration/:tab',
      name: 'configuration',
      component: Configuration,
      meta: {
        noScroll: true,
        hideShare: true,
      },
      beforeEnter: (to, from, next) => {
        const tabs = ['version', 'surfaces', 'categories', 'options', 'pack', 'land', 'fees', 'extensions', 'login', 'register', 'save', 'finalize', 'forgotten-password'];
        next();
        // Check des tabs possibles pour cette route
        if (tabs.includes(to.params.tab)) {
          next();
        } else {
          next({
            name: 'configuration',
            params: {
              ...to.params,
              tab: tabs[0],
            },
            query: {
              ...to.query,
            },
          });
        }
      },
    },
    {
      path: '/projet/:projectDraftId/tab/:tab',
      name: 'project-projectId',
      component: Configuration,
      meta: {
        noScroll: true,
        hideShare: true,
      },
      beforeEnter: (to, from, next) => {
        const tabs = ['version', 'surfaces', 'categories', 'options', 'pack', 'land', 'fees', 'extensions', 'login', 'register', 'save', 'finalize', 'calculator'];
        next();
        // Check des tabs possibles pour cette route
        if (tabs.includes(to.params.tab)) {
          next();
        } else {
          next({
            name: 'project-projectId',
            params: {
              ...to.params,
              tab: tabs[0],
            },
            query: {
              ...to.query,
            },
          });
        }
      },
    },

    {
      path: '/projets/:projectDraftId/pdf',
      name: 'project-projectId-pdf',
      component: ConfigurationPdf,
      meta: {
        // noScroll: true,
        print: true,
        loginRequired: true,
        hideShare: true,
      },
    },

    // Projet
    {
      path: '/projet-construction-maison/investissement-locatif',
      name: 'project-house-rental-invest',
      component: RentalInvestment,
      meta: {
        title: 'Réalisez le meilleur investissement locatif avcec une maison individuelle | Alysia',
        description: 'L’investissement locatif avec votre conseiller Maisons Alysia : l’assurance d’une excellente rentabilité',
        keywords: 'investissement locatif, investissement immobilier',
        isDark: true,
      },
    },
    {
      path: '/projet-construction-maison/budget',
      name: 'project-house-budget',
      component: HouseBudget,
      meta: {
        title: 'Quel budget et quelles solutions pour faire construire sa maison | Alysia',
        description: 'Que prévoir pour la construction de sa maison, comment identifier son budget et savoir au mieux le financer. Abordons tout cela sans rien oublier…',
        isDark: true,
      },
    },
    {
      path: '/projet-construction-maison/financement',
      name: 'project-house-funding',
      component: HouseFunding,
      meta: {
        title: 'Suivez les conseils d’un expert pour financer votre maison | Alysia',
        description: 'Suivez le guide pour trouver une solution adaptée au financement de la construction de votre future maison',
        keywords: 'conseils, financement, maison, construction',
        isDark: true,
      },
    },
    {
      path: '/projet-construction-maison/ou-faire-construire-sa-maison',
      name: 'project-house-construction',
      component: HouseConstruction,
      meta: {
        title: 'Où faire construire avec Maisons Alysia ?',
        description: 'Consulter des centaines de terrains par département, y associer la maison de son choix, obtenir le prix du projet et sa future mensualité en ligne, c’est ici !',
        keywords: 'conseils, financement, maison, construction',
        isDark: true,
      },
    },
    {
      path: '/projet-construction-maison/etapes',
      name: 'project-house-steps',
      component: HouseSteps,
      meta: {
        title: 'Bénéficiez d\'un accompagnement personnalisé sur toutes les étapes du projet | Alysia',
        description: 'Démarrez simplement et rapidement votre projet à l\'aide du configurateur en ligne Maisons Alysia',
        keywords: '',
        isDark: true,
      },
    },
    {
      path: '/projet-construction-maison/devenir-proprietaire',
      name: 'project-become-owner',
      component: BecomeOwner,
      meta: {
        title: 'Les avantages à devenir propriétaire de sa maison individuelle neuve',
        description: 'Devenir propriétaire de sa maison est le rêve n°1 chez la majeur partie des français. Nous vous révélons comment y accéder',
        keywords: '',
        isDark: true,
      },
    },
    {
      path: '/projet-construction-maison/faire-construire-une-maison-pour-la-louer-quels-avantages',
      name: 'build-to-rent',
      component: BuildToRent,
      meta: {
        title: 'Faire construire une maison pour la louer : quels avantages ?',
        description: 'Découvrez tous nos conseils et astuces pour réaliser l’investissement locatif gagnant',
        keywords: 'investissement locatif, investissement immobilier',
        isDark: true,
      },
    },
    {
      path: '/projet-construction-maison/construction-de-maison-pret-a-taux-zero',
      name: 'interest-free-loan',
      component: InterestFreeLoan,
      meta: {
        title: 'Financer la construction de sa maison avec le prêt à taux zéro | Alysia',
        description: 'Le PTZ vise à faciliter l’acquisition d’un premier logement notamment pour les ménages à revenus modestes. Voilà comment en bénéficier',
        keywords: 'Taux zéro, construction maison, ménages à revenus modestes',
        isDark: true,
      },
    },
    {
      path: '/projet-construction-maison/construire-pour-louer-en-meuble',
      name: 'build-to-rent-furnished',
      component: BuildToRentFurnished,
      meta: {
        title: 'Louer en meublé son investissement immobilier | Alysia',
        description: 'La solution du meublé pour son investissement locatif permet d’optimiser sa rentabilité',
        keywords: 'louer meublé, investissement locatif, rentabilité',
        isDark: true,
      },
    },
    {
      path: '/projet-construction-maison/aides-pour-faire-construire-sa-maison',
      name: 'build-in-2020',
      component: BuildIn2020,
      meta: {
        title: 'Toutes les aides financières pour faire construire une maison | Alysia',
        description: 'De nombreuses aides existent pour vous aider à financer votre futur nid douillet et vous ne les connaissez sûrement pas toutes…',
        keywords: 'maison en 2020, aides pour faire constuire',
        isDark: true,
      },
    },
    {
      path: '/projet-construction-maison/les-avantages-du-primo-accedant',
      name: 'first-time-buyer',
      component: FirstTimeBuyer,
      meta: {
        title: 'Tous les avantages à faire construire quand pour un primo-accédant | Alysia',
        description: 'Etre primo-accédant en France est un statut qui donne droit à nombreux avantages financiers répondant au rêve de propriété des jeunes ménages',
        keywords: 'avantages primo-accédant',
        isDark: true,
      },
    },
    // Constructeur
    {
      path: '/constructeur-maisons/reseau',
      name: 'constructor-network',
      component: Network,
      meta: {
        title: 'Votre réseau d\'agences constructeur de maisons | Alysia',
        description: 'Faites confiance à un bâtisseur conseiller et garant du bon déroulement de votre projet de A à Z pour vous faciliter l\'accès à la propriété !',
        isDark: true,
      },
    },

    // Constructeur de maisons
    {
      path: '/constructeur-maisons/garanties',
      name: 'securities',
      component: Securities,
      meta: {
        title: 'Nos garanties et engagements | Alysia',
        description: 'Un interlocuteur unique et un service tout compris, les clés d’une gestion de projet réussie',
        keywords: 'garanties, engagements',
        isDark: true,
      },
    },

    // Constructeur de maison
    {
      path: '/constructeur-maisons/avantages-CCMI',
      name: 'construction-contract',
      component: ConstructionContract,
      meta: {
        title: 'Découvrez tous les avantages du contrat de construction CCMI | Alysia',
        description: 'Faire construire en mode zen … Bénéficiez du CCMI garanti par l’Etat pour protéger l’acquéreur en lui apportant toutes les garanties et assurances.',
        keywords: 'contrat de construction, CCMI, garanti d’État',
        isDark: true,
      },
    },

    // Constructeur
    {
      path: '/constructeur-maisons/equipe',
      name: 'who-we-are',
      component: WhoWeAre,
      meta: {
        title: 'Des experts dédiés à votre projet de construction | Alysia',
        description: 'Découvrez tous les services et engagements de Maisons Alysia envers les futurs propriétaires !',
        keywords: 'constructeur maison individuelle, construire sa maison, faire construire sa maison',
        isDark: true,
      },
    },

    // Departements
    {
      path: '/constructeur-maisons/:dpt',
      name: 'department',
      component: Department,
      meta: {
        isDark: true,
      },
    },

    // Communes
    {
      path: '/constructeur-maisons/:dpt/:cityName',
      name: 'city',
      component: City,
      meta: {
        isDark: true,
      },
    },

    // Terrain
    {
      path: '/terrains/je-possede-un-terrain-a-batir',
      name: 'land-own-land',
      component: OwnLand,
      meta: {
        title: 'Je possède déjà un terrain à bâtir | Alysia',
        description: 'Je possède déjà un terrain à bâtir, puis-je utiliser le configurateur Alysia ?',
        keywords: 'terrain a batir',
        isDark: true,
      },
    },
    {
      path: '/terrains/je-recherche-un-terrain-constructible',
      name: 'land-search-land',
      component: SearchLand,
      meta: {
        title: 'Trouver un terrain à vendre pour bâtir la maison de vos rêves | Alysia',
        description: 'Localisez les terrains constructibles dans les départements, villes et communes de votre choix',
        keywords: 'terrain a vendre, terrain constructible, terrain à vendre',
        isDark: true,
      },
    },
    {
      path: '/terrains/construire-sa-maison-sur-un-terrain-plat-ou-en-pente',
      name: 'flat-or-sloping-ground',
      component: FlatOrSlopingGround,
      meta: {
        title: 'Comment faire construire sur un terrain en pente | Alysia',
        description: 'Votre projet de construction doit s’adapter aux caractéristiques d’un terrain difficile ou en pente ? C’est possible mais sous certaines conditions…',
        keywords: 'construire sa maison, terrain plat, terrain pente',
        isDark: false,
      },
    },
    {
      path: '/terrains/pourquoi-faire-construire-sa-maison-en-lotissement',
      name: 'house-in-allotment',
      component: HouseInAllotment,
      meta: {
        title: 'Tous les avantages à faire construire sa maison en lotissement | Alysia',
        description: 'Plus simple et plus sécurisant que le secteur diffus, les procédures sont simplifiées dans un lotissement et les frais de bornage réduits d’autant…',
        keywords: 'construire dans un lotissement',
        isDark: false,
      },
    },
    {
      path: '/terrains/borner-son-terrain-une-securite-juridique',
      name: 'limit-his-land',
      component: LimitHisLand,
      meta: {
        title: 'Comment faire pour borner un terrain démarche et budget | Alysia',
        description: 'Faire borner son terrain, c’est délimiter sa propriété et être sûr de ses droits. Seul professionnel officiellement habilité, le géomètre-expert vous garantit ce service',
        keywords: 'borner son terrain, délimiter sa propriété',
        isDark: false,
      },
    },

    // Maison
    {
      path: '/maisons/plan-maison-intelligent',
      name: 'smart-house-plan',
      component: HousePlan,
      meta: {
        title: 'Projetez-vous grâce à nos plans de maison intelligents | Alysia',
        description: 'Choisissez parmi nos plans de maisons intelligents pour vous aider à vous projeter',
        keywords: 'plan de maison',
        isDark: true,
      },
    },
    {
      path: '/maisons/nos-modeles',
      name: 'our-house-models',
      component: OurHouseModels,
      meta: {
        title: 'Découvrez notre gamme de Maisons Résidentielles | Alysia',
        description: 'Profitez de prestations haut de gamme avec des matériaux et équipements de qualité, à un niveau de prix ultra-compétitif pour des mensualités réduites',
        keywords: 'modèles de maison',
        isDark: true,
      },
    },
    {
      path: '/maisons/la-RE2020-est-operationnelle-depuis-2021',
      name: 'positive-house',
      component: PositiveHouse,
      meta: {
        title: 'Tout savoir sur la réglementation environnementale RE2020 | Alysia',
        description: 'Tout comprendre sur la nouvelle réglementation environnementale RE 2020 : apports en confort, économies d’énergie… Mais qu’en est-il des prix ?',
        keywords: 'maison positive',
        isDark: true,
      },
    },
    {
      path: '/maisons/moderne',
      name: 'modern-house',
      component: ModernHouse,
      meta: {
        title: 'Investissez dans une Maison Moderne | Alysia',
        description: 'Configurez votre projet de maison moderne sur-mesure, adapté à la superficie de votre terrain',
        keywords: 'maison moderne',
        isDark: true,
      },
    },
    {
      path: '/maisons/contemporaine',
      name: 'contemporary-house',
      component: ContemporaryHouse,
      meta: {
        title: 'Découvrez notre gamme de Maisons Contemporaines | Alysia',
        description: 'Vous rêvez d’une maison dans l’ère du temps ? Découvrez notre gamme de maison contemporaine !',
        keywords: 'maison contemporaine',
        isDark: true,
      },
    },
    {
      path: '/maisons/plain-pied',
      name: 'ground-floor-house',
      component: GroundFloorHouse,
      meta: {
        title: 'Choisissez votre plan de Maison Plain-pied | Alysia',
        description: 'Comparez les avantages et inconvénients de regrouper les pièces de vie et les chambres sur un seul niveau',
        keywords: 'maison plain-pied',
        isDark: true,
      },
    },
    {
      path: '/maisons/etage',
      name: 'floor',
      component: Floor,
      meta: {
        title: 'Découvrez nos modèles de Maison à Etage | Alysia',
        description: 'Vous hésitez entre un modèle maison de plain-pied et à étages ? Maisons Alysia vous aide à faire le meilleur choix',
        keywords: 'maison à étage',
        isDark: true,
      },
    },
    {
      path: '/maisons/pas-cher',
      name: 'low-cost-house',
      component: LowCostHouse,
      meta: {
        title: 'Réalisez des économies avec une maison pas cher | Alysia',
        description: 'Concrétisez votre rêve de devenir propriétaire en construisant votre maison au meilleur prix, sans rogner sur la qualité des prestations',
        keywords: 'maison pas cher, qualité, prestations',
        isDark: true,
      },
    },
    {
      path: '/maisons/programme-immobilier-neuf',
      name: 'new-real-estate',
      component: NewRealEstate,
      meta: {
        title: 'Optez pour un programme Immobilier Neuf | Alysia',
        description: 'Vous souhaitez investir dans le neuf ? Suivez les conseils d’un professionnel pour l’acquisition de votre futur bien immobilier',
        keywords: 'immobilier neuf',
        isDark: true,
      },
    },
    {
      path: '/maisons/brique-ou-parpaing-pour-la-construction',
      name: 'brick-or-cinder-block',
      component: BrickOrCinderBlock,
      meta: {
        title: 'Brique ou parpaing pour la construction : un débat dépassé ?',
        description: 'Découvrez tous nos conseils et astuces pour réaliser l’investissement locatif gagnant',
        keywords: 'investissement locatif, investissement immobilier',
        isDark: true,
      },
    },
    {
      path: '/maisons/mode-constructif',
      name: 'construction-method',
      component: ConstructionMethod,
      meta: {
        title: 'Tout sur la performance de nos techniques constructives | Alysia',
        description: 'Bien préparer son projet de construction de maison nécessite de se pencher sur les nouvelles techniques proposées régulièrement sur la marché',
        isDark: true,
      },
    },
    // Catégories
    {
      path: '/maisons/mode-constructif/sanitaires-et-chauffage',
      name: 'category-sanitaires-et-chauffage',
      component: SanitairesEtChauffage,
      meta: {
        title: 'Découvrez toutes les caractéristiques de notre mode constructif | Alysia',
        description: 'Découvrez l’ensemble des catégories qui constituent le mode constructif et les équipements d’un constructeur offrant le meilleur rapport qualité/prix du marché',
        isDark: true,
      },
    },
    {
      path: '/maisons/mode-constructif/gros-oeuvre',
      name: 'category-gros-oeuvre',
      component: GrosOeuvre,
      meta: {
        title: 'Découvrez toutes les caractéristiques de notre mode constructif | Alysia',
        description: 'Découvrez l’ensemble des catégories qui constituent le mode constructif et les équipements d’un constructeur offrant le meilleur rapport qualité/prix du marché',
        isDark: true,
      },
    },
    {
      path: '/maisons/mode-constructif/sols-et-revetements',
      name: 'category-sols-et-revetements',
      component: SolsEtRevetements,
      meta: {
        title: 'Découvrez toutes les caractéristiques de notre mode constructif | Alysia',
        description: 'Découvrez l’ensemble des catégories qui constituent le mode constructif et les équipements d’un constructeur offrant le meilleur rapport qualité/prix du marché',
        isDark: true,
      },
    },
    {
      path: '/maisons/mode-constructif/toiture',
      name: 'category-toiture',
      component: Toiture,
      meta: {
        title: 'Découvrez toutes les caractéristiques de notre mode constructif | Alysia',
        description: 'Découvrez l’ensemble des catégories qui constituent le mode constructif et les équipements d’un constructeur offrant le meilleur rapport qualité/prix du marché',
        isDark: true,
      },
    },
    {
      path: '/maisons/mode-constructif/divers',
      name: 'category-divers',
      component: Divers,
      meta: {
        title: 'Découvrez toutes les caractéristiques de notre mode constructif | Alysia',
        description: 'Découvrez l’ensemble des catégories qui constituent le mode constructif et les équipements d’un constructeur offrant le meilleur rapport qualité/prix du marché',
        isDark: true,
      },
    },
    {
      path: '/maisons/mode-constructif/menuiseries',
      name: 'category-menuiseries',
      component: Menuiseries,
      meta: {
        title: 'Découvrez toutes les caractéristiques de notre mode constructif | Alysia',
        description: 'Découvrez l’ensemble des catégories qui constituent le mode constructif et les équipements d’un constructeur offrant le meilleur rapport qualité/prix du marché',
        isDark: true,
      },
    },
    {
      path: '/maisons/mode-constructif/autres-amenagements',
      name: 'category-autres-amenagements',
      component: AutresAmenagements,
      meta: {
        title: 'Découvrez toutes les caractéristiques de notre mode constructif | Alysia',
        description: 'Découvrez l’ensemble des catégories qui constituent le mode constructif et les équipements d’un constructeur offrant le meilleur rapport qualité/prix du marché',
        isDark: true,
      },
    },
    {
      path: '/maisons/mode-constructif/isolation-et-plaquisterie',
      name: 'category-isolation-et-plaquisterie',
      component: IsolationEtPlaquisterie,
      meta: {
        title: 'Découvrez toutes les caractéristiques de notre mode constructif | Alysia',
        description: 'Découvrez l’ensemble des catégories qui constituent le mode constructif et les équipements d’un constructeur offrant le meilleur rapport qualité/prix du marché',
        isDark: true,
      },
    },
    {
      path: '/maisons/mode-constructif/regles-geographiques',
      name: 'category-regles-geographiques',
      component: ReglesGeographiques,
      meta: {
        title: 'Découvrez toutes les caractéristiques de notre mode constructif | Alysia',
        description: 'Découvrez l’ensemble des catégories qui constituent le mode constructif et les équipements d’un constructeur offrant le meilleur rapport qualité/prix du marché',
        isDark: true,
      },
    },
    {
      path: '/maisons/mode-constructif/electricite',
      name: 'category-electricite',
      component: Electricite,
      meta: {
        title: 'Découvrez toutes les caractéristiques de notre mode constructif | Alysia',
        description: 'Découvrez l’ensemble des catégories qui constituent le mode constructif et les équipements d’un constructeur offrant le meilleur rapport qualité/prix du marché',
        isDark: true,
      },
    },
    {
      path: '/maisons/prix-construction-maison-m2',
      name: 'price-square-meter',
      component: PriceSquareMeter,
      meta: {
        title: 'Quel est le prix au m² d’une maison neuve ? | Alysia',
        description: 'Quel est le prix moyen pour la construction d’une maison ? Quel constructeur est en mesure de proposer le prix le plus bas sans renoncer à la qualité ?',
        isDark: true,
      },
    },

    {
      path: '/maisons/immobilier-pourquoi-les-maisons-de-plain-pied-ont-la-cote',
      name: 'single-storey-houses',
      component: SingleStoreyHouses,
      meta: {
        title: 'Immobilier : pourquoi les maisons de plain-pied ont la cote | Alysia',
        description: 'Découvrez tous nos modèles de maisons de plain-pied : confort, accessibilité, regroupant surfaces de jour et surfaces de nuit sur un même plan…',
        keywords: 'maison plain pied',
        isDark: true,
      },
    },

    // Outils
    {
      path: '/projet-construction-maison/configurateur',
      name: 'configurator',
      component: Configurator,
      meta: {
        title: 'Configurez votre projet de maison en ligne | Alysia',
        decriptions: 'Simple, rapide et intuitif, découvrez le nouveau configurateur pour calculer le prix exact de votre projet',
        keywords: '',
        isDark: true,
      },
    },

    // Mon Compte
    {
      path: '/mon-compte/:tab',
      name: 'account',
      component: Account,
      meta: {
        isDark: true,
        loginRequired: true,
        hideShare: true,
      },
      beforeEnter: (to, from, next) => {
        const tabs = ['parametres', 'mes-projets', 'mot-de-passe', 'recherche'];
        // Check des tabs possibles pour cette route
        if (tabs.includes(to.params.tab)) {
          next();
        } else {
          next({
            params: {
              ...to.params,
              tab: tabs[1],
            },
            query: {
              ...to.query,
            },
          });
        }
      },
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        hideShare: true,
      },
      beforeEnter: async (to, from, next) => {
        try {
          const isLoggedIn = authApi.isLoggedIn();
          if (isLoggedIn) {
            const me = await customerApi.getMe();
            if (me && me.customerId) {
              next({ name: 'account', params: { tab: 'mes-projets' } });
            }
          }
          next();
        } catch (er) {
          next();
        }
      },
    },
    {
      path: '/inscription',
      name: 'register',
      component: Register,
      meta: {
        hideShare: true,
      },
    },
    {
      path: '/inscription/finalisation',
      name: 'register-finalization',
      component: RegisterFinalization,
      meta: {
        hideShare: true,
      },
    },
    {
      path: '/mot-de-passe-perdu',
      name: 'lost-password',
      component: LostPassword,
      meta: {
        hideShare: true,
      },
    },
    {
      path: '/mot-de-passe-perdu/modification',
      name: 'reset-password',
      component: ResetPassword,
      meta: {
        hideShare: true,
      },
    },
    {
      path: '/creation-mot-de-passe',
      name: 'create-password',
      component: ResetPassword,
      meta: {
        hideShare: true,
      },
    },
    // Outils
    {
      path: '/outils/calculette-simulation-credit',
      name: 'calculator-budget',
      component: CalculatorBudget,
    },
    {
      path: '/outils/calculette-simulation-credit-locataire',
      name: 'calculator-budget-tenant',
      component: CalculatorBudget,
    },
    // Sitemap
    {
      path: '/sitemap/:tab',
      name: 'sitemap',
      component: Sitemap,
      beforeEnter: (to, from, next) => {
        const tabs = ['maisons-alysia', 'your-project', 'your-home', 'your-land', 'your-builder', 'our-tools', 'our-agencies', 'where-to-build', 'articles'];
        next();
        // Check des tabs possibles pour cette route
        if (tabs.includes(to.params.tab)) {
          next();
        } else {
          next({
            name: 'sitemap',
            params: {
              ...to.params,
              tab: tabs[0],
            },
          });
        }
      },
    },
    {
      path: '/cuisine-offerte',
      name: 'socooc-partnership',
      component: SocoocPartnership,
    },
    { // Il faut que ce soit la dernière route !
      path: '*',
      redirect: '/',
      name: '404',
      component: Home,
    },
  ],
  scrollBehavior(to) {
    if (to.hash) {
      const anchor = document.getElementById(to.hash.slice(1));
      if (anchor) {
        // Le scroll de vue vers l'ancre ne fonctionne pas, donc on le fait "manuellement"
        anchor.scrollIntoView();
      }
      return {
        selector: to.hash,
      };
    }
    return { x: 0, y: 0 };
  },
});
/*
GENERATE SITEMAP.XML FROM VUE ROUTER

getRoutesList : récupères les routes du router et les stocks dans un tableau.
getRoutesXML : met les routes dans une string en XML.
saveSitemap : récupères le fichier XML au lancement du site vitrine.

Les fonctions sont en commentaire car pour récupérer le sitemap.xml, il est
nécessaire de lancer le site vitrine et d'accéder au site sur le navigateur,
à ce moment là le fichier sera téléchargé. C'est à utiliser une seule fois
pour générer le sitemap.

Il est nécessaire de revérifier à la main la cohérence des routes exportées
depuis Vue Router, par exemple il faut retirer les routes contenant des
variables en paramètres.

Il n'y a pas de méthodes pour attribuer automatiquement un indice de priorité,
de fréquence de changement et de date de dernière modification aux routes, donc
ces paramètres doivent être fixés à la main.

function getRoutesList(routes, pre) {
  return routes.reduce((array, route) => {
    const path = `${pre}${route.path}`;

    // Exclude routes with params
    // const regex = new RegExp('\\/:(.*)', 'gm');

    if (route.path !== '*') {
      array.push(path);
    }

    if (route.children) {
      array.push(...getRoutesList(route.children, `${path}/`));
    }

    return array;
  }, []);
}

function getRoutesXML() {
  const list = getRoutesList(router.options.routes, 'https://www.maisons-alysia.com')
    .map((route) => `<url><loc>${route}</loc><lastmod>${new Date().toISOString().substring(0, 10)}</lastmod><changefreq>monthly</changefreq><priority>0.8</priority></url>`)
    .join('\r\n');
  return `<?xml version="1.0" encoding="UTF-8"?><urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">${list}</urlset>`;
}

function saveSitemap() {
  const data = JSON.stringify(getRoutesXML());
  const blob = new Blob([data], { type: 'text/xml' });
  const a = document.createElement('a');
  a.download = 'sitemap.xml';
  a.href = window.URL.createObjectURL(blob);
  a.dataset.downloadurl = ['text/xml', a.download, a.href].join(':');
  const evt = new MouseEvent('click', { bubbles: true, cancelable: true, view: window });
  a.dispatchEvent(evt);
}

saveSitemap();
*/
router.beforeEach((to, from, next) => {
  if (document.querySelector('link[rel="canonical"]')) {
    document.querySelector('link[rel="canonical"]').setAttribute('href', `https://www.maisons-alysia.com${to.path}`);
    next();
  } else {
    const link = document.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', `https://www.maisons-alysia.com${to.path}`);
    document.head.appendChild(link);
    next();
  }
});

router.afterEach((to) => {
  if (document.querySelector('.page-container')) {
    document.querySelector('.page-container').scrollTo(0, 0);
  }

  Vue.nextTick(() => {
    if (to.meta && to.meta.title && to.meta.description && to.meta.keywords) {
      document.title = to.meta.title;
      document.querySelector('meta[name="description"]').setAttribute('content', to.meta.description);
      document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.keywords);
    }
  });
});

Vue.use(Router);

export default router;
