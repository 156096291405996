<template>
  <section class="app-card-option">
    <slot name="content"/>
  </section>
</template>

<script>
export default {
  name: 'app-card-option',
};
</script>

<style lang="sass" scoped>
.app-card-option
  padding: 25px
  background-color: $background
  border-left: 4px solid $primary
</style>
