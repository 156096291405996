<template>
  <div class="app-double-slider" :class="{ firefox }">
    <span class="label">{{ label }}</span>
    <div class="container">
      <div class="sliders" ref="slider">
        <input
          ref="start"
          class="start"
          type="range"
          :min="min"
          :max="max"
          :step="step"
          :value="value.start"
          @input="startChange"
        />
        <input
          ref="end"
          class="end"
          type="range"
          :min="min"
          :max="max"
          :step="step"
          :value="value.end"
          :class="{ 'index': index }"
          @input="endChange"
        />
      </div>
    </div>
    <div class="container">
      <div class="value-start">
        <div class="name">
          <p>min.</p>
        </div>
        <div class="value">
          <p>{{ value.start }}{{ unit ? ` ${unit}` : '' }}</p>
        </div>
      </div>
      <div class="sliders"></div>
      <div class="value-end">
        <div class="name">
          <p>max.</p>
        </div>
        <div class="value">
          <p>{{ value.end }}{{ unit ? ` ${unit}` : '' }}</p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'app-double-slider',
  data() {
    return {
      index: false,
      firefox: navigator.userAgent.includes('Firefox'),
    };
  },
  props: {
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    step: {
      type: Number,
    },
    value: {
      type: Object,
      default: () => ({
        start: 0,
        end: 100,
      }),
    },
    label: {
      type: String,
    },
    unit: {
      type: String,
    },
  },
  methods: {
    startChange(event) {
      if (event.target.value <= this.value.end - 20) {
        this.$emit('input', {
          end: this.value.end,
          start: parseFloat(event.target.value),
        });
      } else {
        this.$emit('input', {
          end: this.value.end,
          start: this.value.end - 20,
        });
      }
    },
    endChange(event) {
      if (event.target.value >= this.value.start + 20) {
        this.$emit('input', {
          end: parseFloat(event.target.value),
          start: this.value.start,
        });
      } else {
        this.$emit('input', {
          end: this.value.start + 20,
          start: this.value.start,
        });
      }
    },
  },
};
</script>

<style lang="sass" scoped>
@function shadow($color, $size)
  $val: 5px 0 0 $size $color
  @for $i from 6 through 300
    $val: #{$val}, #{$i}px 0 0 $size #{$color}
  @return $val

@function revertshadow($color, $size)
  $val: 5px 0 0 $size $color
  @for $i from 6 through 300
    $val: #{$val}, -#{$i}px 0 0 $size #{$color}
  @return $val

.app-double-slider
  display: flex
  flex-direction: column
  border-radius: 4px
  padding: 8px
  width: 100%
  .label
    @include label
    color: $body-color
    padding-bottom: 4px
  p
    @include infos
    color: $secondary-light
    margin-bottom: 8px
  .container
    display: flex
    flex-direction: row
    align-items: center

  .value-end,
  .value-start
    @include label
    color: $body
    width: 80px
    text-align: center
    .name p
      margin-bottom: 0
    .value p
      color: $primary
      font-weight: 600

  .sliders
    display: block
    position: relative
    width: 100%
    height: 20px

    input[type="range"]
      pointer-events: none
      position: absolute
      start: 0
      top: 0
      overflow: hidden
      margin: 0
      height: 100%
      width: 100%
      cursor: pointer
      appearance: none
      outline: none
      background: none

      // les 2 curseurs du slider
      &::-webkit-slider-thumb
        appearance: none
        pointer-events: all
        width: 16px
        height: 16px
        border: 1px solid $primary
        border-radius: 50%
        background-color: $off-white
        cursor: pointer
        margin-top: -6px

      &::-webkit-slider-runnable-track
        appearance: none
        border-radius: 4px

  .start
    z-index: 3
    // fond du slider
    &::-webkit-slider-thumb
      box-shadow: revertshadow($line, -6px)

    // partie colorée, sélectionnée
    &::-webkit-slider-runnable-track
      background-color: $primary
      height: 4px

    &:focus::-webkit-slider-thumb
      box-shadow: revertshadow($line, -6px), 0 0 1px 2px rgba($primary, 0.2)

  .end
    z-index: 4
    &::-webkit-slider-thumb
      box-shadow: shadow($line, -6px)

    &::-webkit-slider-runnable-track
      height: 4px

    &:focus::-webkit-slider-thumb
      box-shadow: shadow($line, -6px), 0 0 1px 2px rgba($primary, 0.2)

  // Pour faire marcher sur Firefox
  &.firefox
    .sliders
      input[type="range"]
        // les 2 curseurs du slider
        &::-moz-range-thumb
          appearance: none
          pointer-events: all
          width: 16px
          height: 16px
          border: 1px solid $primary
          border-radius: 50%
          background-color: $off-white
          cursor: pointer
          margin-top: -6px
        &::-moz-range-track
          appearance: none
          border-radius: 4px
      .start
        // fond du slider
        &::-moz-range-thumb
          box-shadow: revertshadow($line, -6px)
        // partie colorée, sélectionnée
        &::-moz-range-track
          background-color: $primary
          height: 4px
        &:focus::-moz-range-thumb
          box-shadow: revertshadow($line, -6px), 0 0 1px 2px rgba($primary, 0.2)
      .end
        &::-moz-range-thumb
          box-shadow: shadow($line, -6px)
        &::-moz-range-thumb
          height: 4px
        &:focus::-moz-range-thumb
          box-shadow: shadow($line, -6px), 0 0 1px 2px rgba($primary, 0.2)

</style>
