<template>
  <div class="app-search" :class="size">
    <span v-if="label" class="label">{{ label }}</span>
    <label>
      <position class="search" />
      <div class="search-input">
        <input
          ref="search"
          type="text"
          :value="value"
          :autocomplete="autocomplete"
          :placeholder="placeholder"
          :disabled="disabled"
          @focus="showOptions"
          @blur="blur"
          @input="$emit('input', $event.target.value)"
          :required="required"
        />
        <app-button hasArrow @click="$emit('search', input)"></app-button>
      </div>
    </label>
    <ul v-show="isOpen && options.length > 0">
      <app-scrollable>
        <li v-for="(option, key) in options" :key="key" @mousedown="selectOption($event, option)">
          <span>{{ option }}</span>
        </li>
      </app-scrollable>
    </ul>
  </div>
</template>

<script>
import position from '@/assets/img/position.svg?inline';

export default {
  name: 'app-search',
  components: {
    position,
  },
  data() {
    return {
      isOpen: false,
      selected: null,
    };
  },
  props: {
    value: String,
    options: {
      type: Array,
      default: () => [],
    },
    autocomplete: {
      default: 'off',
    },
    placeholder: {
      type: String,
      default: 'Text...',
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      validator: (val) => ['large', 'small', 'medium'].indexOf(val) !== -1,
      default: 'large',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    showOptions() {
      if (!this.disabled) {
        this.isOpen = !this.isOpen;
      }
    },
    blur() {
      this.showOptions();
      this.$emit('blur');
    },
    selectOption(event, option) {
      if (event.button === 0) {
        this.selected = option;
        this.$emit('input', this.selected);
      }
    },
  },
  computed: {
    input() {
      return this.value;
    },
  },
};
</script>

<style lang='sass'>
.app-search
  display: flex
  flex-direction: column
  position: relative
  &.small
    width: 50% !important
    min-width: 240px !important
  &.medium
    width: 100%
    max-width: 480px
  &.large
    width: 100%
    .search
      transform: translateY(-50%) scale(1)
    .search-input
      input
        width: 100%
        padding: 16px 24px 16px 48px
        font-size: $font-xs
      button
        height: 52px
        width: 80px
  label
    display: flex
    flex-direction: column
    position: relative
    width: 100%
  .label
    @include label
    color: $label
    padding-bottom: 4px
  .search
    position: absolute
    left: 10px
    top: 50%
    transform: translateY(-50%) scale(0.75)
  .search-input
    display: flex
    align-items: center
    input
      @include paragraph
      display: flex
      padding: 10px 16px 10px 42px
      background-color: $off-white
      border: 1px solid $line
      border-radius: 4px 0 0 4px
      border-right: none
      color: $body
      &:focus
        outline: none
      &:disabled
        background-color: $background
        color: $info
        pointer-events: none
        cursor: not-allowed
      &::placeholder
        color: $info
    .app-button
      height: 40px
      border-radius: 0 4px 4px 0
      span
        padding-left: 0 !important
  ul
    position: absolute
    top: 100%
    left: 0
    margin-top: 4px
    padding: unset
    display: flex
    flex-direction: column
    background-color: $off-white
    color: $label
    box-shadow: $drop-med
    width: 100%
    max-height: 148px
    border-radius: 4px
    z-index: 999
  li
    cursor: pointer
    padding: 0.5rem 1rem
    &:hover
      background-color: rgba($primary, 0.1)
    span
      @include paragraph
</style>
