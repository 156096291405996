import axios from 'axios';

const { VUE_APP_API_URL, VUE_APP_BRAND_ID } = process.env;

/**
 * Récupère une marque
 */
const get = async () => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/public/brands/${VUE_APP_BRAND_ID}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const brand = {};
brand.get = get;
export default brand;
