<template>
  <section class="app-pagination">
    <div class="grid-x align-middle align-right grid-margin-x pagination">
      <div class="cell shrink button-pagination button-pagination-left" @click="() => pageChange(1)" :class="{disabled: isFirstPage}">
        <arrow class="arrow" />
        <arrow class="arrow" />
      </div>
      <div class="cell shrink button-pagination button-pagination-left" @click="() => pageChange(parseInt($route.query.page, 10) - 1)" :class="{disabled: isFirstPage}">
        <arrow class="arrow" />
      </div>
      <template v-for="page in pagesDisplayed">
        <div
          :key="`pagination-${page}-${selectedPage}`"
          class="page-number cell auto shrink" :class="page === selectedPage ? 'active' : ''"
          @click="() => pageChange(page)"
        >
          <h5>{{ page }}</h5>
        </div>
      </template>
      <div class="cell shrink next button-pagination" :class="{disabled: isLastPage}" @click="() => pageChange(parseInt($route.query.page, 10) + 1)">
        <arrow class="arrow" />
      </div>
      <div class="cell shrink last button-pagination" :class="{disabled: isLastPage}" @click="() => pageChange(nbPages)">
        <arrow class="arrow" />
        <arrow class="arrow" />
      </div>
    </div>
  </section>
</template>

<script>
import arrow from '@/assets/img/nav/arrow.svg?inline';

export default {
  name: 'pagination',

  components: {
    arrow,
  },

  props: {
    limit: {
      type: Number,
      default: 10,
    },
    offset: {
      type: Number,
      default: 0,
    },
    count: [Number],
  },

  data() {
    return {
      pagesDisplayed: null,
      pageMaxShown: 3,
      selectedPage: 1,
    };
  },

  watch: {
    offset() {
      this.setPagesDisplayed();
      this.selectedPage = parseInt(this.$route.query.page, 10);
    },
    count() {
      this.setPagesDisplayed();
      this.selectedPage = parseInt(this.$route.query.page, 10);
    },
    limit() {
      this.setPagesDisplayed();
      this.selectedPage = parseInt(this.$route.query.page, 10);
    },
  },

  created() {
    if (!this.$route.query.page || this.$route.query.page > this.nbPages || this.$route.query.page <= 0) {
      this.$router.push({
        query: {
          ...this.$route.query,
          page: 1,
        },
      });
    }
    this.selectedPage = parseInt(this.$route.query.page, 10);
    this.setPagesDisplayed();
  },

  computed: {
    // Calcule le nombre de page max
    nbPages() {
      return Math.max(1, Math.ceil(this.count / this.limit));
    },

    // Retourne true si c'est la 1ère page du tableau
    isFirstPage() {
      return (this.offset === 0);
    },

    // Retourne true si c'est la dernière page du tableau
    isLastPage() {
      return ((this.offset + this.limit + 1) > this.count);
    },
  },

  methods: {
    // Au changement de la page
    pageChange(page) {
      if (page !== this.selectedPage && page > 0 && page <= this.nbPages) {
        this.selectedPage = page;
        this.setPagesDisplayed();
        this.$router.push({
          query: {
            ...this.$route.query,
            page,
          },
        });
      }
    },

    setPagesDisplayed() {
      // Si la pagination ne va pas au dessus du nombre max visible
      if (this.nbPages <= this.pageMaxShown) {
        this.pagesDisplayed = this.getRange(1, this.nbPages);
        // S'il faut décaler les nombres pour voir les pages supérieurs
      } else if (this.selectedPage > Math.floor((this.pageMaxShown / 2) + 1)) {
        // Si on a accès aux pages supérieurs
        if (this.selectedPage + Math.floor(this.pageMaxShown / 2) <= this.nbPages) {
          this.pagesDisplayed = this.getRange(
            this.selectedPage - (Math.floor(this.pageMaxShown / 2)),
            this.selectedPage + Math.floor(this.pageMaxShown / 2),
          );
        } else {
          // Si on est arrivé à la dernière page
          this.pagesDisplayed = this.getRange(this.nbPages - this.pageMaxShown + 1, this.nbPages);
        }
      } else {
        this.pagesDisplayed = this.getRange(1, this.pageMaxShown);
      }
    },

    getRange(from, to) {
      const range = Array.from(Array(to).keys(), (x) => x + 1);
      if (from <= 1) {
        return range;
      }
      range.splice(0, from - 1);
      return range;
    },
  },
};
</script>

<style lang="sass">
.app-pagination
  margin-top: 2.5rem

  .page-number
    color: $info
    font-weight: 700
    cursor: pointer

  .page-number.active
    color: $primary

  .button-pagination
    display: flex
    align-items: center
    justify-content: center
    width: 26px
    height: 26px
    cursor: pointer
    border-radius: $global-border-radius
    background-color: $primary
    transition: background-color .1s

    .arrow
      height: 8px
      margin-left: 1px

    &:hover
      background-color: $primary-dark

    &.disabled
      cursor: initial
      background-color: $line

  .button-pagination-left
    transform: rotate(-180deg)
</style>
