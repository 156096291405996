<template>
  <div class="top-nav" :class="{dark: isDark}">
    <div class="container grid-x" :class="{ 'dark': isDark }">
      <router-link  :to="{ name: 'home' }">
        <logo-black v-if="!isDark"/>
        <logo-white v-else />
      </router-link>
      <router-link :to="{ name: 'our-house-models' }" class="mobile-none">
        Nos modèles de maisons
      </router-link>
      <router-link :to="{ name: 'constructor-network' }" class="mobile-none">
        Nos agences
      </router-link>
      <router-link :to="{ name: 'who-we-are' }" class="mobile-none">
        Qui sommes-nous ?
      </router-link>
      <router-link class="my-account" :to="{ name: 'login' }">
        <account />
        <span class="mobile-none">Mon compte</span>
      </router-link>
      <div class="burger">
        <button
          @click="isOpen = true"
          :tabindex="1000"
          >
          <burger :class="{ 'dark': isDark }" />
        </button>
      </div>
    </div>
    <movember class="more-logo" />
    <transition name="fade">
      <div class="menu" v-if="isOpen">
        <div class="left-list">
          <logo-bird class="logo"/>
          <nav :class="[selectedNavSection, {home: !selectedNavSection}]">
            <div class="nav-section nav-projet" :class="{ display: selectedNavSection === 'projet'}">
              <h1 class="nav-section-title" @click="selectedNavSection = 'projet'">Votre projet</h1>
              <ul class="nav-section-list">
                <li class="nav-subsection">
                  <router-link :to="{ name: 'project-become-owner' }">Devenir propriétaire</router-link>
                </li>
                <li class="nav-subsection">
                  <router-link :to="{ name: 'project-house-construction' }">Où faire construire sa maison ?</router-link>
                </li>
                <li class="nav-subsection">
                  <router-link :to="{ name: 'project-house-steps' }">Étapes de votre Projet</router-link>
                </li>
                <li class="nav-subsection">
                  <router-link :to="{ name: 'project-house-budget' }">Votre budget maison</router-link>
                </li>
                <li class="nav-subsection">
                  <router-link :to="{ name: 'project-house-funding' }">Solutions de financement</router-link>
                </li>
                <li class="nav-subsection">
                  <router-link :to="{ name: 'project-house-rental-invest' }">Investissement locatif</router-link>
                </li>
              </ul>
            </div>
            <div class="nav-section nav-maison" :class="{ display: selectedNavSection === 'maison'}">
              <h1 class="nav-section-title"  @click="selectedNavSection = 'maison'">Votre maison</h1>
              <ul class="nav-section-list">
                <li class="nav-subsection">
                  <router-link :to="{ name: 'our-house-models' }">Nos modèles de maisons</router-link>
                </li>
                <li class="nav-subsection">
                  <router-link :to="{ name: 'smart-house-plan' }">Nos plans de maisons intelligents</router-link>
                </li>
                <li class="nav-subsection">
                  <router-link :to="{ name: 'construction-method' }">Mode constructif</router-link>
                </li>
              </ul>
            </div>
            <div class="nav-section nav-terrain" :class="{ display: selectedNavSection === 'terrain'}">
              <h1 class="nav-section-title" @click="selectedNavSection = 'terrain'">Votre terrain</h1>
              <ul class="nav-section-list">
                <li class="nav-subsection">
                  <router-link :to="{ name: 'land-search-land' }">Je recherche un terrain</router-link>
                </li>
                <li class="nav-subsection">
                  <router-link :to="{ name: 'land-own-land' }">Je possède déjà un terrain</router-link>
                </li>
              </ul>
            </div>
            <div class="nav-section nav-constructeur" :class="{ display: selectedNavSection === 'constructeur'}">
              <h1 class="nav-section-title" @click="selectedNavSection = 'constructeur'">Votre constructeur</h1>
              <ul class="nav-section-list">
                <li class="nav-subsection">
                  <router-link :to="{ name: 'who-we-are' }">Qui sommes-nous ?</router-link>
                </li>
                <li class="nav-subsection">
                  <router-link :to="{ path: '/constructeur-maisons/reseau/#carte' }">Le réseau</router-link>
                </li>
                <li class="nav-subsection">
                  <router-link :to="{ name: 'securities' }">Les garanties</router-link>
                </li>
              </ul>
            </div>
            <div class="nav-section nav-outils" :class="{ display: selectedNavSection === 'outils'}">
              <h1 class="nav-section-title" @click="selectedNavSection = 'outils'">Nos outils</h1>
              <ul class="nav-section-list">
                <li class="nav-subsection">
                  <router-link :to="{ name: 'configurator' }">Configurateur</router-link>
                </li>
                <li class="nav-subsection">
                  <router-link :to="{ name: 'calculator-budget' }">Calculette budgétaire</router-link>
                </li>
              </ul>
            </div>
          </nav>
        </div>

        <div class="right-nav">
          <div class="blur"></div>
          <div class="content">
            <div class="center">
              <logo-bird class="logo"/>
              <p class="h6">Le plus court chemin entre vous et votre maison</p>
              <div class="buttons">
                <app-button :hasArrow="true" @click="$route.name !=='home' ? $router.push({ name: 'home', hash: '#hero' }) : scrollTo('hero')">Configurer</app-button>
                <app-button theme="secondary" @click="$route.name !=='constructor-network' ? $router.push({ name: 'constructor-network', hash: '#carte' }) : scrollTo('carte')">Nos agences</app-button>
              </div>
              <!-- <a class="cookies" href="javascript:openAxeptioCookies()">Préférences de cookies</a> -->
            </div>
          </div>
        </div>
        <div class="close-btn">
          <button @click="isOpen = false" :tabindex="1000">
            <close />
          </button>
        </div>

        <button
        v-if="selectedNavSection"
        @click="selectedNavSection = null"
        :tabindex="1000"
        class="back-btn"
        >
          <arrow-back />
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
import burger from '@/assets/img/nav/burger.svg?inline';
import close from '@/assets/img/nav/close.svg?inline';
import logoBlack from '@/assets/logos/logo-black.svg?inline';
import logoWhite from '@/assets/logos/logo-white.svg?inline';
import logoBird from '@/assets/logos/bird.svg?inline';
import movember from '@/assets/img/movember.svg?inline';
import arrowBack from '@/assets/img/arrow-back.svg?inline';
import account from '@/assets/img/nav/account.svg?inline';

export default {
  name: 'top-nav',
  components: {
    burger,
    close,
    'logo-black': logoBlack,
    'logo-white': logoWhite,
    'logo-bird': logoBird,
    'arrow-back': arrowBack,
    movember,
    account,
  },
  props: {
    isDark: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      isOpen: false,
      // Avoir un param query dans le router dans le menu pour chaque page serait plus propre
      selectedNavSection: null,
    };
  },
  mounted() {
  },
  watch: {
    $route() {
      this.isOpen = false;
    },
  },
  methods: {
    scrollTo(anchorName) {
      const anchor = document.getElementById(anchorName);
      if (anchor) {
        anchor.scrollIntoView();
        this.isOpen = false;
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.top-nav
  width: 100%
  background-color: white
  height: 70px
  position: sticky
  top: 0
  transition: background-color 1s
  z-index: 3
  box-shadow: $global-box-shadow
  .more-logo
    position: absolute
    width: 71px
    height: auto
    top: 24px
    left: 168px
    transform: scale(0.5)
    transform-origin: top left
    @media (max-width: 768px)
      left: 155px
  .container
    padding: 6px 40px
    display: flex
    gap: 44px
    align-items: center
    flex-wrap: nowrap
    height: 100%
    a
      color: $black
      text-decoration: none
      font-size: $font-xs
      line-height: 20px
      font-weight: 500
      span
        color: currentColor

      &:first-child
        flex-grow: 2

      &.my-account
        display: flex
        gap: 8px
        align-items: center
    @media (max-width: 768px)
      gap: 5%
      .mobile-none
        display: none

    .burger
      display: flex
      justify-content: center
      align-items: center
      button
        background-color: transparent
        border: none
        cursor: pointer
    .burger
      svg
        height: 25px
        path
          stroke: $black
    .my-account
      margin-right: 20px
      background-color: transparent
      svg
        height: 25px
        width: 27px
        path
          stroke: $black
    &.dark
      a
        color: $white
      svg:not(.logo) path
        stroke: $white
  .menu
    width: 100vw
    height: 100vh
    background: black
    position: fixed
    display: flex
    top: 0
    left: 0
    z-index: 8
    .left-list
      width: 60%
      max-width: 60%
      background: $black
      padding: 85px 0 40px 0
      display: flex
      flex-direction: column
      align-items: center
      overflow-y: auto
      justify-content: flex-start
      // Scrollbar pour Firefox
      scrollbar-color: $primary transparent
      scrollbar-width: thin
      scrollbar-radius: 0
      // Scrollbar pour Chrome
      &::-webkit-scrollbar
        width: 3px
        background-color: transparent
      &::-webkit-scrollbar-thumb
        background-color: $primary
      .logo
        display: none
      .nav-section
        display: flex
        &:not(:last-child)
          margin-bottom: 56px
        .nav-section-title
          width: 150px
          margin: 0
          color: $white
          font-weight: bold
          font-size: 18px
        .nav-section-list
          .nav-subsection
            a
              @include body
              text-decoration: none
              color: $line
            &:not(:last-child)
              margin-bottom: 16px
    .right-nav
      width: 40%
      min-width: 500px
      background-image: url(../../assets/img/pages/background-house.jpeg)
      background-size: cover
      background-position: center
      position: relative
      .blur
        position: absolute
        width: 100%
        height: 100%
        top: 0
        left: 0
        z-index: 1
        @include black-filter
      .content
        width: 100%
        height: 100%
        max-height: 100vh
        position: relative
        z-index: 2
        display: flex
        flex-direction: column
        align-items: center
        justify-content: space-between
        .center
          display: flex
          flex-direction: column
          align-items: center
          justify-content: space-between
          margin-top: 15vw
          .logo
            margin-bottom: 20px
          .h6
            color: white
            margin-bottom: 40px
          .buttons
            display: flex
            flex-direction: column
            align-items: center
            justify-content: space-between
            .app-button
              margin-bottom: 28px
    .close-btn
      position: absolute
      z-index: 9
      height: 30px
      top: 1.25rem
      width: 42px
      display: flex
      justify-content: center
      align-items: center
      right: 3.5rem
      > button
        background-color: transparent
        border: none
        cursor: pointer
    .back-btn
      position: absolute
      z-index: 9
      background-color: transparent
      border: none
      cursor: pointer
      top: 1.25rem
      left: 5%
      transform: translateY(40%)
      display: none
  &.dark
    background: transparent
    box-shadow: none
    border-bottom: none
  .cookies
    color: $white
// Responsive mobile
@media (max-width: 768px)
  .top-nav
    .container
      .img
        transform: scale(0.8)
    .menu
      flex-direction: column
      .left-list
        width: 100%
        max-width: 100%
        height: 75%
        padding: 55px 0
        // overflow-y: hidden
        .logo
          display: block
          margin: auto
          transform-origin: top
          transform: scale(0.7)
          margin-bottom: 15px
          margin-top: 0
          min-height: 80px
        nav
          .nav-section
            flex-direction: column
            align-items: center
            &:not(:last-child)
              margin-bottom: 0
            .nav-section-title
              display: none
              width: 100%
              font-size: 20px
              font-weight: 500
              line-height: 28px
              text-align: center
              margin-bottom: 48px
            .nav-section-list
              display: none
              text-align: center
              padding: 0
              .nav-subsection
                a
                  font-size: 14px
                  line-height: 24px
                  font-weight: 400
          &.home
            .nav-section
              .nav-section-title
                display: block
          .nav-section.display
            .nav-section-title
              display: block
            .nav-section-list
              display: block
      .right-nav
        width: 100%
        min-width: 100%
        background: $black
        height: 45%
        .blur
          display: none
        .content
          .center
            width: 100%
            .logo
              display: none
            .h6
              width: 90%
              margin: 0 auto
              text-align: center
            h6
              display: none
            .buttons
              flex-direction: row
              width: 90%
              margin: 1.5rem auto 0 auto
              .app-button
                width: 42%
      .back-btn
        display: block
@media (max-width: 768px)
  .top-nav
    .container
      padding: 6px 7vw
    .menu
      .left-list
        padding-bottom: 0
      .right-nav .content .center
        margin-top: 0
      .close-btn
        right: 7vw
@media (max-height: 667px)
  .top-nav .menu .left-list nav .nav-section .nav-section-title
    font-size: 18px
    margin-bottom: 24px
// Transitions
.fade-enter-active, .fade-leave-active
  transition: opacity .5s
.fade-enter, .fade-leave-to
  opacity: 0
</style>
