import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupère une liste d'agences
 * @param {string} brandId : id de la marque concernée
 * @param {string} search : paramètre de recherche, sur le nom de l'agence
 * @param {string} department : slug de l'agence
 */
const getAgencies = async (brandId, department, search) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/public/brands/${brandId}/agencies`, {
      params: { ...(search && { search }), ...(department && { department }) },
    });
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupère une agence
 * @param {string} slugName: id de l'agence à récupérer
 */
const getBySlugName = async (slugName) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/public/agencies/${slugName}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupère les membres d'une agence
 * @param {uuid} agencyId : id de l'agence concernée
 */
const getAgencyMembers = async (agencyId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/public/agencies/${agencyId}/members`);
    return response.data.data;
  } catch (er) {
    return er;
  }
};

/**
 * Récupère les photos d'une agence (intérieur, extérieur, plan)
 * @param {*} agencyId : id de l'agence concernée
 */
const getAgencyMedias = async (agencyId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/public/agencies/${agencyId}/medias`);
    return response.data.data;
  } catch (er) {
    return er;
  }
};

/**
 * Récupère la liste des agences
 * @param {string} search: paramètre de recherche, sur le nom de l'agence
 */
const getAll = async (search) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/agencies/`, {
      params: { ...(search && { search }) },
    });
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Création d'une agence
 * @param {agency} agency: objet de l'agence à créer
 */
const create = async (agency) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/agencies/`, agency);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Mise à jour d'une agence
 * @param {agency} agency: nouvel objet de l'agence
 * @param {string} agencyId: id de l'agence à récupérer
 */
const update = async (agencyId, agency) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/agencies/${agencyId}`, agency);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Suppression de l'agence
 * @param {string} agencyId: id de l'agence à récupérer
 */
const deleteOne = async (agencyId) => {
  try {
    const response = await axios.delete(`${VUE_APP_API_URL}/agencies/${agencyId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const sendContact = async (agencyId, body) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/public/agencies/${agencyId}/contact`, body);
    return response;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupère des agences par code postal
 * @param {string} postalCodeInseeNumber: postalCodeInseeNumber de l'agence
 */
const getByPostalCode = async (postalCodeInseeNumber) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/public/postal-codes/${postalCodeInseeNumber}/agency`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const agency = {};
agency.getAll = getAll;
agency.getAgencies = getAgencies;
agency.getBySlugName = getBySlugName;
agency.getAgencyMembers = getAgencyMembers;
agency.getAgencyMedias = getAgencyMedias;
agency.create = create;
agency.update = update;
agency.delete = deleteOne;
agency.sendContact = sendContact;
agency.getByPostalCode = getByPostalCode;

export default agency;
