<template>
<div :class="[size, color]" class="app-input">
  <label>
    <span v-if="label" class="label" :class="{ mandatory: required, disabled: disabled }">{{ label }}</span>
    <div class="grid-x">
      <input
        class="cell auto"
        :type="type"
        :value="value"
        :autocomplete="autocomplete"
        :placeholder="placeholder"
        :disabled="disabled"
        :class="[ hasSucceed ? hasSucceed : '', unit ? 'has-unit' : '' ]"
        :required="required"
        :step="step"
        :min="min"
        :max="max"
        :maxlength="maxlength"
        :minlength="minlength"
        @input="$emit('input', $event.target.value)"
        @blur="$emit('blur')"
        @focus="$emit('focus')"
      />
      <div v-if="unit" class="cell shrink unit" :class="{ disabled: disabled }">
        <p>{{ unit }}</p>
      </div>
    </div>
    <span class="msg" :class="hasSucceed">
      <warning v-if="hasSucceed === 'error'"/>
      {{ msg }}
    </span>
  </label>
</div>
</template>

<script>
import warning from '@/assets/img/warning.svg?inline';

export default {
  components: {
    warning,
  },
  props: {
    value: [String, Array, Number],
    type: {
      type: String,
      default: 'text',
      validator: (val) => ['text', 'number', 'email', 'password', 'phone'].indexOf(val) !== -1,
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
    placeholder: {
      type: String,
      default: 'Text...',
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    step: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
    },
    maxlength: {
      type: Number,
    },
    minlength: {
      type: Number,
    },
    size: {
      type: String,
      validator: (val) => ['large', 'small'].indexOf(val) !== -1,
      default: 'large',
    },
    hasSucceed: {
      type: String,
      default: null,
      validator: (val) => ['error', 'success'].indexOf(val) !== -1,
    },
    msg: {
      type: String,
    },
    unit: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: 'default',
      validator: (val) => ['default', 'dark'].indexOf(val) !== -1,
    },
  },
  computed: {
    input() {
      return this.value;
    },
  },
};
</script>

<style lang='sass' scoped>
.app-input
  display: flex
  flex-direction: column
  &.small
    width: 50% !important
    min-width: 240px !important
  &.large
    width: 100%
  label
    display: flex
    flex-direction: column
    width: 100%
    &.disabled
      color: $info
  .label
    @include label
    color: $body-color
    padding-bottom: 4px
    &.mandatory:after
      content: "*"
      display: inline-block
      transform: translateX(0.3rem) translateY(-0.15rem)
      color: currentcolor
    &.disabled
      color: $info
  input
    @include paragraph
    padding: 10px 16px
    background-color: $off-white
    border: 1px solid $line
    border-radius: 4px
    color: $body
    margin: 0
    &:focus
      outline: none
      border-bottom: 2px solid $primary
    &:disabled
      background-color: $background
      color: $label
      pointer-events: none
      cursor: not-allowed
      &::placeholder
        color: $label
    &::placeholder
      color: $info
    &.has-unit
      border-bottom-right-radius: 0
      border-top-right-radius: 0
  input.success
    box-shadow: 0 0 2px $success
    border: 1px solid $success !important
  .msg.success
    @include label
    margin-top: 4px
    color: $success
  input.error
    border: 1px solid $warning !important
  .msg.error
    @include label
    margin-top: 4px
    color: $warning
    display: flex
    align-items: center
    svg
      margin-right: 6px
  .unit
    @include centered-container
    background-color: $background
    border: 1px solid $line
    display: flex
    padding: 0 19px
    border-left-width: 0
    border-bottom-right-radius: $global-border-radius
    border-top-right-radius: $global-border-radius
    p
      color: $label
      @include label
  .unit.disabled
    p
      color: $body-color
  .custom-grid
    display: flex
    width: 100%
    input
      width: 100%
  &.dark
    label .label
      color: $white
    input
      background-color: $subtitle
      border-color: $subtitle
      color: $white
</style>
