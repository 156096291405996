<template>
  <section class="mobile-configuration-form">
    <div class="header" ref="top">
      <h2>Recevoir mon projet et bien plus encore !</h2>
    </div>
    <template v-if="isLoading">
      <p>Veuillez patienter pendant l'envoi.<br/>
        Cette opération peut prendre plusieurs minutes.</p>
      <app-spinner />
    </template>
    <form v-else id="lead-magnet-form" @submit.prevent="sendForm">
      <div class="form-card card">
        <app-input ref="emailInput" type="email" v-model="email" label="Votre email" required :disabled="isLoggedIn" placeholder="jean.dupont@mail.com" @focus.once="handleAutofocus"></app-input>
        <app-input v-model="firstname" label="Votre prénom" placeholder="Jean" required></app-input>
        <app-input v-model="lastname" label="Votre nom" placeholder="Dupont" required></app-input>
        <app-input v-model="phone" label="Numéro de téléphone" placeholder="0102030405" required></app-input>
        <app-checkbox>
          Recevoir le descriptif détaillé de votre projet par mail
        </app-checkbox>
        <app-checkbox>
          Recevoir ma future mensualité
        </app-checkbox>
        <div class="send" :class="{'error-rectangle': showError }">
          <app-checkbox value="CGV" v-model="CGVcheckbox">
            En cliquant sur le bouton ci-dessous, je reconnais avoir pris connaissance et accepter sans réserves les <a href="/docs/conditions-genenales-d-utilisation.pdf" target="blank">Conditions Générales d’Utilisation du site</a> et notre <a href="/docs/mentions-legales.pdf" target="blank">Politique de Confidentialité</a>.
          </app-checkbox>
          <p v-if="showError">Vous devez cocher les conditions générales pour recevoir votre projet</p>
          <app-button type="submit" form="lead-magnet-form">Recevez</app-button>
        </div>
      </div>
      <div class="description">
        <p>
          <strong>... Et en vous inscrivant, accédez au mode expert du configurateur Alysia</strong>
          <br />
          <ul>
            <li>+ de offres 3 000 terrains</li>
            <li>des centaines d’options de finitions et d’équipements</li>
            <li>vos futures mensualités personnalisées</li>
            <li>la sauvegarde de vos projets</li>
          </ul>
        </p>
      </div>
    </form>
    <app-button theme="secondary" class="back" @click="$emit('update:previousStep')"><arrowRight />Retour</app-button>
  </section>
</template>

<script>
import customerApi from '@/services/api/customer';
import authApi from '@/services/auth';
import agencyApi from '@/services/api/agency';
import utils from '@/services/utils/utils';
import arrowRight from '@/assets/img/arrow-right.svg?inline';

export default {
  name: 'mobile-configuration-form',
  components: {
    arrowRight,
  },
  props: {
    configuration: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      isLoggedIn: authApi.isLoggedIn(),
      email: null,
      CGVcheckbox: null,
      showError: false,
      lastname: null,
      firstname: null,
      phone: null,
      agency: null,
    };
  },
  watch: {
    isLoading(value) {
      this.$emit('update:isLoading', value);
    },
    CGVcheckbox: {
      handler(value) {
        this.showError = value[0] !== 'CGV';
      },
    },
  },
  mounted() {
    if (this.isLoggedIn) {
      this.getInfosFromAccount();
    }
    this.getAgency();
  },
  methods: {
    async getAgency() {
      this.agency = await agencyApi.getByPostalCode(this.$route.query.postalCodeInseeNumber);
    },
    // Enlève l'autofocus automatique du navigateur pour afficher le titre
    handleAutofocus() {
      const input = this.$refs.emailInput.$el.querySelector('input');
      input.blur();
      this.$el.parentElement.parentElement.scrollTo({ top: 0, behavior: 'smooth' });
    },
    async sendForm() {
      if (this.CGVcheckbox && this.CGVcheckbox[0] === 'CGV') {
        this.showError = false;
        this.isLoading = true;
        const customer = {
          email: this.email,
          lastname: this.lastname,
          firstname: this.firstname,
          phone: this.phone,
        };
        const configuration = {
          name: this.projectName ? this.projectName : 'Nouveau projet',
          versionId: this.configuration.version.versionId,
          customerLand: this.configuration.customerLand,
          packId: this.$route.query.packId,
          extensionsProperties: this.configuration.extensionsProperties,
          totalPrice: this.configuration.totalPrice,
          totalSurface: this.configuration.totalSurfaceHabitable,
          landPrice: this.configuration.customerLand.price,
          initialPrice: this.configuration.initialPrice,
          selectedOptions: this.configuration.options.filter((option) => option.type === 'ANNEX' && option.isActive),
          surfaces: this.configuration.surfaces,
        };
        try {
          await customerApi.createLeadMagnet(customer, configuration);
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'envoi_formulaire',
            device: 'mobile',
            type_config: 'config_mobile_express',
            agence: this.agency ? this.agency.name : 'inconnue',
            code_postal: this.$route.query.postalCodeInseeNumber.substring(0, 5),
            montant_projet: utils.formatCentToEuro(this.configuration.totalPrice),
          });
          this.$emit('update:currentStep');
        } catch (e) {
          this.$message.show({ title: 'Erreur', text: 'Une erreur est survenue durant l\'envoi de votre projet. Veuillez rééssayer ultérieurement' });
        }
        this.isLoading = false;
      } else {
        this.showError = true;
      }
    },
    async getInfosFromAccount() {
      try {
        const response = await customerApi.getMe();
        this.email = response.email;
        this.lastname = response.lastname;
        this.firstname = response.firstname;
        this.phone = response.phone;
      } catch (e) {
        this.isLoggedIn = false;
      }
    },
  },
};
</script>

<style lang="sass">
.mobile-configuration-form
  padding: 7vw 5vw 5vw 5vw
  .back
    width: 100%
    margin: 1rem 0
    border-radius: 8px !important
    svg
      transform: rotate(180deg)
      margin-right: 8px
      vertical-align: text-bottom
      position: relative
      bottom: 1px
  .header
    text-align: center
    h2
      @include heading-md-mobile
      color: $white
    svg
      width: 3rem
      path:first-child
        fill: none
  > p
    text-align: center
    color: $white
    margin: 5vw
  > .app-spinner
    margin: 5vw auto
  form
    display: flex
    flex-direction: column
    gap: 1rem
    margin-top: 24px

    .card.form-card
      display: flex
      flex-direction: column
      gap: 32px
      background: $white
      color: $body-color
      strong
        color: $subtitle
      .app-radio label
        color: $body-color
      .send
        display: flex
        flex-direction: column
        gap: 24px
        &.error-rectangle
          outline: 1px solid red
          outline-offset: 8px
        p
          color: red
          font-size: 12px
          line-height: 16px
          margin: 0
        button
          border-radius: 8px !important
    .description
      background: transparent
      color: $white
      margin: 8px
      p
        @include small-body
        color: $white
      ul
        padding: 0.5rem 0 0 1rem
        li
          list-style-image: url('~@/assets/img/checked-blue.svg')
          margin-bottom: 0.5rem
          padding-left: 0.5rem
</style>
