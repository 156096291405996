<template>
  <section class="mobile-configuration">
    <div class="content">
      <MobileConfigurationVersion v-if="currentStep === 1" @update:configuration="configuration = $event"/>
      <MobileConfigurationForm
        v-else-if="currentStep === 2"
        :configuration="configuration"
        @update:currentStep="currentStep += 1"
        @update:previousStep="currentStep -= 1"
        @update:isLoading="isSendingMail = $event"/>
      <div v-else class="success-message">
        <div class="card">
          <arrow-configurator />
          <h2>Le lien de votre projet a bien été envoyé !</h2>
          <p>Votre demande a bien été prise en compte, vous allez recevoir un mail avec votre notice pdf et un lien vers votre configuration.</p>
          <app-button role="link" @click="$router.push({ name: 'home' })">Retour à l'accueil</app-button>
        </div>
      </div>
    </div>

    <div class="cta-container" v-if="currentStep < 2">
      <app-button theme="secondary" hasArrow @click="goBack" :disabled="isSendingMail"><arrowRight />Retour</app-button>
      <app-button hasArrow v-if="currentStep === 1" @click="currentStep += 1">Valider</app-button>
    </div>
  </section>
</template>

<script>
import arrowRight from '@/assets/img/arrow-right.svg?inline';
import arrowConfigurator from '@/assets/img/pages/configurator/arrow.svg?inline';

import MobileConfigurationVersion from './MobileConfigurationVersion.vue';
import MobileConfigurationForm from './MobileConfigurationForm.vue';

export default {
  name: 'mobile-configuration',
  components: {
    MobileConfigurationVersion,
    MobileConfigurationForm,
    arrowRight,
    arrowConfigurator,
  },
  data() {
    return {
      currentStep: 1,
      configuration: {},
      isSendingMail: false,
    };
  },
  methods: {
    goBack() {
      if (this.currentStep === 1) {
        this.$router.go(-1);
      } else {
        this.currentStep -= 1;
      }
    },
  },
};
</script>

<style lang="sass">
.mobile-configuration
  height: calc(100% - 70px)
  width: 100%
  margin-top: 70px
  position: fixed
  box-sizing: content-box
  overflow-y: scroll

  .content
    min-height: 100%
    background-size: cover
    background-image: url(~@/assets/img/pages/configurator/config-mobile-background.png)
    .card
      border-radius: 8px
      padding: 24px 16px
      text-align: left
      &:first-child
        gap: 16px
      strong
        @include body-bold
      a
        @include infos
        color: $primary
        text-decoration: none
      .app-radio label
        @include small-body
    .success-message
      padding: 5vw
      .card
        background: $white
        text-align: center
        svg
          width: 70px
          path
            fill: $subtitle
            &:first-child
              display: none
        h2
          color: $subtitle
        p
          margin: 32px 0
        .app-button
          width: 100%

  .cta-container
    width: 100%
    box-sizing: border-box
    position: sticky
    bottom: 0
    display: flex
    background: $white
    justify-content: space-around
    gap: 1rem
    padding: 1rem
    .app-button
      width: 100%
      &.secondary
        color: $body-color
        svg
          transform: rotate(180deg)
          margin-right: 8px
          vertical-align: text-bottom
          position: relative
          bottom: 1px
          path
            stroke: $body-color
</style>
