<template>
  <div class="hedifys-21 analytics-modal">
    <transition name="message">
    <div class="message-wrapper" v-if="visible">
      <check class="modal-icon" />
      <div class="title">
        <h2>{{ title }}</h2>
      </div>
      <div class="grid-x grid-margin-x row">
        <div class="cell auto">
          <p class="text">{{ text }}</p>
        </div>
      </div>
      <div class="grid-x grid-margin-x row">
        <div class="cell auto">
          <app-button v-if="hasConfirm" @click="confirm" theme="secondary" size="small">{{ confirmText }}</app-button>
        </div>
      </div>
      <div class="grid-x grid-margin-x row" :class="{ cancel: hasCancel }" v-if="hasCancel">
        <div class="cell auto">
          <a @click="cancel">{{ cancelText }}</a>
        </div>
      </div>
    </div>
    </transition>
    <transition name="screen">
      <div class="message-screen" v-if="visible" @click="hide()" />
    </transition>
  </div>
</template>

<script>
import check from '@/assets/img/pages/configurator/check.svg?inline';

import AppEvent from '../../basics/utils/AppEvent';

export default {
  components: {
    check,
  },
  name: 'analytics-modal',
  data() {
    return {
      visible: false,
      queryName: null,
      title: '',
      text: '',
      confirmText: 'Fermer',
      cancelText: 'Annuler',
      hasCancel: false,
      hasConfirm: true,
      onConfirm: {},
      onCancel: {},
    };
  },
  mounted() {
  },
  methods: {
    hide() {
      this.visible = false;
      if (this.queryName && this.queryName.length && this.$route.query[this.queryName]) {
        const query = {
          ...this.$route.query,
        };
        delete query[this.queryName];
        try {
          this.$router.push({
            params: this.$route.params,
            query,
          });
        } catch (error) {
          if (error.name !== 'NavigationDuplicated') {
            throw error;
          }
        }
      }
    },
    cancel() {
      if (typeof this.onCancel === 'function') {
        this.onCancel();
      }
      this.hide();
    },
    async confirm() {
      if (typeof this.onConfirm === 'function') {
        await this.onConfirm();
      }
      this.hide();
    },
    show(params) {
      this.title = params.title;
      this.text = params.text;
      this.onConfirm = params.onConfirm && typeof params.onConfirm === 'function' ? params.onConfirm : null;
      this.onCancel = params.onCancel && typeof params.onCancel === 'function' ? params.onCancel : null;
      this.confirmText = params.confirmText ? params.confirmText : null;
      this.cancelText = params.cancelText ? params.cancelText : null;
      this.queryName = params.queryName ? params.queryName : null;

      this.hasCancel = !!params.hasCancel;
      this.hasConfirm = !!params.hasConfirm;

      this.visible = true;
      if (params.queryName && params.queryName && this.visible && !this.$route.query[params.queryName]) {
        const query = {
          ...this.$route.query,
        };
        query[params.queryName] = 'true';
        try {
          this.$router.push({
            params: this.$route.params,
            query,
          });
        } catch (error) {
          if (error.name !== 'NavigationDuplicated') {
            throw error;
          }
        }
      }
    },
  },
  beforeMount() {
    AppEvent.$on('analytics-modal-show', (params) => {
      this.show(params);
    });
  },
  beforeDestroy() {
    AppEvent.$off('analytics-modal-show', (params) => {
      this.show(params);
    });
  },
};
</script>

<style lang="sass" scoped>
.analytics-modal
  .row
    @include row
  h2
    @include subtitle
    color: black
    text-align: center
  .message-wrapper
    position: fixed
    top: 50%
    left: 50%
    width: 440px
    padding: 40px
    padding-bottom: 16px
    border-radius: $global-border-radius
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)
    background: white
    z-index: 1000
    transform: translate(-50%, -50%)
    overflow: auto
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    @include breakpoint(large down)
      top: initial
      left: 0
      right: 0
      bottom: 0
      width: initial
      padding: 20px
      border-radius: 0
      transform: none

  .message-screen
    position: fixed
    top: 0
    bottom: 0
    left: 0
    right: 0
    background: rgba(0,0,0,0.4)
    z-index: 800

  .text
    text-align: center
    @include body
    color: $body-color
  .title
    display: flex
    justify-content: center
    margin-bottom: 0.5rem
  button.secondary
    outline: 1px solid $body-color
    & >>> span
      color: $body-color !important
  .cell
    justify-content: center
    display: flex

  .screen-enter-active, .screen-leave-active
    transition: opacity .9s

  .screen-enter, .screen-leave-to
    opacity: 0

  .message-enter-active, .message-leave-active
    transition: transform .5s, opacity .3s

  .message-enter, .message-leave-to
    opacity: 0
    transform: translateY(-130px) translateX(-50%)
  .modal-icon
    width: 69px
    height: 69px
    margin-bottom: 10px
</style>
