<template>
  <section class="mobile-configuration-version">
    <app-spinner v-if="isLoading" />
    <template v-else-if="configuration">
      <mobile-carousel v-if="configuration && versionMedias" :images="versionMedias"/>
      <div class="informations">
        <div class="card information">
          <div>
            <span class="name">{{ configuration.version.name }}</span>
            <br/>
            <strong>Maison {{ configuration.version.numberOfRooms }} ch.</strong>
          </div>
          <div class="price">
            <template v-if="configuration.totalPrice">{{ configuration.totalPrice | displayPrice }}*</template>
          </div>
          <p>
            Prix de la maison seule : <strong class="price-house">{{ configuration.initialPrice | displayPrice }}</strong><br/>
            * Prix du projet total incluant un estimatif terrain
          </p>
          <p class="description">{{ configuration.version.description }}</p>
          <p><strong>Une maison neuve à votre image plus économe qu'une ancienne !</strong></p>
          <!-- zone de partage -->
          <div id="Share-Version" class="share" @click="openShare">
            <div>
              <button class="share-icon">
                <img src="@/assets/gifs/share-transparent.gif"/>
              </button>
            </div>
            <div>
              <p>Cette maison vous plait ?</p>
              <p>Partager cette version avec un ami</p>
            </div>
          </div>
          <p v-if="postalCode.landBuiltAveragePrice > configuration.totalPrice">Le prix de ce projet de construction terrain + maison est inférieur à ceux constatés dans l’ancien sur le secteur. *</p>
        </div>
        <div class="card norms">
          <greenHouse />
          <p>Ce projet respectant <strong>les normes RE2020</strong> vous permettra de faire des économies d’énergie !</p>
        </div>
        <div class="card surfaces">
          <p class="title">
            <strong>Surface totale</strong>
            <strong>{{ configuration.totalSurface }} m2</strong>
          </p>
          <div class="surface" v-for="surface of configuration.version.surfaces" :key="surface.surfaceId">
            <span>{{ surface.room.name }}</span>
            <strong>{{ surface.area }} m2</strong>
          </div>
        </div>
        <div class="card inverted description-card">
          <img src="~@/assets/img/pages/configurator/pdf.svg" />
          <p>
            <strong>Recevez le descriptif complet de votre projet</strong>
            <br />
            Gratuit et sans engagement
            <ul>
              <li>Le plan</li>
              <li>La distribution (superficie et cotes)</li>
              <li>Le descriptif du terrain</li>
              <li>Les ouvrages et fournitures</li>
              <li>Les options</li>
              <li>Les aménagements optionnés</li>
              <li>Le récapitulatif budgétaire avec tous les frais annexe.</li>
              <li>Votre agence Alysia</li>
            </ul>
          </p>
        </div>
        <div class="precisions">
          * Prix incluant la construction de la maison ainsi que le prix moyen constaté d’un terrain sur la commune. Tarif issu des données cadastrales donné à titre indicatif.</div>
      </div>
    </template>
  </section>
</template>

<script>
import configurationApi from '@/services/api/configuration';
import versionMediaApi from '@/services/api/versionMedia';
import postalCodeApi from '@/services/api/postalCode';

import greenHouse from '@/assets/img/green-house.svg?inline';

import MobileCarousel from '@/components/general/MobileCarousel.vue';

export default {
  name: 'mobile-configuration-version',
  components: {
    MobileCarousel,
    greenHouse,
  },
  data() {
    return {
      isLoading: false,
      configuration: null,
      versionMedias: null,
      postalCode: null,
    };
  },
  async mounted() {
    await this.getData();
  },
  filters: {
    displayPrice(price) {
      return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(Math.round(price / 100));
    },
  },
  watch: {
    configuration() {
      this.$emit('update:configuration', this.configuration);
    },
  },
  methods: {
    async getData() {
      // Récupérertoutes les données, puis le prix / mois
      // (on a besoin de la configuration et du code postal pour l'avoir)
      this.isLoading = true;
      await this.getPostalCode();
      await Promise.all([this.getConfiguration(), this.getVersionMedias()]);
      this.isLoading = false;
    },
    async getConfiguration() {
      try {
        const customerLand = {
          width: 30,
          surface: 250,
          price: 250 * this.postalCode.landToBuildAveragePrice,
          postalCodeInseeNumber: this.$route.query.postalCodeInseeNumber,
          isMainDrainagePlanned: true,
          isServiced: true,
          isClayZone: false,
        };
        const response = await configurationApi.getConfiguration({
          customerLand,
          versionId: this.$route.query.versionId,
          packId: this.$route.query.packId,
        });
        this.configuration = response;
      } catch (e) {
        this.$message.show({
          text: 'Un problème est survenue durant la récupération de la configuration.',
        });
      }
    },
    async getVersionMedias() {
      try {
        this.versionMedias = await versionMediaApi.getAll(this.$route.query.versionId);
      } catch (err) {
        // Rien: on n'aura pas de carousel
      }
    },
    async getPostalCode() {
      try {
        this.postalCode = await postalCodeApi.getOne(this.$route.query.postalCodeInseeNumber);
      } catch (e) {
        this.postalCode = {
          postalCodeInseeNumber: this.$route.query.postalCodeInseeNumber,
          landToBuildAveragePrice: 0,
        };
      }
    },
    openShare() {
      this.$socialShares.show({});
    },
  },
};
</script>

<style lang="sass">
.mobile-configuration-version
  min-height: calc(100vh - 70px)
  > .app-spinner
    padding-top: 5rem
  .informations
    margin: 0 4vw
    padding-top: 3vh
    display: flex
    flex-direction: column
    gap: 2vh
    .information
      gap: 1vh !important
    .share
      display: flex
      flex-direction: row
      align-items: center
      justify-content: space-between
      margin-top: 2vh
      div
        p
          font-size: 14px
          font-weight: 700
          color: black
          &:last-child
            color: #ee41c1
      .share-icon
        border: none
        background-color: transparent
        margin-right: 10px
        z-index: 1
        cursor: pointer
        display: flex
        justify-content: center
        align-items: center
        img
          width: 65px
          height: 60px
    .card
      color: $black
      padding: 16px 20px
      border-radius: 8px
      background: $white
      display: flex
      flex-wrap: wrap
      align-items: center
      justify-content: space-between
      text-decoration: none
      &.norms
        display: flex
        justify-content: space-between
        flex-direction: row
        p
          width: 75%
      &.surfaces
        .title
          width: 100%
          display: flex
          justify-content: space-between
          padding: 16px 0
          strong
            font-size: 16px
            &:first-child
              text-transform: uppercase
        padding: 0
        span, strong
          padding: 0 16px
          color: $subtitle
        .surface
          width: 100%
          display: flex
          justify-content: space-between
          padding: 16px 0
          border-bottom: 1px solid $line
          &:last-child
            border-bottom: none
      &.inverted
        background: transparent
        border: 1px solid $white
        color: $white
        display: flex
        align-items: center
        justify-content: center
        h3
          color: $white
          text-align: center
          padding: 16px
        ul
          display: flex
          flex-direction: column
          padding: 0 16px
          li
            list-style: disc
            font-size: 14px
            line-height: 20px
        p
          color: $white
          padding-top: 16px
      &.description-card
        justify-content: left !important
        p, ul
          padding-top: 8px
        strong
          color: $white
          font-size: 16px
      > div:first-child
        span
          @include label
          font-weight: 700
        strong
          color: $black
      > p
        padding-top: 8px
        font-size: 14px
        line-height: 20px
        &.description
          padding-bottom: 8px
        strong
          font-size: 14px
          color: $black
          &.price-house
            color: $warning
          span
            font-size: 14px
            font-weight: 700
            color: $warning
      .price
        padding: 4px 10px
        background: $warning
        @include body-bold
        color: $white
        border-radius: 24px
        > .app-spinner
          padding: 4px 10px
          span
            border-color: $black
      .app-button
        margin-top: 16px
        width: 100%
        border-radius: 8px
    .precisions
      padding: 2vh 0
      font-size: 10px
      line-height: 12px
      color: $line
</style>
