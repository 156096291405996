import Vue from 'vue';
import * as dayjs from 'dayjs';
import VueAnalytics from 'vue-analytics';
import VueMeta from 'vue-meta';
import fr from 'dayjs/locale/fr';
import App from './App.vue';
import router from './router';

import Basics from './basics/index';
import Intersection from './directives/intersection';

// Analytics
Vue.use(VueAnalytics, {
  id: 'UA-107258517-1',
  router,
});

Vue.use(VueMeta);

dayjs.locale(fr);
Vue.prototype.$dayjs = dayjs;
Vue.config.productionTip = false;

// directives immodvisor pour relancer les scripts à l'entrée des tags
Vue.directive('immodvisor-widget', {
  inserted() {
    window.imdw.run();
  },
});

Vue.directive('intersection', Intersection);

// les composants du starter-kit
Vue.use(Basics);

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
