import axios from 'axios';

const { VUE_APP_API_URL, VUE_APP_BRAND_ID } = process.env;

/**
 * Récupère tous les niveaux
 */
const getAll = async () => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/public/brands/${VUE_APP_BRAND_ID}/floors`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const floor = {};
floor.getAll = getAll;

export default floor;
