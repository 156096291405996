<template>
  <section class="home">

    <section class="hero-small" id="hero">
      <div>
        <h1 class="h2">Configurez votre projet de construction</h1>
        <h2 class="h3">Le plus court chemin entre vous et votre maison</h2>
        <configurator-search/>
      </div>
    </section>

    <!-- Petites statistiques -->
    <div class="stats-container">
      <div>
        <p v-for="stat in statistics" :key="stat.id" class="stat">
          <strong>{{ stat.content.title }}</strong>
          <span>{{ stat.content.subtitle }}</span>
        </p>
      </div>
    </div>

    <div class="title-container">
      <h1>{{ texts.title }}</h1>
    </div>

    <div class="links-container">
      <router-link v-for="linkCard in linkCards" :key="linkCard.id" :to="{ path: linkCard.content.link.url }" class="link"
        :class="`link-${linkCard.content.color}`" :style="{ '--link-bg': `url(${linkCard.content.image.filename})` }">
        <div class="moving-bg" />
        <div class="fixed-bg" />
        <div class="text-container">
          <span>{{ linkCard.content.subtitle }}</span>
          <br />
          <strong>{{ linkCard.content.title }}</strong>
          <div class="arrow-container">
            <img src="~@/assets/img/arrow-thin-right.svg" alt="" />
          </div>
        </div>
      </router-link>
    </div>

    <!-- Vidéo -->
    <div class="video-container">
      <iframe width="600" height="380" src="https://www.youtube.com/embed/cuG9BCCnUAQ?controls=1"
        title="YouTube video player" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
      <div class="text-container">
        <h2>{{ texts.videoTitle }}</h2>
        <p>{{ texts.videoDescription }}</p>
      </div>
    </div>

    <div class="map-container">
      <div class="background-container">
        <div v-if="!selectedDpt" class="text-container">
          <h2>{{ texts.mapTitle }}</h2>
          <p>{{ texts.mapDescription }}</p>
          <p><strong>{{ texts.mapCta }}</strong></p>
        </div>

        <div v-else class="dpt-card-container" @click.self="selectedDpt = null">
          <div class="dpt-card">
            <button aria-label="fermer" @click="selectedDpt = null">
              <close aria-hidden />
            </button>
            <img :src="departments[selectedDpt].content.presentationImage.filename" alt="" />
            <div class="dpt-card-content">
              <h3>{{ departments[selectedDpt].content.name }}</h3>
              <p>{{ departments[selectedDpt].content.homeText || departments[selectedDpt].content.introductionText }}</p>
              <div class="cta-container">
                <app-button
                  @click="$router.push({ name: 'department', params: { dpt: `${departments[selectedDpt].slug}-${selectedDpt}` } })">En
                  savoir plus</app-button>
                <app-button theme="secondary"
                  @click="$router.push({ name: 'departement-agences', params: { slugDepartment: departments[selectedDpt].slug } })">Voir
                  les agences</app-button>
              </div>
            </div>
          </div>
        </div>

        <interactive-map v-model="selectedDpt" />

        <div class="counter-container">
          <div class="counter">
            <div v-for="(digit, index) in counter.value" :key="index" class="digit">{{ digit }}</div>
          </div>
          <strong>{{ texts.counterLegend }}</strong>
        </div>
      </div>
    </div>

    <!-- Arguments de vente -->
    <div class="selling-features-container">
      <div v-for="feature in sellingFeatures" :key="feature.id" class="selling-feature">
        <component :is="feature.content.icon" />
        <h3>{{ feature.content.title }}</h3>
        <p>{{ feature.content.text }}</p>
      </div>
    </div>

    <!-- Immodvisor -->
    <div v-if="windowWidth > 1024" id="imdw-rating" data-cid="31068" data-hash="CDQXP-GPUQ-ILQY-N6JDGI-LUYOE"
      data-ctype="brand" data-fp="" data-vertical-align="right" data-top-align="300" data-position="fixed"
      data-redirect="false" data-wording="plural"
      data-redirect-link="https://www.immodvisor.com/pro/maisons-alysia-dompierre-sur-mer-17139-31068.html" />
  </section>
</template>

<script>
import Storyblok from '@/services/storyblok';

import budget from '@/assets/img/pages/home/budget.svg?inline';
import house from '@/assets/img/pages/home/house.svg?inline';
import support from '@/assets/img/pages/home/support.svg?inline';
import engagement from '@/assets/img/pages/home/engagement.svg?inline';
import close from '@/assets/img/close.svg?inline';

import ConfiguratorSearch from '@/components/general/ConfiguratorSearch.vue';
import InteractiveMap from '@/components/general/InteractiveMap.vue';

export default {
  name: 'home',
  components: {
    budget,
    house,
    support,
    engagement,
    close,
    ConfiguratorSearch,
    InteractiveMap,
  },
  data() {
    return {
      selectedDpt: null,
      departments: {},
      linkCards: [],
      sellingFeatures: [],
      statistics: [],
      texts: {},
      counter: {
        value: '0000',
        observer: null,
        interval: null,
      },
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    this.getDepartments();
    this.getLinkCards();
    this.getSellingFeatures();
    this.getStatistics();
    this.getTexts();
    this.counter.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.triggerCounter();
        }
      });
    }, {
      root: null,
      rootMargin: '0px',
      threshold: 1,
    });
    this.setObserver();
  },
  beforeDestroy() {
    clearInterval(this.counter.interval);
  },
  methods: {
    setObserver() {
      const target = document.querySelector('.counter');
      if (target) {
        this.counter.observer.observe(target);
      }
    },
    triggerCounter() {
      this.counter.observer.disconnect();
      let counter = 0;
      this.counter.interval = setInterval(() => {
        counter += 1;
        this.counter.value = counter.toString().padStart(4, '0');
        if (counter === +this.texts.counter) {
          clearInterval(this.counter.interval);
        }
      }, 1);
    },
    async getDepartments() {
      try {
        const response = await Storyblok.get('cdn/stories/', {
          version: process.env.VUE_APP_STORYBLOK_ENV === 'development' ? 'draft' : 'published',
          starts_with: 'departments/',
        });
        response.data.stories.forEach((dpt) => {
          this.departments[dpt.content.number] = dpt;
        });
      } catch (err) {
        throw err;
      }
    },
    async getLinkCards() {
      try {
        const response = await Storyblok.get('cdn/stories/', {
          version: process.env.VUE_APP_STORYBLOK_ENV === 'development' ? 'draft' : 'published',
          starts_with: 'home/links',
        });
        const linkCards = [];
        linkCards.push(response.data.stories.find((story) => story.content.color === 'orange'));
        linkCards.push(response.data.stories.find((story) => story.content.color === 'skyblue'));
        linkCards.push(response.data.stories.find((story) => story.content.color === 'darkblue'));
        this.linkCards = linkCards;
      } catch (err) {
        throw err;
      }
    },
    async getSellingFeatures() {
      try {
        const response = await Storyblok.get('cdn/stories/', {
          version: process.env.VUE_APP_STORYBLOK_ENV === 'development' ? 'draft' : 'published',
          starts_with: 'home/selling-features',
        });
        const features = [];
        features.push(response.data.stories.find((story) => story.content.icon === 'budget'));
        features.push(response.data.stories.find((story) => story.content.icon === 'house'));
        features.push(response.data.stories.find((story) => story.content.icon === 'support'));
        features.push(response.data.stories.find((story) => story.content.icon === 'engagement'));
        this.sellingFeatures = features;
      } catch (err) {
        throw err;
      }
    },
    async getStatistics() {
      try {
        const response = await Storyblok.get('cdn/stories/', {
          version: process.env.VUE_APP_STORYBLOK_ENV === 'development' ? 'draft' : 'published',
          starts_with: 'home/statistics',
        });
        this.statistics = response.data.stories.sort((a, b) => a.content.order - b.content.order);
      } catch (err) {
        throw err;
      }
    },
    async getTexts() {
      try {
        const response = await Storyblok.get('cdn/stories/', {
          version: process.env.VUE_APP_STORYBLOK_ENV === 'development' ? 'draft' : 'published',
          starts_with: 'home/textes-divers',
        });
        this.texts = response.data.stories[0].content;
      } catch (err) {
        throw err;
      }
    },
  },
};

</script>

<style lang='sass' scoped>
  .home
    background: $white
    display: flex
    flex-direction: column
    @media (max-width: 1024px)
      > div, > h1
        order: 4
      .stats-container
        order: 2
        .stat
          background: $white
    .hero-small
      @include hero-small
      background-image: linear-gradient(180deg, rgba(10, 10, 10, 0.6) 0%, rgba(32, 32, 32, 0.2) 100%), url(~@/assets/img/pages/home-hero.jpg)
      > div
        width: 1000px
        max-width: 90%
        @media (max-width: 768px)
          max-width: 92vw
      h1, h2
        margin: 0 auto
      h2
        margin-bottom: 40px
      @media (max-width: 768px)
        min-height: 600px

    .stats-container
      padding: 3vw 0
      > div
        display: flex
        justify-content: center
        align-items: stretch
        margin: auto
        max-width: 900px
        .stat
          display: flex
          justify-content: center
          flex-direction: column
          text-align: center
          padding: 0 44px
          box-sizing: border-box
          flex-grow: 4
          width: min-content
          strong
            @include body-bold
            color: $primary-hover
            text-transform: uppercase
          &:not(:first-child)
            border-left: 1px solid $line
          &:first-child
            padding-left: 0
            flex-grow: 2
          &:last-child
            padding-right: 0
            flex-grow: 2
      @media (max-width: 1024px)
        padding: 32px 0
        width: 100%
        overflow-x: scroll
        scroll-snap-type: x mandatory
        > div
          width: fit-content
          gap: 16px
          justify-content: space-around
          padding: 0 32px
          .stat
            padding: 16px
            border: 1px solid $line
            border-radius: 8px
            min-width: 70vw
            flex-grow: 1
            box-sizing: border-box
            scroll-snap-align: center
            strong
              font-size: 12px
    .title-container
      background: $home-bg
      h1
        text-align: center
        width: 60%
        max-width: 800px
        margin: 75px auto 0 auto
        background: $home-bg
        @media (max-width: 768px)
          width: 85%

    .selling-features-container
      max-width: 80vw
      display: flex
      gap: 24px
      justify-content: center
      align-items: stretch
      margin: 80px auto
      .selling-feature
        @include card-content
        flex-basis: 0
        flex-grow: 1

      @media (max-width: 768px)
        flex-direction: column
  .video-container
    width: auto
    padding: 6vw 6vw 0 6vw
    display: flex
    flex-direction: row
    background: $home-bg
    gap: 6vw
    iframe
      width: 100%
      border-radius: 8px
    div
      width: 100%
      height: 80%
      max-width: 40vw
      p
        margin: 2rem 0
        white-space: pre-wrap
    @media (max-width: 768px)
      width: 90vw
      flex-direction: column-reverse
      padding-top: 100px
      iframe
        width: 100%
        height: 100%
        min-height: 210px
        border-radius: 8px
      div
        width: 100%
        max-width: none
  .map-container
    width: 100%
    background: $white
    .background-container
      position: relative
      display: flex
      justify-content: space-between
      align-items: center
      padding: 0 0 0 6vw
      box-sizing: border-box
      background-color: $home-bg
      border-radius: 0px 0px 0px 60% / 0px 0px 0px 10%
      .interactive-map
        width: 50%
        flex-shrink: 0
        margin-bottom: -5px
      .text-container
        max-width: 40vw
        p
          margin: 2rem 0
        strong
          color: $primary
      .dpt-card-container
        max-width: 40%
        .dpt-card
          width: 100%
          background: $white
          box-shadow: $drop-max
          border-radius: 8px
          position: relative
          overflow: hidden
          img
            width: 100%
            max-height: 15vw
            object-fit: cover
          > button
            all: unset
            position: absolute
            top: 1rem
            right: 1rem
            cursor: pointer
            svg
              width: 1rem
              height: 1rem
              stroke: $white
              stroke-width: 2px
          .dpt-card-content
            padding: 32px
            display: flex
            flex-direction: column
            gap: 24px
            p, button.secondary
              color: $subtitle
            .cta-container
              display: flex
              gap: 16px
      .counter-container
        position: absolute
        bottom: 0
        left: 50%
        transform: translate(-50%, -2rem)
        text-align: center
        margin: auto
        .counter
          background: #202020E5
          padding: 8px 16px
          border-radius: 48px
          width: fit-content
          margin: 1rem auto
          .digit
            display: inline-block
            color: $white
            padding: 0 13px
            font-size: 32px
            &:not(:first-child)
              border-left: 1px solid $body-color
            &:first-child
              padding-left: 0
            &:last-child
              padding-right: 0

        strong
          @include body-bold
          color: $subtitle
          text-transform: uppercase

    @media (max-width: 768px)
      .background-container
        padding: 100px 0 50px 0
        flex-direction: column
        border-radius: 0px 0px 0px 100% / 0px 0px 0px 5vh
        .text-container
          max-width: 90%
          margin: auto
        .interactive-map
          width: 100%
          margin: 0
        .dpt-card-container
          position: fixed
          max-width: unset
          top: 0
          left: 0
          right: 0
          bottom: 0
          z-index: 10
          background: #202020B2
          .dpt-card
            width: 80vw
            max-height: 80vh
            display: flex
            flex-direction: column
            margin: auto
            position: relative
            top: 50%
            transform: translateY(-50%)
            padding: 0
            > img
              max-height: unset
            .dpt-card-content
              flex-grow: 2
              overflow-y: scroll

        .counter-container
          position: static
          transform: unset
          margin-top: 3rem
          .counter
            .digit
              font-size: 24px
          strong
            font-size: 12px
            line-height: 20px

  .links-container
    display: flex
    gap: 42px
    background: $home-bg
    justify-content: center
    width: 100%
    padding: 4vw 0
    .link
      width: 330px
      height: 350px
      background-position: center
      background-size: cover
      text-decoration: none
      position: relative
      overflow: hidden
      background-image: var(--link-bg)
      .text-container
        color: $white
        padding: 25px
        position: absolute
        bottom: 0
        transform: translateY(55px)
        z-index: 1
        transition: all 0.4s ease-in-out
        span
          @include infos
          color: $white
          opacity: 0.6
          text-transform: uppercase
        strong
          @include subtitle
          font-weight: 500
        .arrow-container
          margin-top: 23px
      .moving-bg
        width: 150%
        height: 100%
        background: $warning
        position: relative
        bottom: 0
        left: -40%
        transform: translateY(55%)
        border-radius: 0px 330px 0px 0px / 0px 97px 0px 0px
        z-index: 1
        transition: all 0.4s ease-in-out
      .fixed-bg
        width: 150%
        height: 100%
        background: $loading
        opacity: 0.6
        position: relative
        bottom: 0
        transform: translateY(-45%)
        right: 0
        border-radius: 330px 0px 0px 0px / 97px 0px 0px 0px
        z-index: 0
      &:hover
        .text-container
          transform: translateY(0)
        .moving-bg
          border-radius: 0
          transform: translateY(0)

    .link-orange
      .moving-bg
        background: $warning
    .link-skyblue
      .moving-bg
        background: $primary
    .link-darkblue
      .moving-bg
        background: $colored-bg

    @media (max-width: 768px)
      flex-direction: column
      align-items: center
      padding: 8vw 0

</style>
