<template>
  <div class="app-avatar">
    <div class="avatar" v-if="!value">
      <avatar />
    </div>
    <div class="img" v-else>
      <img :src="value" alt="Profile picture">
    </div>
    <div class="btn">
      <input type="file" ref="file" accept="image/png, image/jpeg" @change="fileUpload">
      <app-button v-if="!value" theme="primary" icon="add" size="round" @click="$refs.file.click()"></app-button>
      <app-button v-else theme="warning" icon="remove" size="round" @click="remove"></app-button>
    </div>
  </div>
</template>

<script>
import avatar from '@/assets/img/avatar.svg?inline';

export default {
  name: 'app-avatar',
  components: {
    avatar,
  },
  props: {
    value: {
      type: String,
    },
  },
  methods: {
    fileUpload(event) {
      this.$emit('upload', event.target.files[0]);
    },
    remove() {
      this.$emit('remove');
    },
  },
};
</script>

<style lang="sass" scoped>
.app-avatar
  display: flex
  position: relative
  align-items: center
  justify-content: center
  background-color: $line
  border-radius: 50%
  user-select: none
  width: 72px
  height: 72px
  .btn
    position: absolute
    bottom: -8px
    right: -8px

  .img
    width: 72px
    height: 72px
    img
      border-radius: 50%
      width: 100%
      height: 100%
      object-fit: cover

  input[type="file"]
    display: none

  .avatar
    margin-top: 15px
    svg
      width: 56px
      height: 56px
      fill: $off-white
</style>
