<template>
  <section class="app-accordion">
    <!-- HEADER -->
    <header @click="openAccordion" class="header" :class="{'open': showContent}">
      <div class="grid-x">
        <div class="cell auto header-content">
          <slot name="header"/>
        </div>
        <div class="cell shrink arrow" :class="{'arrow-down': showContent}">
          <arrow />
        </div>
      </div>
    </header>

    <!-- CONTENT -->
    <transition
      name="accordion"
      @before-enter="beforeEnter"
      @enter="enter"
      @before-leave="beforeLeave"
      @leave="leave"
    >
      <section v-show="showContent" class="body" ref="section">
        <div class="grid-x body-content">
          <div class="cell auto">
            <slot name="content"/>
          </div>
        </div>
      </section>
    </transition>
  </section>
</template>

<script>
import arrow from '@/assets/img/arrow-blue.svg?inline';

export default {
  name: 'app-accordion',

  components: {
    arrow,
  },

  props: {
    isDefaultOpen: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showContent: false,
    };
  },

  mounted() {
    this.showContent = this.isDefaultOpen || false;
  },

  created() {
    window.addEventListener('resize', this.onResize);
  },

  destroyed() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    openAccordion() {
      this.showContent = !this.showContent;
    },

    beforeEnter(el) {
      const element = el;
      element.style.height = '0';
    },

    enter(el) {
      const element = el;
      element.style.height = `${element.scrollHeight}px`;
    },

    beforeLeave(el) {
      const element = el;
      element.style.height = `${element.scrollHeight}px`;
    },

    leave(el) {
      const element = el;
      element.style.height = '0';
    },

    onResize() {
      if (this.showContent) {
        this.$refs.section.style.height = `${this.$refs.section.scrollHeight}px`;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.app-accordion
  border-radius: $global-border-radius
  box-shadow: $drop-shadow

  .header
    background: $white
    padding: 24px 32px
    cursor: pointer
    border-radius: $global-border-radius
    transition: all 0.3s ease-out
    user-select: none

    .header-content
      display: flex
      flex-direction: row
      align-items: center

      h3
        margin: 0 24px 0 0
        color: $primary
        font-size: $font-lg

      p
        margin: 0
        color: $black
        font-size: $font-md
        @include subtitle

    .arrow
      display: flex
      align-items: center
      margin-left: 24px
      transition: all 0.3s ease-out

    .arrow-down
      transform: rotate(90deg)
      transition: all 0.3s ease-out

  .body
    background-color: $white
    border-radius: 0 0 8px 8px
    transition: all 0.3s ease-out
    overflow: hidden

    .body-content
      padding: 24px 64px 24px 32px
      @include body

  .open
    border-radius: $global-border-radius $global-border-radius 0 0
    transition: all 0.3s ease-out
</style>
