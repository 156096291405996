import axios from 'axios';

const { VUE_APP_API_URL, VUE_APP_BRAND_ID } = process.env;

/**
 * Récupère la liste des terrains publiques par codes postaux
 * @param {string} brandId: id de la marque
 * @param {string} postalCodeInseeNumber: postal code insee number
 */
const getAll = async (postalCodeInseeNumber) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/public/brands/${VUE_APP_BRAND_ID}/lands`, {
      params: { ...(postalCodeInseeNumber && { postalCodeInseeNumber }) },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Récupération d'un terrain
 * @param {string} landId: id du terrain à récupérer
 */
const getOne = async (landId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/public/lands/${landId}`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const land = {};
land.getAll = getAll;
land.getOne = getOne;

export default land;
