import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupération des medias de la version
 * @param {string} versionId: id de la version
 * @returns données de l'agence
 */
const getAll = async (versionId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/public/versions/${versionId}/version-medias`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const versionMedia = {};
versionMedia.getAll = getAll;

export default versionMedia;
