<template>
  <section class="app-lightbox">
    <transition name="modal">
      <div class="container" v-if="show" :class="slide">

        <div v-if="previousImage" :style="{ transitionDuration: `${time}ms`}" class="block previous">
          <span class="img">
            <img :src="previousImage"/>
          </span>
        </div>

        <div class="block current" :style="{ transitionDuration: `${time}ms`}">
          <div class="close-screen" v-if="show" @click.stop="$emit('update:show', false)"></div>

          <span class="img">
            <button v-if="imageIndex !== 0" class="left" @click="previousSlide()">◀</button>
            <img :src="currentImage"/>
            <button v-if="imageIndex < images.length - 1" class="right" @click="nextSlide()">▶</button>
          </span>
        </div>

        <div v-if="nextImage" :style="{ transitionDuration: `${time}ms`}" class="block next">
           <span class="img">
            <img :src="nextImage"/>
          </span>
        </div>

      </div>
    </transition>

    <transition name="screen">
      <div class="modal-screen" v-if="show"></div>
    </transition>

  </section>
</template>

<script>
const animationDuration = 400;

export default {
  name: 'app-lightbox',

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    images: Array,

    index: Number,
  },

  data() {
    return {
      slide: null,

      previousImage: null,
      currentImage: null,
      nextImage: null,

      imageIndex: this.index,
      time: 0,
    };
  },

  mounted() {
    this.init();
  },

  watch: {
    index: 'init',
    images: 'init',
  },

  methods: {
    init() {
      this.imageIndex = this.index;
      this.currentImage = this.images[this.imageIndex];
      this.previousImage = this.images[this.imageIndex - 1] ? this.images[this.imageIndex - 1] : null;
      this.nextImage = this.images[this.imageIndex + 1] ? this.images[this.imageIndex + 1] : null;
    },

    previousSlide() {
      this.time = animationDuration;
      this.slide = 'previous';

      setTimeout(() => {
        this.time = 0;
        this.imageIndex = Math.max(this.imageIndex - 1, 0);
        this.currentImage = this.images[this.imageIndex];
      }, animationDuration);

      this.changeSlide();
    },

    nextSlide() {
      this.time = animationDuration;
      this.slide = 'next';

      setTimeout(() => {
        this.time = 0;
        this.imageIndex = Math.min(this.imageIndex + 1, this.images.length);
        this.currentImage = this.images[this.imageIndex];
      }, animationDuration);

      this.changeSlide();
    },

    changeSlide() {
      setTimeout(() => {
        this.slide = null;
        this.previousImage = this.images[this.imageIndex - 1] ? this.images[this.imageIndex - 1] : null;
        this.nextImage = this.images[this.imageIndex + 1] ? this.images[this.imageIndex + 1] : null;
      }, animationDuration + 100);
    },
  },
};
</script>

<style lang="sass" scoped>
.app-lightbox
  .container
    position: fixed
    padding: 1rem
    z-index: 10
    &.previous
      .current
        transform: translateX(100%)
      .previous
        transform:  translateX(0%)
    &.next
      .current
        transform: translateX(-100%)
      .next
        transform:  translateX(0%)

  .block
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    transition: all 0s ease-in-out
    .img
      position: fixed
      top: 50%
      left: 50%
      display: flex
      align-items: center
      transform: translate(-50%, -50%)
      img
        max-width: calc(100vw - 100px)
        max-height: calc(100vh - 100px)
        box-shadow: $drop-max
        border-radius: 4px

      button
        position: absolute
        width: 50px
        padding: 0.55rem 0
        background: rgba($secondary-dark, 0.7)
        border: 0
        color: white
        font-size: 1.5rem
        text-align: center
        &.left
          left: 0
          border-right: 7px solid transparent
        &.right
          right: 0
          border-left: 7px solid transparent

    &.previous
      transform: translateX(-100%)
    &.next
      transform: translateX(100%)

  .modal-screen, .close-screen
    position: fixed
    top: 0
    bottom: 0
    left: 0
    right: 0
    z-index: 0

  .modal-screen
    background-color: rgba($secondary-dark, 0.7)
    z-index: 5

  .screen-enter-active
    transition: opacity .9s

  .screen-leave-active
    transition: opacity .3s

  .screen-enter, .screen-leave-to
    opacity: 0

  .modal-enter-active, .modal-leave-active
    transition: opacity 0.6s

  .modal-enter, .modal-leave-to
    opacity: 0

</style>
