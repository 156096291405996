<template>
  <div class="matchings">
    <!-- HEADER / FILTRE -->
    <div class="grid-x filter-header-container">
      <!-- FILTRE COMMUNE  -->
      <div class="cell large-3 medium-12 small-12 cities">
        <div class="filter-header grid-x"
          @click="() => openFilter('postalCode')"
          :class="{ 'is-open': isPostalCodeOpen }">
          <div class="cell small-2 icon header-column">
            <location class="location stroke" />
          </div>
          <div class="cell auto title-container header-column">
            <h4 class="filter-name">Commune</h4>
            <div v-if="postalCode && postalCode.postalCodeId">
              <p class="filter-value">{{ postalCode.city }}</p>
            </div>
            <div v-else>
              <p class="filter-value">Pas de commune</p>
            </div>
          </div>
          <div class="cell shrink icon header-column">
            <arrow class="arrow stroke" />
          </div>
        </div>
        <div class="dropdown-filter city" v-if="isPostalCodeOpen">
          <div class="grid-x city-toggle mobile">
            <div class="cell auto"></div>
            <div class="cell shrink">
              <app-toggle
                name='toggle-city'
                :icons='["search", "location"]'
                v-model="citySearchChoice"
                :selectedIcon="citySearchChoice || 'search'"
              />
            </div>
            <div class="cell auto"></div>
          </div>

          <h3 v-if="citySearchChoice === 'search'">Quelle commune recherchez-vous ?</h3>
          <div v-if="citySearchChoice === 'search'" class="grid-x">
            <div class="cell auto">
              <app-search-city
                placeholder="Nantes - 44000"
                @select="setSelectedPostalCode"
                @clear="resetSelectedPostalCode"
                @submit-city="() => applyPostalCode(true)"
                :selectedPostalCode="selectedPostalCode"
                required
              />
            </div>
          </div>
          <div  v-if="citySearchChoice === 'location'" class="mobile map">
            <matching-map
              v-if="showMap"
              :postalCodes="nearPostalCodes"
              :selectedPostalCode="postalCode"
              :height="500"
              @input="mapClicked"
              @mapdrag="mapDragged"
            />
          </div>
        </div>
      </div>
      <!-- FILTRES TERRAIN -->
      <div class="cell large-3 medium-12 small-12 lands">
        <div class="filter-header grid-x"
          @click="() => openFilter('land')"
          :class="{ 'is-open': isLandOpen }">
          <div class="cell small-2 icon header-column">
            <layer class="layer stroke" />
          </div>
          <div class="cell auto title-container header-column">
            <h4 class="filter-name">Terrain</h4>
            <div v-if="land && land.landId && $route.query.landId">
              <p class="filter-value">{{ land.allotment.allotmentId && land.lotNumber ? `Lot n°${land.lotNumber} - ` : '' }}{{ land.surface }}m² - {{ land.price ? utils.formatCentToEuro(land.price, true, true)  : '' }}</p>
            </div>
            <div v-else-if="landChoice === 'land' && (!availableLands || !availableLands.length)">
              <p class="filter-value">Aucun terrain disponible</p>
            </div>
            <div v-else-if="landChoice === 'no-land'">
              <p class="filter-value">Pas de terrains</p>
            </div>
            <div v-else-if="landChoice === 'custom-land'">
              <p class="filter-value" v-if="$route.query.landWidth && $route.query.landPrice">Mon terrain Larg. {{ $route.query.landWidth }} m</p>
              <p class="filter-value" v-else>Définir mon terrain</p>
            </div>
            <div v-else>
              <p class="filter-value">{{ availableLandsMsg }}</p>
            </div>
          </div>
          <div class="cell shrink icon header-column">
            <arrow class="arrow stroke" />
          </div>
        </div>
        <div class="dropdown-filter land" v-if="isLandOpen">
          <!-- CHOIX DU MODE DE TERRAIN  -->
          <form ref="land" @submit.prevent="applyLandChoice">
            <div class="grid-x">
              <div
                class="cell large-auto medium-12 small-12 land-choice grid-x"
                :class="{ 'is-selected': landChoice === 'land' }"
                @click="() => landChoiceChange('land')">
                <div class="cell shrink">
                  <search-land class="land-icon" />
                </div>
                <div class="cell auto">
                  <p class="cta">Choisir un terrain</p>
                  <p class="land-count" v-if="allLands && allLands.length">
                    {{ allLands.length }} terrain{{ allLands.length > 1 ? 's' : '' }} disponible{{ allLands.length > 1 ? 's' : '' }}
                  </p>
                </div>
              </div>
              <div
                class="cell large-auto medium-12 small-12 land-choice grid-x"
                :class="{ 'is-selected': landChoice === 'custom-land' }"
                @click="() => landChoiceChange('custom-land')">
                <div class="cell shrink">
                  <my-land class="land-icon" />
                </div>
                <div class="cell auto">
                  <p class="cta">J'ai mon terrain</p>
                </div>
              </div>
              <div
                class="cell large-auto medium-12 small-12 land-choice grid-x"
                :class="{ 'is-selected': landChoice === 'no-land' }"
                @click="() => landChoiceChange('no-land')">
                <div class="cell shrink">
                  <later-land class="land-icon" />
                </div>
                <div class="cell auto">
                  <p class="cta">A définir plus tard</p>
                </div>
              </div>
            </div>
          </form>
          <!-- CHOIX DES TERRAINS, POUR LA COMMUNE  -->
          <div class="land-detail" v-if="landChoice === 'land' && availableLands && availableLands.length">
            <div
              class="allotment-container"
              v-for="(landsByAllotment, index) in availableLands"
              :key="landsByAllotment.allotment && landsByAllotment.allotment.allotmentId ? landsByAllotment.allotment.allotmentId : index">
              <div class="allotment-header grid-x">
                <div class="cell auto">
                  <h5>
                    <slot v-if="postalCode && postalCode.city">{{ postalCode.city }} - </slot>
                    {{ landsByAllotment && landsByAllotment.allotment && landsByAllotment.allotment.name ? landsByAllotment.allotment.name : 'Terrains sans lotissements' }}
                  </h5>
                  <div class="company" v-if="landsByAllotment.company && landsByAllotment.company.companyId">
                    <p>Lotissement proposé par notre partenaire</p>
                    <p class="company-name">{{ landsByAllotment.company.name }}</p>
                  </div>
                </div>
                <div
                  v-if="landsByAllotment && landsByAllotment.allotment && landsByAllotment.allotment.presentationPdf && landsByAllotment.allotment.presentationPdf.includes('https://')"
                  class="cell shrink download"
                  @click="downloadPdf(landsByAllotment.allotment)">
                  <document />
                  <p>Voir la fiche<br>lotissement</p>
                </div>
              </div>
              <div class="allotment-lands" v-if="landsByAllotment && landsByAllotment.lands.length">
                <div v-for="(land, index) in landsByAllotment.lands" :key="land && land.landId ? land.landId : index" class="grid-x">
                  <div class="cell auto land-name">
                    <p>{{ land.lotNumber ? `Lot n°${land.lotNumber} - ` : '' }}{{ land.surface ? `${land.surface} m²` : '' }}</p>
                  </div>
                  <div class="cell shrink">
                    <h3>{{ land.price ? utils.formatCentToEuro(land.price, true, true)  : '' }}</h3>
                  </div>
                  <div class="cell shrink land-radio">
                    <app-radio label="" v-if="land" v-model="landId" :value="land.landId" @input="landChange(land)"></app-radio>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="land-detail land" v-else-if="landChoice === 'land' && (!availableLands || !availableLands.length)">
            <h3>Aucun terrain disponible pour cette commune</h3>
            <p>Nous n’avons actuellement aucun terrain sur cette commune. Vous pouvez voir les terrains disponibles sur les communes alentours directement sur la carte ci-dessous.</p>
            <div class="desktop">
              <app-button v-if="nearPostalCodes && nearPostalCodes.length > 1" size="large" theme="primary" @click="nextPostalCode">Voir les terrains les plus proches</app-button>
            </div>
            <div class="mobile">
              <app-button v-if="nearPostalCodes && nearPostalCodes.length > 1" size="large" theme="primary" @click="displayMap">Voir les terrains les plus proches</app-button>
            </div>

          </div>
          <!-- FORMULAIRE DE TERRAIN CUSTOM  -->
          <div class="land-detail custom-land" v-if="landChoice === 'custom-land'">
            <h3>Personnaliser votre recherche de maison avec votre terrain</h3>
            <p>
              Pour afficher les modèles de maisons compatibles avec votre terrain, renseignez les informations ci-dessous.
              Sinon, cliquez sur le bouton « A définir plus tard ».
            </p>
            <form ref="custom-change" @submit.prevent="applyCustomLand">
              <div class="grid-x grid-margin-x row">
                <div class="cell large-auto medium-12 small-12">
                  <app-input v-model.number="landWidth" :min="0" :step="0.01" label="Largeur du terrain" placeholder="Largeur..." type="number" unit="m" required />
                </div>
                <div class="cell large-auto medium-12 small-12">
                  <app-input v-model.number="landPrice" :min="1" :step="1" label="Prix du terrain" placeholder="Prix..." type="number" unit="€" required />
                </div>
              </div>
              <div class="grid-x grid-margin-x row">
                <div class="cell large-auto medium-12 small-12">
                  <app-input v-model.number="landSurface" :step="0.01" label="Superficie du terrain" placeholder="Superficie..." type="number" unit="m²" />
                </div>
                <div class="cell large-auto medium-12 small-12">
                  <p><span class="label">Nature du terrain</span></p>
                  <app-checkbox
                    :value="'true'"
                    v-model="isClayZone"
                  >
                    <span class="checkbox-value">Zone argileuse</span>
                  </app-checkbox>
                </div>
              </div>
              <div class="grid-x grid-margin-x row">
                <div class="cell large-auto medium-12 small-12">
                  <app-radio-button
                    label="Assainissement"
                    name="isMainDrainagePlanned"
                    :value="isMainDrainagePlanned"
                    v-model="isMainDrainagePlanned"
                    :options="[
                      { name: 'true', label: 'Tout à l\'égout' },
                      { name: 'false', label: 'Autonome' }
                    ]"
                  />
                </div>
                <div class="cell large-auto medium-12 small-12">
                  <app-radio-button
                    label="Aménagement"
                    name="isServiced"
                    :value="isServiced"
                    v-model="isServiced"
                    :options="[
                      { name: 'true', label: 'Viabilisé' },
                      { name: 'false', label: 'Non viabilisé' }
                    ]"
                  />
                </div>
              </div>
              <app-button class="mobile-big-button" size="large" type="submit">Appliquer pour mon terrain</app-button>
            </form>
          </div>
          <div class="land-detail no-land" v-if="landChoice === 'no-land'">
            <h3>Découvrez tous nos modèles de maisons</h3>
            <p>Accédez immédiatement à l’intégralité de notre catalogue sans terrain. Par la suite, vous pourrez toujours compléter votre projet.</p>
            <app-button size="large" theme="primary" @click="isLandOpen = false">Voir les modèles</app-button>
          </div>
        </div>
      </div>
      <!-- FILTRE DES VERSION  -->
      <div class="cell large-auto medium-12 small-12 filters">
        <div
          class="filter-header grid-x desktop"
          @click="() => openFilter('version')"
          :class="{ 'is-open': isVersionOpen }">
          <div class="cell small-2 icon header-column">
            <filters class="filter stroke" />
          </div>
          <div class="cell shrink header-column">
            <p class="filter-value">Filtrer</p>
          </div>
          <div class="cell auto bubbles-container grid-x" v-if="models && models.length && ranges && ranges.length && floors && floors.length">
            <div class="bubble cell shrink" v-if="selectedRange">
              {{ ranges.find((r) => r.name === selectedRange) ? ranges.find((r) => r.name === selectedRange).label : '' }}
            </div>
            <div class="bubble cell shrink" v-if="modelId" @click.stop="() => removeFilter('modelId')">
              {{ models.find((model) => model.name === modelId) ? models.find((model) => model.name === modelId).label : '' }}
              <remove class="remove-option" />
            </div>
            <div class="bubble cell shrink" v-if="roomCount" @click.stop="() => removeFilter('roomCount')">
              {{ roomCount }} chambre{{ roomCount > 1 ? 's' : '' }}
              <remove class="remove-option" />
            </div>
            <div class="bubble cell shrink" v-if="bathroomCount" @click.stop="() => removeFilter('bathroomCount')">
              {{ bathroomCount }} salle{{ bathroomCount > 1 ? 's' : '' }} de bain
              <remove class="remove-option" />
            </div>
            <div class="bubble cell shrink" v-if="surfaceRange && (surfaceRange.start && surfaceRange.start !== 0 || surfaceRange.end && surfaceRange.end !== 300)" @click.stop="() => removeFilter('surfaceRange')">
              {{ surfaceRange.start }}m² - {{ surfaceRange.end }}m²
              <remove class="remove-option" />
            </div>
            <div class="bubble cell shrink" v-if="floorId" @click.stop="() => removeFilter('floorId')">
              {{ floors.find((floor) => floor.name === floorId) ? floors.find((floor) => floor.name === floorId).label : null }}
              <remove class="remove-option" />
            </div>
            <div class="bubble cell shrink" v-if="priceRange && (priceRange.start && priceRange.start !== 0 || priceRange.end && priceRange.end !== 600000)" @click.stop="() => removeFilter('priceRange')">
              {{ priceRange.start }}€ - {{ priceRange.end }}€
              <remove class="remove-option" />
            </div>
            <div class="bubble cell shrink" v-if="garage" @click.stop="() => removeFilter('garage')">
              {{ garage === 'withGarage' ? 'Avec garage' : 'Sans garage' }}
              <remove class="remove-option" />
            </div>
          </div>
          <div v-else class="cell auto">
          </div>
          <div class="cell shrink icon">
            <arrow class="arrow stroke" />
          </div>
        </div>
        <div class="dropdown-filter-version" v-if="isVersionOpen">
          <div class="filter-body">
            <div class="grid-x row grid-margin-x">
              <div class="cell large-auto medium-12 small-12">
                <app-radio-button
                  label="Trier par"
                  name="orderBy"
                  :value="orderBy"
                  v-model="orderBy"
                  :options="[
                    { name: 'price', label: 'Prix croissant' },
                    { name: '-price', label: 'Prix décroissant' },
                    { name: 'favorite', label: 'Favoris' },
                  ]"
                />
              </div>
              <div class="cell large-auto medium-12 small-12">
                <app-double-slider
                  label="Budget"
                  :min="0"
                  :max="600000"
                  unit="€"
                  v-model="priceRange"
                  :value="priceRange"
                  :step="1000"
                />
              </div>
            </div>
            <div class="grid-x row grid-margin-x">
              <div class="cell large-auto medium-12 small-12">
                  <app-radio-button
                    label="Gammes"
                    name="range"
                    :value="selectedRange"
                    v-model="selectedRange"
                    :options="ranges"
                    required
                  />
              </div>
              <div class="cell large-auto medium-12 small-12">
                <app-select
                  label="Modèles"
                  v-model="modelId"
                  :options="models"
                />
              </div>
            </div>
            <div class="grid-x row grid-margin-x">
              <div class="cell large-auto medium-12 small-12">
                <app-radio-button
                  label="Niveau"
                  name="floor"
                  :value="floorId"
                  v-model="floorId"
                  :options="floors"
                />
              </div>
              <div class="cell large-auto medium-12 small-12">
                <app-double-slider
                  label="Surfaces habitables"
                  :min="0"
                  :max="300"
                  unit="m²"
                  v-model="surfaceRange"
                  :value="surfaceRange"
                />
                <app-radio-button
                  class="filter-garage"
                  label="Garage"
                  name="garage"
                  :value="garage"
                  v-model="garage"
                  :options="[
                    { name: 'withoutGarage', label: 'Sans garage' },
                    { name: 'withGarage', label: 'Avec garage' },
                  ]"
                />
              </div>
            </div>
            <div class="grid-x row grid-margin-x">
              <div class="cell large-auto medium-12 small-12">
                <app-radio-button
                  label="Chambres"
                  name="roomCount"
                  :value="roomCount"
                  v-model="roomCount"
                  :options="[
                    { name: '1', label: '1' },
                    { name: '2', label: '2' },
                    { name: '3', label: '3' },
                    { name: '4', label: '4' },
                    { name: '5', label: '5' },
                  ]"
                />
              </div>
              <div class="cell large-auto medium-12 small-12">
                <app-radio-button
                  label="Salles de bains"
                  name="bathroomCount"
                  :value="bathroomCount"
                  v-model="bathroomCount"
                  :options="[
                    { name: '1', label: '1' },
                    { name: '2', label: '2' },
                  ]"
                />
              </div>
            </div>
          </div>
          <div class="filter-bottom grid-x">
            <div class="cell shrink">
              <app-button theme="primary" @click="filterMatchings">Afficher résultat</app-button>
            </div>
            <div class="cell auto"></div>
            <div class="cell shrink init" @click="resetVersionFilter">
              <p>Réinitialiser</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BODY -->
    <div class="list-container">
      <div v-if="isPostalCodeOpen || isLandOpen || isVersionOpen" class="cover" @click="closeFilter"></div>
      <div class="grid-x">
        <div class="cell large-6 medium-12 small-12 map desktop" :class="{'is-open': isPostalCodeOpen }" v-if="landChoice === 'land'">
          <!-- CARTE DES TERRAINS -->
          <matching-map
            v-if="showMap"
            :postalCodes="nearPostalCodes"
            :selectedPostalCode="postalCode"
            :height="500"
            @input="mapClicked"
            @mapdrag="mapDragged"
          />
        </div>
        <div class="cell large-auto medium-12 small-12 list" :class="{'is-open': !isPostalCodeOpen && !isLandOpen && !isVersionOpen }" v-if="!isLoading && filteredMatchings && filteredMatchings.length">
          <div v-if="viewType === 'version'" class="page-body">
            <h2>Nos {{ filteredMatchings && filteredMatchings.length ? `${filteredMatchings.length} versions ${modelId && models.find((m) => m.name === modelId) ? ` du modèle ${models.find((m) => m.name === modelId).label}` : ''} à ${postalCode ? postalCode.city : ''}` : ' ' }}</h2>
            <!-- LISTE DES MATCHINGS -->
            <div class="grid-x back-container">
              <div class="cell large-auto medium-auto small-9">
                <div class="link" @click="goBackToModels">
                  <return-arrow />
                  <span>Retour aux modèles</span>
                </div>
              </div>
              <div class="cell large-shrink medium-shrink small-3 toggle-land-container">
                <app-toggle
                  name='toggle'
                  :icons='["blueprint", "house"]'
                  v-model="imagesType"
                  :selectedIcon="$route.query.imagesType || imagesType || 'house'"
                  @input="updateImages"
                />
              </div>
            </div>
            <div class="grid-x grid-margin-x">
              <div
                class="cell small-12 medium-6"
                :class="{ 'large-6': landChoice === 'land', 'large-3': landChoice !== 'land' }"
                v-intersection="handleCardIntersection" :data-version="matching.version.versionId"
                v-for="(matching, index) in filteredMatchings"
                :key="matching && matching.version ? matching.version.versionId : index">
                <matching-card :matching="matching" :landChoice="landChoice" :imagesType="imagesType" @share="share" />
              </div>
            </div>
          </div>

          <div v-else-if="versionsByModels && versionsByModels.length" class="page-body">
            <!-- LISTE DES MODELES -->
            <div class="grid-x">
              <div class="cell auto">
                <h2>Nos {{ versionsByModels && versionsByModels.length ? `${versionsByModels.filter((model) => !model.isAd && !model.isFlashNews).length} modèles de maisons pour construire à ${postalCode ? postalCode.city : ''}` : ' ' }}</h2>
              </div>
            </div>
            <!-- GALERIE : LISTE DES VIGNETTES -->
            <div class="grid-x grid-margin-x">
              <template v-for="(model, index) in versionsByModels">
                <!-- Vignette flash news -->
                <div
                  v-if="model.isFlashNews"
                  :key="model && model.modelId ? model.modelId : index"
                  class="cell card small-12 medium-6 flash-news-card"
                  :class="{ 'large-6': landChoice === 'land', 'large-3': landChoice !== 'land' }"
                  :style="{ backgroundColor: model.backgroundColor }"
                >
                  <div class="flash-news-banner">flash info</div>
                  <img :src="model.image.filename" alt="" />
                  <h5>{{ model.name }}</h5>
                  <p class="card-description">{{ model.description }}</p>
                  <p class="card-fine-prints">{{ model.finePrints }}</p>
                </div>
                <!-- Vignettes maison ou pub -->
                <div v-else
                  :key="model && model.modelId ? model.modelId : index"
                  class="cell matching card model-card small-12 medium-6"
                  :class="{
                    'large-6': landChoice === 'land',
                    'large-3': landChoice !== 'land',
                    'no-land': !model.isAd && model && !filteredMatchings.find((matching) => matching && matching.version && matching.version.model.modelId === model.modelId && matching.land) && landChoice !== 'no-land',
                    'is-ad': model.isAd,
                  }"
                  :style="{ backgroundColor: model.backgroundColor }"
                  @click="() => model.isAd ? goToAd(model.link.url) : selectModel(model)"
                >
                  <img v-if="model && model.image && !model.isAd" :src="model.image" alt="" />
                  <div v-else-if="model.description" class="grid-x">
                    <div class="cell small-6">
                      <img v-if="model && model.image" :src="model.image.filename" alt="" />
                    </div>
                    <div class="cell auto">
                      <h4 v-html="model.description"></h4>
                    </div>
                  </div>
                  <div v-else-if="model.isAd" class="grid-x image-only">
                    <div class="cell auto">
                      <img v-if="model && model.image" :src="model.image.filename" alt="" />
                    </div>
                  </div>
                  <div v-else class="image-not-found">
                    <upload class="icon-upload" />
                  </div>
                  <div class="card-content" v-if="!model.isAd" v-intersection="handleCardIntersection" :data-version="model.displayedVersion">
                    <div class="model-version-price">
                      <div v-if="model && model.name">
                        <h4 class="model">Modèle</h4>
                        <h5 class="model-name">{{ model.name.includes('| INVEST') ?  model.name.replace('| INVEST', '') : model.name }} <small v-if="model.name.includes('| INVEST') ">INVEST</small></h5>
                        <p class="version-count" :class="{'land': landChoice === 'land'}" v-if="model.versionCount">{{ model.versionCount }} version{{ model.versionCount > 1 ? 's' : '' }}</p>
                      </div>
                      <div class="price-container">
                        <h4 class="model">À partir de</h4>
                        <div class="price" v-if="model && landChoice === 'no-land' && (model.priceWithoutAdditionalFees || model.priceWithoutAdditionalFees === 0)">
                          <h3>{{ model.priceWithoutAdditionalFees || model.priceWithoutAdditionalFees === 0 ? utils.formatCentToEuro(model.priceWithoutAdditionalFees, true, true)  : '' }}</h3>
                        </div>
                        <div class="price" v-else-if="model && landChoice !== 'no-land' && (model.price || model.price === 0)">
                          <h3>{{ model.price || model.price === 0 ? utils.formatCentToEuro(model.price, true, true)  : '' }}</h3>
                        </div>
                        <div v-else class="price">
                          <div class="white-spinner">
                            <app-spinner small />
                            <h3>€</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="title-container">
                    <h5>{{ model.name }}</h5>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="cell auto main-loading" v-else-if="isLoading">
          <div class="spinner-container">
            <app-spinner />
          </div>
        </div>
        <div v-else class="empty-list">
          <p>Aucun modèle de maison pour les filtres demandées</p>
        </div>
      </div>
    </div>
    <div
      v-if="!isPostalCodeOpen && !isLandOpen && !isVersionOpen"
      class="version-filters mobile"
      :class="{ 'edition' : $route.name.includes('edit') }"
      @click="() => openFilter('version')">
      <filters class="filter stroke" />
    </div>
    <div class="edit-configuration" v-if="$route.name.includes('edit')" :class="{ 'hide-config': isPostalCodeOpen || isLandOpen || isVersionOpen }">
      <div class="edit-configuration-left">
        <div class="edit-configuration-logo">
          <house />
          <p class="edit-configuration-status">Projet<br /> en cours</p>
        </div>
        <div class="edit-configuration-selection" v-if="selectedMatching">
          <p class="edit-configuration-version" v-if="selectedMatching.version">Maison {{ selectedMatching.version.name }} {{ selectedMatching.version.numberOfRooms }} Chambre{{ selectedMatching.version.numberOfRooms > 1 ? 's' : '' }} à {{ postalCode.city }}</p>
          <p class="edit-configuration-land" v-if="selectedMatching.land && selectedMatching.land.allotment && selectedMatching.land.allotment.allotmentId">Lotissement {{selectedMatching.land.allotment.name}}{{ `${selectedMatching.land.lotNumber ? ` - Lot n°${selectedMatching.land.lotNumber}` : ''}` }} - {{ selectedMatching.land.surface }}m²</p>
          <p class="edit-configuration-land" v-else-if="selectedMatching.land && selectedMatching.land.surface">Terrain {{ selectedMatching.land.surface }} m²</p>
        </div>
        <div v-else-if="isPreviousMatchingInit">
          <p>Nous n'avons pas pu reprendre la même version, veuillez faire une nouvelle sélection</p>
        </div>
        <div v-else class="edit-configuration-loader">
          <app-spinner small />
        </div>
      </div>
      <div class="edit-configuration-right" v-if="selectedMatching && selectedMatching.version">
        <div class="edit-configuration-metrics">
          <p class="edit-configuration-surface">{{ selectedMatching.totalSurfaceHabitable ? `${Math.round(selectedMatching.totalSurfaceHabitable)} m²` : '' }}</p>
          <p class="edit-configuration-price" v-if="landChoice === 'no-land' && selectedMatching.priceWithoutAdditionalFees">{{ utils.formatCentToEuro(selectedMatching.priceWithoutAdditionalFees, true, true) }}</p>
          <p class="edit-configuration-price" v-else-if="landChoice !== 'no-land' && selectedMatching.price">{{ utils.formatCentToEuro(selectedMatching.price, true, true) }}</p>
          <p class="edit-configuration-price" v-else><app-spinner small /></p>
        </div>
        <div class="edit-configuration-actions">
          <div>
            <app-button theme="secondary" size="small" @click="cancelConfigEdit">Annuler</app-button>
          </div>
          <div>
            <app-button theme="primary" size="small" @click="applyConfigEdit" :disabled="!(selectedMatching && selectedMatching.version)">Continuer</app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import calculatorApi from '@/services/api/calculator';
import configurationApi from '@/services/api/configuration';
import postalCodeApi from '@/services/api/postalCode';
import projectDraftApi from '@/services/api/projectDraft';
import landApi from '@/services/api/land';
import agencyApi from '@/services/api/agency';
import roomApi from '@/services/api/room';
import floorApi from '@/services/api/floor';
import rangeApi from '@/services/api/range';
import modelApi from '@/services/api/model';
import utils from '@/services/utils/utils';
import ls from 'local-storage';

import AppSearchCity from '@/basics/components/AppSearchCity.vue';
import MatchingMap from '@/views/configuration/MatchingMap.vue';

import upload from '@/assets/img/upload.svg?inline';
import arrow from '@/assets/img/nav/arrow.svg?inline';
import location from '@/assets/img/location.svg?inline';
import layer from '@/assets/img/nav/layer.svg?inline';
import filters from '@/assets/img/filter.svg?inline';
import laterLand from '@/assets/img/later-land.svg?inline';
import myLand from '@/assets/img/my-land.svg?inline';
import searchLand from '@/assets/img/search-land.svg?inline';
import document from '@/assets/img/document.svg?inline';
import remove from '@/assets/img/remove.svg?inline';
import returnArrow from '@/assets/img/return-arrow.svg?inline';
import house from '@/assets/img/house.svg?inline';

import Storyblok from '@/services/storyblok';

import MatchingCard from './MatchingCard.vue';

export default {
  name: 'Matchings',
  components: {
    AppSearchCity,
    upload,
    location,
    arrow,
    filters,
    layer,
    document,
    'later-land': laterLand,
    'my-land': myLand,
    'search-land': searchLand,
    'return-arrow': returnArrow,
    remove,
    MatchingMap,
    house,
    MatchingCard,
  },
  data() {
    return {
      isLoading: null,
      agencyCode: null,
      interestRate: 0,

      // config params
      postalCode: {
        postalCodeId: null,
        postalCodeInseeNumber: null,
      },
      projectDraft: null,
      land: null,
      landId: null,
      // custom land
      isCustomLandApplied: false,
      landWidth: null,
      landSurface: null,
      landPrice: null,
      isMainDrainagePlanned: null,
      isServiced: null,
      isClayZone: null,

      // Infos pour les filtres
      matchings: null,
      allLands: null,
      availableLands: null,
      allotments: null,
      utils,
      // la sélection peut être 'land', 'no-land', ou 'custom-land'
      landChoice: 'land',

      // listes pour les filtres de version
      rooms: null,
      garage: null,

      models: null,
      ranges: null,
      floors: null,

      // filtres pour la version, front
      filteredMatchings: null,

      bathroomCount: null,
      floorId: null,
      modelId: null,
      orderBy: null,
      priceRange: {
        start: 0,
        end: 600000,
      },
      roomCount: null,
      selectedRange: null,
      surfaceRange: {
        start: 0,
        end: 300,
      },
      priceLoaded: false,

      // inputs de filtres
      selectedPostalCode: null,
      selectedPostalCodeInseeNumber: null,

      // la vue peut être 'version' ou 'model'
      viewType: 'model',
      versionsByModels: null,
      pricedVersions: {},
      toPriceVersions: {},

      // Conditions pour l'interface
      isPostalCodeOpen: false,
      isVersionOpen: false,
      isLandOpen: false,

      nearPostalCodes: null,

      // Le nouveau matching sélectionné
      selectedMatching: null,
      // La configuration précédente pour le retour en arrière
      previousConfiguration: null,
      previousCustomerLand: null,
      previousPostalCodeInseeNumber: null,
      previousCustomerId: null,

      isPreviousMatchingInit: false,
      isMapLoading: false,
      showMap: true,

      // mobile
      citySearchChoice: 'search',

      allModels: null,
      allModelsFormatted: null,

      matchingFavorite: ls('MATCHING_FAVORITE') || [],

      // Ads
      ads: [],

      imagesType: 'house',
    };
  },
  async created() {
    await this.getAds();
    await this.getData();
  },
  watch: {
    // Déclenche la recherche de produits à chaque changement de query
    async $route(newRoute, previousRoute) {
      if (newRoute.name.includes('matchings')) {
        if (
          !previousRoute.name.includes('matchings')
          && previousRoute.name !== 'configuration'
          && previousRoute.name !== 'project-projectId') {
          // La page est caché, donc on doit la recharger si on vient d'une autre page que du config
          this.showMap = false;
          this.viewType = 'model';
          await this.getData();
          this.showMap = true;
        } else {
          // Si on vient d'une config ou des matchings
          if (this.matchings) {
            if (newRoute.name.includes('matchings-edit')) {
              this.showMap = false;
              this.viewType = 'model';
              // affichage de la carte
              let landChoice = '';
              if (this.$route.query.landChoice) {
                landChoice = this.$route.query.landChoice;
              } else if (this.$route.query.emptyLand) {
                landChoice = 'no-land';
              } else if (this.$route.query.landWidth) {
                landChoice = 'custom-land';
              } else {
                landChoice = 'land';
              }
              this.landChoice = landChoice;

              this.showMap = true;
            }

            if (this.landChoice === 'land') {
              // On ouvre le dropdown des terrains si on a 0 terrain
              if (this.availableLands.length === 0) {
                this.isLandOpen = true;
              } else {
                this.isLandOpen = false;
              }
            }

            if (this.landChoice === 'no-land') {
              this.isLandOpen = false;
            }

            if (this.landChoice === 'custom-land') {
              this.landWidth = this.$route.query.landWidth || null;
              this.landSurface = this.$route.query.landSurface || null;
              this.landPrice = this.$route.query.landPrice ? Math.round(utils.formatCentToEuro(this.$route.query.landPrice)) : null;
              this.isMainDrainagePlanned = typeof this.$route.query.isMainDrainagePlanned === 'boolean' ? this.$route.query.isMainDrainagePlanned.toString() : this.$route.query.isMainDrainagePlanned;
              this.isServiced = typeof this.$route.query.isServiced === 'boolean' ? this.$route.query.isServiced.toString() : this.$route.query.isServiced;
              this.isClayZone = (typeof this.$route.query.isClayZone === 'boolean' && this.$route.query.isClayZone) || this.$route.query.isClayZone === 'true' ? ['true'] : null;
              this.emptyLand = typeof this.$route.query.emptyLand === 'boolean' ? this.$route.query.emptyLand.toString() : this.$route.query.emptyLand;

              const customLand = {
                width: this.landWidth,
                surface: this.landSurface,
                price: this.landPrice,
                isMainDrainagePlanned: this.isMainDrainagePlanned,
                isServiced: this.isServiced,
                isClayZone: this.isClayZone,
                emptyLand: this.emptyLand,
              };

              this.matchings = this.matchings.map((matching) => ({ ...matching, land: !matching.noLandMatched ? customLand : null }));

              if (this.$route.query.openLandChoice === 'true' || this.$route.query.openLandChoice === true) {
                this.openFilter('land');
                this.$router.push({
                  path: this.$router.currentRoute.path,
                  query: {
                    ...this.$route.query,
                    openLandChoice: undefined,
                    imagesType: this.$route.query.imagesType || this.imagesType,
                  },
                });
              }
            }

            this.selectedMatching = {
              ...this.matchings.find((matching) => matching.version.versionId === this.$route.query.versionId
              && ((matching.land && matching.land.landId === this.$route.query.landId) || (!matching.land || !matching.land.landId))),
            };

            if (this.selectedMatching && this.selectedMatching.land && this.selectedMatching.land.landId) {
              const selectedLand = await landApi.getOne(this.selectedMatching.land.landId);
              this.selectedMatching.land = selectedLand || this.selectedMatching.land;
            }
          }

          if (!this.$route.query.modelId && this.modelId) {
            this.modelId = null;
          }
          this.initPricingSystems();
          this.getVersionByModels();
          this.applyVersionFilters(true);
        }
      }
    },
  },
  computed: {
    availableLandsMsg() {
      let msg = 'Tous les terrains';
      if (this.allLands && this.allLands.length) {
        if (this.allLands.length === 1) {
          msg = 'Voir le terrain disponible';
        } else {
          msg = `Voir les ${this.allLands.length} terrains disponibles`;
        }
      }
      return msg;
    },
  },
  methods: {
    async handleCardIntersection(event) {
      if (!this.toPriceVersions[event.target.dataset.version]) {
        this.toPriceVersions[event.target.dataset.version] = true;
      }
      this.setPrices();
    },
    initPricingSystems() {
      this.pricedVersions = {};
      this.toPriceVersions = {};
    },
    share(matching) {
      this.$socialShares.show({ isFavorite: true, versionId: matching.version.versionId });
    },
    removePrivateModels() {
      if (this.versionsByModels && this.versionsByModels.length) {
        const versionsModelIds = this.versionsByModels.map((version) => version.modelId);
        this.models = this.allModelsFormatted.filter((model) => versionsModelIds.includes(model.name));
      }
    },
    async getAds() {
      try {
        const response = await Storyblok.get('cdn/stories/', {
          version: process.env.VUE_APP_STORYBLOK_ENV === 'development' ? 'draft' : 'published',
          starts_with: 'vignettes/',
          sort_by: 'created_at:desc',
          per_page: 100,
        });
        this.ads = response.data.stories.map((story) => ({
          name: story.content.name || null,
          agencyCode: story.content.agencyCode || null,
          backgroundColor: story.content.backgroundColor || null,
          description: story.content.description || null,
          finePrints: story.content.finePrints || null,
          image: story.content.image,
          isFlashNews: story.content.isFlashNews,
          link: story.content.link,
          isAd: true,
        }));
      } catch (error) {
        // L'absence de publicité ne doit pas gêner la navigation
        // eslint-disable-next-line no-empty
      }
    },
    async filterMatchings() {
      if ((this.$route.query.bathroomCount !== this.bathroomCount && (this.bathroomCount !== null || this.$route.query.bathroomCount !== undefined))
          || (this.$route.query.floorId !== this.floorId && (this.floorId !== null || this.$route.query.floorId !== undefined))
          || (this.$route.query.modelId !== this.modelId && (this.modelId !== null || this.$route.query.modelId !== undefined))
          || (this.$route.query.garage !== this.garage && (this.garage !== null || this.$route.query.garage !== undefined))
          || (this.$route.query.orderBy !== this.orderBy && (this.orderBy !== null || this.$route.query.orderBy !== undefined))
          || ((parseInt(this.$route.query.priceMin, 10) !== this.priceRange.start && (this.priceRange.start !== null || this.$route.query.priceMin !== undefined)) && (this.priceRange.start !== 0 || this.priceRange.end !== 600000))
          || ((parseInt(this.$route.query.priceMax, 10) !== this.priceRange.end && (this.priceRange.end !== null || this.$route.query.priceMax !== undefined)) && (this.priceRange.start !== 0 || this.priceRange.end !== 600000))
          || (this.$route.query.roomCount !== this.roomCount && (this.roomCount !== null || this.$route.query.roomCount !== undefined))
          || (this.$route.query.selectedRange !== this.selectedRange && (this.selectedRange !== null || this.$route.query.selectedRange !== undefined))
          || ((parseInt(this.$route.query.surfaceMin, 10) !== this.surfaceRange.start && (this.surfaceRange.start !== null || this.$route.query.surfaceMin !== undefined)) && (this.surfaceRange.start !== 0 || this.surfaceRange.end !== 300))
          || ((parseInt(this.$route.query.surfaceMax, 10) !== this.surfaceRange.end && (this.surfaceRange.end !== null || this.$route.query.surfaceMax !== undefined)) && (this.surfaceRange.start !== 0 || this.surfaceRange.end !== 300))
      ) {
        this.$router.push({
          query: {
            ...this.$route.query,
            bathroomCount: this.bathroomCount || undefined,
            floorId: this.floorId || undefined,
            modelId: this.modelId || undefined,
            orderBy: this.orderBy || undefined,
            priceMin: this.priceRange.start !== 0 || this.priceRange.end !== 600000 ? this.priceRange.start : undefined,
            priceMax: this.priceRange.start !== 0 || this.priceRange.end !== 600000 ? this.priceRange.end : undefined,
            roomCount: this.roomCount || undefined,
            selectedRange: this.selectedRange || this.ranges[0].name,
            surfaceMin: this.surfaceRange.start !== 0 || this.surfaceRange.end !== 300 ? this.surfaceRange.start : undefined,
            surfaceMax: this.surfaceRange.start !== 0 || this.surfaceRange.end !== 300 ? this.surfaceRange.end : undefined,
            garage: this.garage || undefined,
          },
        });
      }
    },
    async getAgency() {
      if (!this.$route.query.postalCodeInseeNumber) {
        this.agencyCode = null;
      } else {
        try {
          const agency = await agencyApi.getByPostalCode(this.$route.query.postalCodeInseeNumber);
          this.agencyCode = agency.code;
        } catch (err) {
          this.agencyCode = null;
        }
      }
    },
    async mapDragged(coordinates) {
      if (!this.isMapLoading) {
        try {
          if (coordinates && coordinates.latitude) {
            this.isMapLoading = true;
            this.nearPostalCodes = await postalCodeApi.getByPostalCode(
              null,
              40,
              coordinates.latitude,
              coordinates.longitude,
            );
            this.isMapLoading = false;
          }
        } catch (er) {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors de la récupération des codes postaux proches',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
      }
    },
    async landChoiceChange(choice) {
      this.isCustomLandApplied = false;
      this.landChoice = choice;
      if (choice !== 'custom-land') {
        this.isLoading = true;
        try {
          await this.$router.push({
            query: {
              ...this.$route.query,
              landChoice: choice,
              landWidth: undefined,
              landSurface: undefined,
              landPrice: undefined,
              isMainDrainagePlanned: undefined,
              isServiced: undefined,
              isClayZone: undefined,
            },
          });
        } catch (er) {
          if (er.name !== 'NavigationDuplicated') {
            throw er;
          }
        }

        this.landWidth = null;
        this.landSurface = null;
        this.landPrice = null;
        this.isMainDrainagePlanned = null;
        this.isServiced = null;
        this.isClayZone = null;

        if (this.landChoice === 'no-land') {
          this.landId = null;
          this.land = null;
        }
        try {
          if (this.landChoice === 'no-land') {
            await this.$router.push({
              query: {
                ...this.$route.query,
                emptyLand: true,
                landId: undefined,
              },
            });
            this.landId = null;
            this.land = null;
          } else if (this.$route.query.emptyLand !== this.emptyLand || this.landChoice === 'land') {
            await this.$router.push({
              query: {
                ...this.$route.query,
                emptyLand: undefined,
              },
            });
          }
        } catch (er) {
          if (er.name !== 'NavigationDuplicated') {
            throw er;
          }
        }
        this.initPricingSystems();
        await this.getMatchings();
        await this.resetVersionFilter();

        this.isLoading = false;
      } else {
        this.isMainDrainagePlanned = 'true';
        this.isServiced = 'true';
      }
    },
    async getData() {
      this.isLoading = true;

      // On récupère les données pour les filtres versions
      const publicCategoriesPromises = [];

      publicCategoriesPromises.push(this.getRanges());
      publicCategoriesPromises.push(this.getModels());
      publicCategoriesPromises.push(this.getRooms());
      publicCategoriesPromises.push(this.getFloors());

      await Promise.all(publicCategoriesPromises);

      this.landPrice = this.$route.query.landPrice ? Math.round(utils.formatCentToEuro(this.$route.query.landPrice)) : null;
      this.landSurface = this.$route.query.landSurface;
      this.landWidth = this.$route.query.landWidth;
      this.isMainDrainagePlanned = typeof this.$route.query.isMainDrainagePlanned === 'boolean' ? this.$route.query.isMainDrainagePlanned.toString() : this.$route.query.isMainDrainagePlanned;
      this.isServiced = typeof this.$route.query.isServiced === 'boolean' ? this.$route.query.isServiced.toString() : this.$route.query.isServiced;
      this.isClayZone = (typeof this.$route.query.isClayZone === 'boolean' && this.$route.query.isClayZone) || this.$route.query.isClayZone === 'true' ? ['true'] : null;
      this.emptyLand = typeof this.$route.query.emptyLand === 'boolean' ? this.$route.query.emptyLand.toString() : this.$route.query.emptyLand;

      const matchingsPromises = [];

      if (this.$route.query && this.$route.query.projectDraftId) {
        matchingsPromises.push(this.getProjectDraft());
      }

      if (this.$route.query.postalCodeInseeNumber) {
        // il faut au moins le code postal du customer pour aller chercher des matchings
        matchingsPromises.push(this.getMatchings());
      }

      matchingsPromises.push(this.getPostalCode());
      matchingsPromises.push(this.getInterestRate());

      await Promise.all(matchingsPromises);

      // quand on a toutes les données, on peut set le filtre des terrains
      let landChoice = '';
      if (this.$route.query.landChoice) {
        landChoice = this.$route.query.landChoice;
      } else if (this.$route.query.emptyLand) {
        landChoice = 'no-land';
      } else if (this.$route.query.landWidth) {
        landChoice = 'custom-land';
      } else {
        landChoice = 'land';
      }
      this.landChoice = landChoice;

      if (this.landChoice === 'no-land') {
        this.isLandOpen = false;
      }

      if (this.landChoice === 'custom-land') {
        this.isLandOpen = true;
      }

      this.initPricingSystems();

      // On set les filtres des versions (les data à partir des queries)
      this.isLoading = false;

      this.setVersionFilters();
      this.applyVersionFilters(false);
    },
    async getProjectDraft() {
      try {
        if (this.$route.params.projectDraftId) {
          this.projectDraft = await projectDraftApi.getPublicById(this.$route.params.projectDraftId);
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération de l\'avant-projet',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getPostalCode() {
      try {
        let fullPostalCode = null;
        // on récupère le code postal avec le CP en query, ou directement un PC+inseeNumber
        if (this.$route.query.postalCodeInseeNumber && this.$route.query.postalCodeInseeNumber.length === 5) {
          const matchingPostalCodes = await postalCodeApi.getAll(this.$route.query.postalCodeInseeNumber);
          fullPostalCode = matchingPostalCodes && matchingPostalCodes.length ? matchingPostalCodes[0] : null;
        } else if (this.$route.query.postalCodeInseeNumber && this.$route.query.postalCodeInseeNumber.length === 11) {
          const matchingPostalCode = await postalCodeApi.getOne(this.$route.query.postalCodeInseeNumber);
          fullPostalCode = matchingPostalCode;
        }

        this.postalCode = fullPostalCode;
        // Si on a une code postal, on l'entre dans l'input de changement de code postal
        if (this.postalCode) {
          this.selectedPostalCode = `${this.postalCode.city} - ${this.postalCode.postalCodeInseeNumber.split('-')[0]}`;
          this.selectedPostalCodeInseeNumber = this.postalCode.postalCodeInseeNumber;

          await this.getNearPostalCodes();
        } else {
          this.selectedPostalCode = null;
          this.selectedPostalCodeInseeNumber = null;
        }
        await this.updateLands();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des codes postaux',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getNearPostalCodes() {
      try {
        if (this.postalCode) {
          this.nearPostalCodes = await postalCodeApi.getByPostalCode(this.postalCode.postalCodeInseeNumber, 40);
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des codes postaux proches',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async nextPostalCode() {
      const nextPostalCode = this.nearPostalCodes.find((postalCode) => postalCode.postalCodeInseeNumber !== this.postalCode.postalCodeInseeNumber);
      if (nextPostalCode && nextPostalCode.postalCodeInseeNumber) {
        this.selectedPostalCode = nextPostalCode.city;
        this.selectedPostalCodeInseeNumber = nextPostalCode.postalCodeInseeNumber;
        await this.applyPostalCode(true, true);
      }
    },
    async displayMap() {
      this.openFilter('postalCode');
      this.citySearchChoice = 'location';
    },
    async updateLands() {
      // On met à jour la liste des terrains, en fonction du code postal + insee number
      if (this.postalCode) {
        this.allLands = await landApi.getAll(this.selectedPostalCodeInseeNumber);

        this.availableLands = this.allLands.reduce((a, pcLand) => {
          let matchIndex = null;
          if (a && a.length) {
            matchIndex = a.findIndex((land) => (land.allotment && pcLand.allotment && land.allotment.allotmentId === pcLand.allotment.allotmentId) || ((!pcLand.allotment || !pcLand.allotment.allotmentId) && (!land.allotment || !land.allotment.allotmentId)));
            if ((matchIndex || matchIndex === 0) && matchIndex !== -1) {
              if (!a[matchIndex].lands) {
                // eslint-disable-next-line no-param-reassign
                a[matchIndex].lands = [];
              }
              a[matchIndex].lands.push(pcLand);
              return a;
            }
          }
          a.push({
            allotment: pcLand && pcLand.allotment && pcLand.allotment.allotmentId ? pcLand.allotment : null,
            company: pcLand && pcLand.company && pcLand.company.companyId ? pcLand.company : null,
            lands: [pcLand],
          });
          return a;
        }, []);

        // On ouvre le dropdown des terrains si on a 0 terrain
        if (this.availableLands.length === 0) {
          this.isLandOpen = true;
        }
      } else {
        this.availableLands = null;
      }

      if (this.$route.query && this.$route.query.landId && this.$route.query.landId.length) {
        if (this.allLands && this.allLands.length) {
          this.landId = this.$route.query.landId;
          this.land = this.allLands.find((selectedLand) => selectedLand.landId === this.$route.query.landId);
        } else {
          // Si on ne trouve pas de terrain, on reset le champs
          this.land = null;
          this.landId = null;
        }
      }
    },
    resetSelectedPostalCode() {
      this.selectedPostalCode = null;
      this.selectedPostalCodeInseeNumber = null;
    },
    setSelectedPostalCode(value) {
      if (value) {
        this.selectedPostalCode = value.label;
        this.selectedPostalCodeInseeNumber = value.name;
      }
    },
    async mapClicked(postalCode) {
      this.selectedPostalCode = postalCode.city;
      this.selectedPostalCodeInseeNumber = postalCode.postalCodeInseeNumber;

      await this.applyPostalCode(false);
    },
    async applyPostalCode(resetMap, closeLandFilterAfter = false) {
      this.isLoading = true;

      if (resetMap) {
        this.showMap = false;
      }

      this.isLandOpen = closeLandFilterAfter ? false : this.isLandOpen;

      if (this.selectedPostalCodeInseeNumber !== this.$route.query.postalCodeInseeNumber) {
        try {
          this.landId = null;
          this.land = null;
          await this.$router.push({
            query: {
              ...this.$route.query,
              postalCodeInseeNumber: this.selectedPostalCodeInseeNumber ? this.selectedPostalCodeInseeNumber : undefined,
              landId: undefined,
            },
          });
        } catch (er) {
          if (er.name !== 'NavigationDuplicated') {
            throw er;
          }
        }
        this.initPricingSystems();
        await this.getPostalCode();

        if (this.$route.query.postalCodeInseeNumber) {
          // il faut au moins le code postal du customer pour aller chercher des matchings
          await this.getMatchings();
          this.initPricingSystems();
          if (!this.matchings || !this.matchings.length) {
            this.isLandOpen = true;
            await this.landChoiceChange('land');
          } else {
            await this.resetVersionFilter();
          }
        }
      }
      this.isPostalCodeOpen = false;

      if (resetMap) {
        this.showMap = true;
      }

      this.isLoading = false;
    },
    async getMatchings() {
      try {
        const isClayZone = Array.isArray(this.$route.query.isClayZone) && this.$route.query.isClayZone.length ? this.$route.query.isClayZone[0] : this.$route.query.isClayZone;

        if (this.$route.query.postalCodeInseeNumber) {
          const matchings = await configurationApi.getAll(
            this.$route.query.postalCodeInseeNumber,
            this.$route.query.emptyLand,
            this.$route.query.landWidth,
            this.$route.query.landSurface,
            this.$route.query.landPrice,
            this.$route.query.isMainDrainagePlanned,
            this.$route.query.isServiced,
            isClayZone,
            this.$route.query.landId,
          );

          const sortedMatchings = matchings.map((matching) => ({
            ...matching,
            versionMedias: matching.version.versionMedias && matching.version.versionMedias.length ? matching.version.versionMedias.sort((a, b) => a.createdAt.localeCompare(b.createdAt)) : [],
          })).sort((a, b) => a.version.pricePrimary - b.version.pricePrimary); // Pré-tri de base des versions par ordre croissant

          this.matchings = sortedMatchings;

          this.filteredMatchings = sortedMatchings;
          this.getVersionByModels();

          // Si on fait une modification, alors on ouvre la modale des terrains
          if (this.$route.name.includes('edit')) {
            this.selectedMatching = null;
            if (this.matchings && this.matchings.length) {
              this.selectedMatching = {
                ...this.matchings.find((matching) => matching.version.versionId === this.$route.query.versionId
                && (
                  (matching.land && matching.land.landId === this.$route.query.landId) || (!matching.land || !matching.land.landId))),
              };

              if (this.selectedMatching && this.selectedMatching.land && this.selectedMatching.land.landId) {
                const selectedLand = await landApi.getOne(this.selectedMatching.land.landId);
                this.selectedMatching.land = selectedLand || this.selectedMatching.land;
              }

              // On stocké séparément la config de départ pour le retour
              if (!this.previousConfiguration) {
                if (this.$route.query.openLandChoice === 'true' || this.$route.query.openLandChoice === true) {
                  this.isLandOpen = true;
                }
                this.previousConfiguration = this.selectedMatching;
              }
              if (!this.previousCustomerLand) {
                this.previousCustomerLand = {
                  width: this.$route.query.landWidth,
                  surface: this.$route.query.landSurface,
                  price: this.$route.query.landPrice,
                  isMainDrainagePlanned: this.$route.query.isMainDrainagePlanned,
                  isServiced: this.$route.query.isServiced,
                  isClayZone: this.$route.query.isClayZone,
                  postalCodeInseeNumber: this.$route.query.postalCodeInseeNumber,
                };
              }
              if (!this.previousPostalCodeInseeNumber) {
                this.previousPostalCodeInseeNumber = this.selectedPostalCodeInseeNumber;
              }
              if (!this.previousCustomerId) {
                this.previousCustomerId = this.$route.query.customerId;
              }
              this.isPreviousMatchingInit = true;
            }
          }
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des matchings terrains / maisons',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    goToAd(route) {
      if (route) {
        let url;
        try {
          url = new URL(route);
        } catch (err) {
          url = false;
        }

        if (url) {
          window.open(url.href, '_blank');
        } else {
          const routeData = this.$router.resolve({ name: route });
          window.open(routeData.href, '_blank');
        }
      }
    },
    async getVersionByModels() {
      if (this.filteredMatchings && this.filteredMatchings) {
        const selectedRange = this.$route.query.selectedRange || this.ranges[0].name;
        const modelsNotEmpty = this.allModels.filter((model) => {
          if (model.modelId) {
            return this.filteredMatchings.find((matching) => matching.version.model.modelId === model.modelId && model.range.rangeId === selectedRange);
          }
          return false;
        });

        if (modelsNotEmpty && modelsNotEmpty.length) {
          this.versionsByModels = modelsNotEmpty.map((model) => {
            const matchingsByModelLand = this.filteredMatchings.filter((matching) => matching && matching.version.model.modelId === model.modelId && ((matching.land && matching.land.landId) || (matching.customerLand && matching.customerLand.width)));

            let sortedMatchingsLand = [];
            if (matchingsByModelLand && matchingsByModelLand.length) {
              sortedMatchingsLand = matchingsByModelLand.sort((a, b) => (a && b ? ((a.version.width ? a.version.width : 0) * (a.version.depth ? a.version.depth : 0) - (b.version.width ? b.version.width : 0) * (b.version.depth ? b.version.depth : 0)) : 0));
            }

            const matchingsByModelNoLand = this.filteredMatchings.filter((matching) => matching && matching.version.model.modelId === model.modelId && ((!matching.land || !matching.land.landId) && (!matching.customerLand || !matching.customerLand.width)));

            let sortedMatchingsNoLand = [];
            if (matchingsByModelNoLand && matchingsByModelNoLand.length) {
              sortedMatchingsNoLand = matchingsByModelNoLand.sort((a, b) => (a && b ? ((a.version.width ? a.version.width : 0) * (a.version.depth ? a.version.depth : 0) - (b.version.width ? b.version.width : 0) * (b.version.depth ? b.version.depth : 0)) : 0));
            }

            const allMatchings = sortedMatchingsLand.concat(sortedMatchingsNoLand).sort((a, b) => a.version.pricePrimary - b.version.pricePrimary);

            return {
              modelId: model.modelId,
              name: model.name,
              image: model.image,
              displayedVersion: allMatchings[0].version.versionId,
              versionCount: allMatchings && allMatchings.length ? allMatchings.length : 0,
              pricePrimary: allMatchings[0].version.pricePrimary,
            };
          }).sort((a, b) => a.pricePrimary - b.pricePrimary);
          this.removePrivateModels();

          await this.addAds();
        }
      }
    },
    async addAds() {
      const copy = this.versionsByModels.filter((model) => !model.isAd && !model.isFlashNews);
      await this.getAgency();

      // Ajout des publicités
      let addedAds = 0;
      const filterAds = this.ads.filter((el) => el.agencyCode === this.agencyCode || el.agencyCode === null);

      // On insère les pubs dans les modèles
      this.versionsByModels.forEach((_, index) => {
        if (index % 3 === 0 && index !== 0 && addedAds < filterAds.length) {
          copy.splice(index + addedAds, 0, filterAds[addedAds]);
          addedAds += 1;
        }
      });
      this.versionsByModels = copy;
    },
    async setPrices() {
      if (this.matchings && this.matchings.length) {
        this.priceLoaded = false;
        let matchingCopy = this.matchings.slice();

        // On tri les matchings par pricePrimary croissant ou l'inverse afin d'aller chercher le vrai prix des versions demandées en priorité
        matchingCopy = this.$route.query.orderBy === '-price' ? matchingCopy.sort((a, b) => b.version.pricePrimary - a.version.pricePrimary) : matchingCopy.sort((a, b) => a.version.pricePrimary - b.version.pricePrimary);

        // On filtre par les versions qui on besoin d un prix
        matchingCopy = matchingCopy.filter((matchingElement) => this.toPriceVersions[matchingElement.version.versionId] && !this.pricedVersions[matchingElement.version.versionId]);

        const chunks = [];

        const chunkSize = 12;
        for (let i = 0; i < matchingCopy.length; i += chunkSize) {
          const chunk = matchingCopy.slice(i, i + chunkSize);
          chunks.push(chunk);
        }

        /* eslint-disable no-await-in-loop */
        for (let i = 0; i < chunks.length; i += 1) {
          const chunkPromises = chunks[i].map(async (matching) => {
            let { price, priceWithoutAdditionalFees } = await this.getPrice(matching.version.versionId, matching.land ? matching.land.landId : null);

            // Si la version n'est pas compatible avec le terrain, on lui ajoute quand même le prix du terrain pour qu'elle soit à peu près bien positionnée dans la liste
            if (matching.noLandMatched && (this.landPrice || this.land || matching.land)) {
              if (this.land) {
                price += this.land.price;
                priceWithoutAdditionalFees += this.land.price;
                // Récupération du prix par mois
              } else if (!this.land && this.landChoice !== 'custom-land') {
                price += matching.land.price;
                priceWithoutAdditionalFees += matching.land.price;
              }
            } else if (matching.noLandMatched && !(this.landPrice || this.land || matching.land) && this.allLands && this.allLands.length) {
              const landPrice = this.allLands.reduce((prev, curr) => (prev.price < curr.price ? prev : curr)).price;
              price += landPrice;
              priceWithoutAdditionalFees += landPrice;
            }

            const matchIndexMatchings = this.matchings.findIndex((matchingFilter) => matchingFilter && matching && matchingFilter.version.versionId === matching.version.versionId);
            if (matchIndexMatchings !== -1) {
              this.$set(this.matchings, matchIndexMatchings, {
                ...matching,
                price: price || null,
                priceWithoutAdditionalFees: priceWithoutAdditionalFees || null,
              });
            }

            const matchIndex = this.filteredMatchings.findIndex((matchingFilter) => matchingFilter && matching && matchingFilter.version.versionId === matching.version.versionId);
            if (matchIndex === 0 || matchIndex) {
              this.$set(this.filteredMatchings, matchIndex, {
                ...matching,
                price: price || null,
                priceWithoutAdditionalFees: priceWithoutAdditionalFees || null,
              });
            }

            // on met à jour le modèle
            if (this.versionsByModels && this.versionsByModels.length) {
              const modelIndex = this.versionsByModels.findIndex((model) => model && matching && model.displayedVersion === matching.version.versionId);
              if (modelIndex !== -1 && this.versionsByModels[modelIndex]) {
                this.versionsByModels[modelIndex].price = price || null;
                this.versionsByModels[modelIndex].priceWithoutAdditionalFees = priceWithoutAdditionalFees || null;
              }
            }

            // on met à jour la config sélectionnée
            if (this.$route.name.includes('edit') && this.selectedMatching && this.selectedMatching.version && matching && matching.version && matching.version.versionId === this.selectedMatching.version.versionId) {
              this.selectedMatching.price = price || null;
              this.selectedMatching.priceWithoutAdditionalFees = priceWithoutAdditionalFees || null;
            }

            // On met à jour l objet des prix à jour
            this.pricedVersions[matching.version?.versionId] = true;

            return { price, priceWithoutAdditionalFees };
          });
          await Promise.all(chunkPromises);
        }
      }

      return true;
    },
    async getPrice(versionId, landId) {
      let price = null;
      let priceWithoutAdditionalFees = null;
      try {
        ({ price, priceWithoutAdditionalFees } = await configurationApi.getPrice(
          this.$route.query.postalCodeInseeNumber,
          this.$route.query.emptyLand,
          this.$route.query.landWidth,
          this.$route.query.landSurface,
          this.$route.query.landPrice,
          (this.$route.query.isMainDrainagePlanned === 'true' || this.$route.query.isMainDrainagePlanned === true),
          (this.$route.query.isServiced === 'true' || this.$route.query.isServiced === true),
          (this.$route.query.isClayZone === 'true' || this.$route.query.isClayZone === true),
          versionId,
          landId,
        ));
      } catch (er) {
        // On n'affiche pas le message d'erreur si on a un problème avec le prix
        // On l'enlève de la liste
        if (this.matchings && this.matchings.length) {
          const index = this.matchings.findIndex((matching) => matching && matching.version.versionId === versionId);
          this.matchings.splice(index, 1);
        }
        if (this.filteredMatchings && this.filteredMatchings.length) {
          const index = this.filteredMatchings.findIndex((matching) => matching && matching.version.versionId === versionId);
          this.filteredMatchings.splice(index, 1);
        }
      }
      return { price, priceWithoutAdditionalFees };
    },
    async getRanges() {
      try {
        const ranges = await rangeApi.getAll();
        this.ranges = utils.formatOptions(
          ranges,
          (option) => option.rangeId,
          (option) => option.name,
        ).filter((range) => range.label.toUpperCase() !== 'PRIME');
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des gammes',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getModels() {
      try {
        const models = await modelApi.getAll(null, null, null, null, true);
        this.allModels = models && models.data ? models.data : null;
        this.allModelsFormatted = utils.formatOptions(
          models.data,
          (option) => option.modelId,
          (option) => option.name,
          'choose',
        );
        this.allModelsFormatted = this.allModelsFormatted.sort((a, b) => a.label.localeCompare(b.label));
        this.models = this.allModelsFormatted;
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des modèles',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getRooms() {
      try {
        this.rooms = await roomApi.getAll();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des pièces',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getFloors() {
      try {
        const floors = await floorApi.getAll();

        // On remplace "rez-de-chaussée", par "plein pied", pour faire varier les conditionsdu filtre
        if (floors && floors.length) {
          const index = floors.findIndex((floor) => floor.floorId === 'd0e1fedc-c9a8-4f59-8f30-66d897b0a951');
          if (index !== -1) {
            floors[index] = {
              ...floors[index],
              name: 'Plain-pied',
            };
          }
          this.floors = utils.formatOptions(
            floors,
            (option) => option.floorId,
            (option) => option.name,
          );
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des niveaux',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    openFilter(filterName) {
      if (filterName === 'postalCode') {
        this.isPostalCodeOpen = !this.isPostalCodeOpen;
        this.isLandOpen = false;
        this.isVersionOpen = false;
      } else if (filterName === 'land') {
        this.isPostalCodeOpen = false;
        this.isLandOpen = !this.isLandOpen;
        this.isVersionOpen = false;
        if (!this.isCustomLandApplied && this.landChoice === 'custom-land') {
          this.landChoiceChange('no-land');
        }
      } else if (filterName === 'version') {
        this.isPostalCodeOpen = false;
        this.isLandOpen = false;
        this.isVersionOpen = !this.isVersionOpen;

        if (!this.isVersionOpen) {
          this.setVersionFilters();
        }
      }
    },
    closeFilter() {
      if (this.isLandOpen && !this.isCustomLandApplied && this.landChoice !== 'land') {
        this.landChoiceChange('no-land');
      }

      this.isPostalCodeOpen = false;
      this.isLandOpen = false;
      this.isVersionOpen = false;

      if (!this.isVersionOpen) {
        this.setVersionFilters();
      }
    },
    downloadPdf(allotment) {
      // télécharger le pdf du lotissement
      window.open(allotment.presentationPdf, '_blank');
    },
    async landChange(land) {
      this.isLoading = true;
      if (land && this.$route.query.landId !== land.landId) {
        try {
          this.landWidth = null;
          this.landSurface = null;
          this.landPrice = null;
          this.isMainDrainagePlanned = null;
          this.isServiced = null;
          this.isClayZone = null;

          await this.$router.push({
            query: {
              ...this.$route.query,
              landId: land && land.landId ? land.landId : undefined,
            },
          });
        } catch (er) {
          if (er.name !== 'NavigationDuplicated') {
            throw er;
          }
        }
        this.land = this.allLands.find((selectedLand) => selectedLand.landId === land.landId);

        if (this.$route.query.postalCodeInseeNumber) {
          this.initPricingSystems();
          await this.getMatchings();
          await this.resetVersionFilter();
        }
      }
      this.isLoading = false;

      this.isLandOpen = false;
    },
    async applyCustomLand() {
      this.isCustomLandApplied = true;
      // Si un des champs a changé
      if (
        (this.landWidth !== this.$route.query.landWidth)
        || (this.landSurface !== this.$route.query.landSurface)
        || (this.landPrice !== this.$route.query.landPrice)
        || (this.isMainDrainagePlanned !== this.$route.query.isMainDrainagePlanned)
        || (this.isServiced !== this.$route.query.isServiced)
        || (this.isClayZone !== this.$route.query.isClayZone)
      ) {
        try {
          this.land = null;
          await this.$router.push({
            query: {
              ...this.$route.query,
              landChoice: 'custom-land',
              landId: undefined,
              landWidth: this.landWidth,
              landSurface: this.landSurface,
              emptyLand: undefined,
              landPrice: (this.landPrice || this.landPrice === 0) ? utils.formatEuroToCent(this.landPrice) : undefined,
              isMainDrainagePlanned: this.isMainDrainagePlanned ? this.isMainDrainagePlanned : undefined,
              isServiced: this.isServiced ? this.isServiced : undefined,
              isClayZone: this.isClayZone ? this.isClayZone : undefined,
            },
          });
        } catch (er) {
          if (er.name !== 'NavigationDuplicated') {
            throw er;
          }
        }

        this.isLoading = true;
        this.initPricingSystems();
        await this.getMatchings();
        await this.resetVersionFilter();

        this.isLandOpen = false;
        this.isLoading = false;
      }
    },
    setVersionFilters() {
      if (!this.surfaceRange) {
        this.surfaceRange = {
          start: 0,
          end: 300,
        };
      }
      if (!this.priceRange) {
        this.priceRange = {
          start: 0,
          end: 600000,
        };
      }

      // set modelId filter from querie
      this.modelId = this.$route.query.modelId;
    },
    async applyVersionFilters(applyLoading) {
      // Apply queries
      let filtersEmpty = true;

      this.bathroomCount = this.$route.query.bathroomCount || null;
      this.floorId = this.$route.query.floorId || null;
      this.modelId = this.$route.query.modelId || null;
      this.orderBy = this.$route.query.orderBy || null;
      this.priceRange.start = parseInt(this.$route.query.priceMin, 10) || 0;
      this.priceRange.end = parseInt(this.$route.query.priceMax, 10) || 600000;
      this.roomCount = this.$route.query.roomCount || null;
      this.garage = this.$route.query.garage || null;
      this.selectedRange = this.$route.query.selectedRange || this.ranges[0].name;
      this.surfaceRange.start = parseInt(this.$route.query.surfaceMin, 10) || 0;
      this.surfaceRange.end = parseInt(this.$route.query.surfaceMax, 10) || 300;

      // application des filtres sur la liste des matchings, et dans la route
      if (applyLoading) {
        this.isLoading = true;
      }

      let matchings = this.matchings && this.matchings.length ? this.matchings.slice() : [];

      // trier par prix croissant de base et selon si le prix final est chargé
      if (this.orderBy === '-price') {
        filtersEmpty = false;
        matchings = this.priceLoaded ? matchings.sort((a, b) => b.price - a.price) : matchings.sort((a, b) => b.version.pricePrimary - a.version.pricePrimary);
      } else {
        if (this.orderBy === 'price') filtersEmpty = false;
        matchings = this.priceLoaded ? matchings.sort((a, b) => a.price - b.price) : matchings.sort((a, b) => a.version.pricePrimary - b.version.pricePrimary);
      }

      if (this.priceRange && (this.priceRange.start !== 0 || this.priceRange.end !== 600000)) {
        filtersEmpty = false;
        const startPrice = this.priceRange.start;
        const endPrice = this.priceRange.end;

        // Pour le tri par prix, on regarde si on a choisi un terrain ou non afin de prendre en compte soit le prix sans frais annexes soit le prix avec frais annexes
        // Puis on regarde si les prix sont tous arrivés afin de savoir si on applique le filtre avec le pré-prix ou avec le prix total
        if ((this.landChoice === 'custom-land' && this.landPrice) || (this.landChoice === 'land' && this.allLands.length)) {
          const landPrice = this.landChoice === 'custom-land' ? utils.formatEuroToCent(this.landPrice) : this.allLands.reduce((prev, curr) => (prev.price < curr.price ? prev : curr)).price;

          matchings = matchings.filter((m) => {
            if (this.priceLoaded) {
              return m.price > utils.formatEuroToCent(startPrice, false) && m.price < utils.formatEuroToCent(endPrice, false);
            }
            // Si le vrai prix n'est pas encore chargé, on va chercher un prix approximé
            const price = utils.estimatePrice(m.version.pricePrimary, landPrice);

            return price > utils.formatEuroToCent(startPrice, false) && price < utils.formatEuroToCent(endPrice, false);
          });
        } else if (this.landChoice === 'no-land' || (this.landChoice === 'custom-land' && !this.landPrice) || (this.landChoice === 'land' && !this.allLands.length)) {
          matchings = this.priceLoaded
            ? matchings.filter((m) => m.priceWithoutAdditionalFees > utils.formatEuroToCent(startPrice, false) && m.priceWithoutAdditionalFees < utils.formatEuroToCent(endPrice, false))
            : matchings.filter((m) => utils.estimatePrice(m.version.pricePrimary) > utils.formatEuroToCent(startPrice, false) && utils.estimatePrice(m.version.pricePrimary) < utils.formatEuroToCent(endPrice, false));
        }
      }

      if (this.surfaceRange && (this.surfaceRange.start !== 0 || this.surfaceRange.end !== 300)) {
        filtersEmpty = false;
        matchings = matchings.filter((matching) => matching.totalSurfaceHabitable > this.surfaceRange.start && matching.totalSurfaceHabitable < this.surfaceRange.end);
      }

      if (this.floorId && this.floorId.length) {
        filtersEmpty = false;
        matchings = matchings.filter(
          (matching) => {
            if (matching.version && matching.version.surfaces && matching.version.surfaces.length) {
              // Si c'est le rez-de-chaussée, ça soit être le seul niveau (sauf si c'est des combles aménageables)
              if (this.floorId === 'd0e1fedc-c9a8-4f59-8f30-66d897b0a951') {
                const surfaces = matching.version.surfaces.filter((surface) => surface.floorId !== this.floorId && surface.floorId !== 'c166aea2-c111-4ed6-97bf-0ec3697c9cf2');
                return !surfaces || surfaces.length === 0;
              }
              const surfaces = matching.version.surfaces.filter((surface) => surface.floorId === this.floorId);
              return surfaces && surfaces.length;
            }
            return false;
          },
        );
      }

      if (this.roomCount && this.roomCount.length) {
        filtersEmpty = false;
        matchings = matchings.filter(
          (matching) => {
            if (matching.version && matching.version.surfaces && matching.version.surfaces.length) {
              const surfaces = matching.version.surfaces.filter((surface) => surface.room.name.includes('Chambre') || surface.room.name.includes('Suite parentale'));
              return surfaces && surfaces.length === parseInt(this.roomCount, 10);
            }
            return false;
          },
        );
      }

      if (this.bathroomCount && this.bathroomCount.length) {
        filtersEmpty = false;
        matchings = matchings.filter(
          (matching) => {
            if (matching.version && matching.version.surfaces && matching.version.surfaces.length) {
              const surfaces = matching.version.surfaces.filter((surface) => surface.room.name.includes('Salle de bain') || surface.room.name.includes('Salle d’eau'));
              return surfaces && surfaces.length === parseInt(this.bathroomCount, 10);
            }
            return false;
          },
        );
      }

      if (this.modelId && this.modelId.length) {
        filtersEmpty = false;
        this.imagesType = this.$route.query.imagesType || 'blueprint';
        matchings = matchings.filter(
          (matching) => {
            if (matching && matching.version && matching.version.model && matching.version.model.modelId) {
              return matching.version.model.modelId === this.modelId;
            }
            return false;
          },
        );
      }

      if (this.garage) {
        filtersEmpty = false;
        const withGarage = (surface) => surface.room.roomId === '0e75df63-e0d4-4983-b7c4-b253f9104903' || surface.room.roomId === '3f7a7eae-7343-499d-994a-8871b8508b4f' || surface.room.roomId === '0682b316-6b8f-4646-909b-6d51aa86ed80';
        const withoutGarage = (surface) => surface.room.roomId !== '0e75df63-e0d4-4983-b7c4-b253f9104903' && surface.room.roomId !== '3f7a7eae-7343-499d-994a-8871b8508b4f' && surface.room.roomId !== '0682b316-6b8f-4646-909b-6d51aa86ed80';
        if (this.garage === 'withGarage') {
          matchings = matchings.filter(
            (matching) => matching.version.surfaces.some(withGarage),
          );
        } else {
          matchings = matchings.filter(
            (matching) => matching.version.surfaces.every(withoutGarage),
          );
        }
      }

      if (this.selectedRange && this.selectedRange.length && !filtersEmpty) {
        matchings = matchings.filter(
          (matching) => {
            if (matching.version && matching.version.range && matching.version.range.rangeId) {
              return this.selectedRange === matching.version.range.rangeId;
            }
            return false;
          },
        );
      }

      this.filteredMatchings = matchings.slice();

      this.matchingFavorite = ls('MATCHING_FAVORITE') || [];
      if (this.filteredMatchings.length > 0 && this.matchingFavorite.length > 0) {
        const favoriteFiltered = this.filteredMatchings.filter((m) => this.matchingFavorite.find((m2) => m.version.versionId === m2));
        const notFavorite = this.filteredMatchings.filter((m) => !this.matchingFavorite.find((m2) => m.version.versionId === m2));
        if (this.orderBy === 'favorite') {
          this.filteredMatchings = [...favoriteFiltered];
          filtersEmpty = false;
        } else {
          this.filteredMatchings = [...favoriteFiltered, ...notFavorite];
        }
      }

      if (filtersEmpty) {
        this.viewType = 'model';
      } else {
        this.viewType = 'version';
        if (!this.modelId) {
          this.imagesType = this.$route.query.imagesType || 'house';
        }
      }

      if (applyLoading) {
        this.isLoading = false;
      }

      this.isVersionOpen = false;
    },
    async resetVersionFilter() {
      // update queries
      this.isLoading = true;
      this.filteredMatchings = this.matchings;

      this.orderBy = null;
      this.priceRange = {
        start: 0,
        end: 600000,
      };
      this.surfaceRange = {
        start: 0,
        end: 300,
      };
      this.floorId = null;
      this.roomCount = null;
      this.bathroomCount = null;
      this.modelId = null;
      this.garage = null;

      try {
        await this.$router.replace({
          query: {
            ...this.$route.query,
            bathroomCount: undefined,
            floorId: undefined,
            modelId: undefined,
            orderBy: undefined,
            priceMin: undefined,
            priceMax: undefined,
            roomCount: undefined,
            surfaceMin: undefined,
            surfaceMax: undefined,
            garage: undefined,
          },
        });
      } catch (er) {
        if (er.name !== 'NavigationDuplicated') {
          throw er;
        }
      }

      this.isVersionOpen = false;
      await this.applyVersionFilters();

      this.viewType = 'model';

      this.isLoading = false;
    },
    async goBackToModels() {
      // keep queries
      this.isLoading = true;
      this.filteredMatchings = this.matchings;

      this.modelId = null;

      this.$router.replace({
        query: {
          ...this.$route.query,
          bathroomCount: undefined,
          floorId: undefined,
          modelId: undefined,
          orderBy: undefined,
          priceMin: undefined,
          priceMax: undefined,
          roomCount: undefined,
          surfaceMin: undefined,
          surfaceMax: undefined,
          imagesType: undefined,
          garage: undefined,
        },
      });

      this.isVersionOpen = false;
      this.initPricingSystems();
      await this.applyVersionFilters();
      await this.getVersionByModels();

      this.viewType = 'model';

      this.isLoading = false;
    },
    removeFilter(key) {
      // Update all filters
      switch (key) {
        case 'surfaceRange':
          this.surfaceRange = {
            start: 0,
            end: 300,
          };
          this.$router.push({
            query: {
              ...this.$route.query,
              surfaceMin: undefined,
              surfaceMax: undefined,
            },
          });
          break;
        case 'priceRange':
          this.priceRange = {
            start: 0,
            end: 600000,
          };
          this.$router.push({
            query: {
              ...this.$route.query,
              priceMin: undefined,
              priceMax: undefined,
            },
          });
          break;
        default:
          this.$router.push({
            query: {
              ...this.$route.query,
              [key]: undefined,
            },
          });
          break;
      }

      this.initPricingSystems();
      this.applyVersionFilters(true);
      this.getVersionByModels();
    },
    async selectModel(model) {
      this.viewType = 'version';
      this.modelId = model.modelId;

      try {
        await this.$router.push(
          {
            params: {
              ...this.$route.params,
            },
            query: {
              ...this.$route.query,
              modelId: model.modelId,
            },
          },
        );
      } catch (er) {
        if (er.name !== 'NavigationDuplicated') {
          throw er;
        }
      }

      this.applyVersionFilters(true);
    },
    goToConfiguration(matching) {
      let isClayZone = null;
      if (Array.isArray(this.$route.query.isClayZone)) {
        isClayZone = this.$route.query.isClayZone.length ? this.$route.query.isClayZone[0] : undefined;
      } else {
        isClayZone = this.$route.query.isClayZone;
      }

      // Définition du postalCode
      let postalCode;

      if (this.$route.query.postalCodeInseeNumber) {
        postalCode = this.$route.query.postalCodeInseeNumber;
      }

      if (matching.land && matching.land.address && matching.land.address.postalCodeInseeNumber) {
        postalCode = matching.land.address.postalCodeInseeNumber;
      }

      if (matching.land && matching.land.postalCodeInseeNumber) {
        postalCode = matching.land.postalCodeInseeNumber;
      }

      if (!this.$route.name.includes('edit')) {
        const queries = {
          // id terrain
          ...(matching.land && matching.land.landId && { landId: matching.land.landId }),
          // id version
          ...(matching.version && matching.version.versionId && { versionId: matching.version.versionId }),
          // les params pour le terrain custom
          ...(this.$route.query.landWidth && matching.land && { landWidth: this.$route.query.landWidth }),
          ...(this.$route.query.landSurface && matching.land && { landSurface: this.$route.query.landSurface }),
          ...(this.$route.query.landPrice && matching.land && { landPrice: this.$route.query.landPrice }),
          ...(this.$route.query.isMainDrainagePlanned && matching.land && { isMainDrainagePlanned: this.$route.query.isMainDrainagePlanned }),
          ...(this.$route.query.isServiced && matching.land && { isServiced: this.$route.query.isServiced }),
          ...(this.$route.query.isClayZone && matching.land && { isClayZone }),
          // id avant-projet
          ...(this.$route.query.projectDraftId && { projectDraftId: this.$route.query.projectDraftId }),
          // id client
          ...(this.$route.query.customerId && { customerId: this.$route.query.customerId }),
          // id code postal
          ...({ postalCodeInseeNumber: postalCode }),
          //
          ...(this.$route.query.emptyLand && { emptyLand: this.$route.query.emptyLand }),
          // Paramètres de recherche
          ...(this.$route.query.modelId && { modelId: this.$route.query.modelId }),
          ...(this.$route.query.orderBy && { orderBy: this.$route.query.orderBy }),
          ...(this.$route.query.priceMin && { priceMin: this.$route.query.priceMin }),
          ...(this.$route.query.priceMax && { priceMax: this.$route.query.priceMax }),
          ...(this.$route.query.roomCount && { roomCount: this.$route.query.roomCount }),
          ...(this.$route.query.bathroomCount && { bathroomCount: this.$route.query.bathroomCount }),
          ...(this.$route.query.floorId && { floorId: this.$route.query.floorId }),
          ...(this.$route.query.selectedRange && { selectedRange: this.$route.query.selectedRange }),
          ...(this.$route.query.surfaceMin && { surfaceMin: this.$route.query.surfaceMin }),
          ...(this.$route.query.surfaceMax && { surfaceMax: this.$route.query.surfaceMax }),
          ...(this.$route.query.garage && { garage: this.$route.query.garage }),
        };

        return {
          name: 'configuration',
          query: queries,
          params: {
            ...(this.$route.query.projectDraftId && { projectDraftId: this.$route.query.projectDraftId }),
            tab: 'version',
          },
        };
      }
      return {
        ...this.$route,
      };
    },
    updateImages() {
      if (this.imagesType !== this.$route.query.imagesType) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            imagesType: this.imagesType,
          },
        });
      }
    },
    cancelConfigEdit() {
      try {
        if (this.$route.params.projectDraftId) {
          this.$router.push({
            name: 'project-projectId',
            params: {
              projectDraftId: this.$route.params.projectDraftId,
            },
          });
        } else if (this.previousConfiguration) {
          this.$router.push({
            name: 'configuration',
            query: {
              versionId: this.previousConfiguration.version.versionId,
              landId: this.previousConfiguration.land ? this.previousConfiguration.land.landId : undefined,
              landWidth: this.previousCustomerLand.landWidth,
              landSurface: this.previousCustomerLand.landSurface,
              landPrice: this.previousCustomerLand.landPrice,
              isMainDrainagePlanned: this.previousCustomerLand.isMainDrainagePlanned,
              isServiced: this.previousCustomerLand.isServiced,
              isClayZone: this.previousCustomerLand.isClayZone,
              postalCodeInseeNumber: this.previousPostalCodeInseeNumber,
              customerId: this.previousCustomerId,
              ...(this.$route.query.modelId && { modelId: this.$route.query.modelId }),
              ...(this.$route.query.orderBy && { orderBy: this.$route.query.orderBy }),
              ...(this.$route.query.priceMin && { priceMin: this.$route.query.priceMin }),
              ...(this.$route.query.priceMax && { priceMax: this.$route.query.priceMax }),
              ...(this.$route.query.roomCount && { roomCount: this.$route.query.roomCount }),
              ...(this.$route.query.bathroomCount && { bathroomCount: this.$route.query.bathroomCount }),
              ...(this.$route.query.floorId && { floorId: this.$route.query.floorId }),
              ...(this.$route.query.selectedRange && { selectedRange: this.$route.query.selectedRange }),
              ...(this.$route.query.surfaceMin && { surfaceMin: this.$route.query.surfaceMin }),
              ...(this.$route.query.surfaceMax && { surfaceMax: this.$route.query.surfaceMax }),
              ...(this.$route.query.garage && { garage: this.$route.query.garage }),
            },
          });
        } else {
          this.$router.push({
            name: 'home',
          });
        }
      } catch (er) {
        if (er.name !== 'NavigationDuplicated') {
          throw er;
        }
      }
    },
    applyConfigEdit() {
      try {
        let isClayZone = null;
        if (Array.isArray(this.$route.query.isClayZone)) {
          isClayZone = this.$route.query.isClayZone.length ? this.$route.query.isClayZone[0] : undefined;
        } else {
          isClayZone = this.$route.query.isClayZone;
        }

        if (this.$route.params.projectDraftId) {
          this.$router.push({
            name: 'project-projectId',
            params: {
              projectDraftId: this.$route.params.projectDraftId,
            },
            query: {
              versionId: this.selectedMatching.version.versionId,
              landId: this.selectedMatching.land && this.selectedMatching.land.landId ? this.selectedMatching.land.landId : undefined,
              landWidth: this.selectedMatching.land ? this.landWidth : undefined,
              landSurface: this.selectedMatching.land ? this.landSurface : undefined,
              landPrice: this.selectedMatching.land && (this.landPrice || this.landPrice === 0) ? utils.formatEuroToCent(this.landPrice) : undefined,
              isMainDrainagePlanned: this.selectedMatching.land ? this.isMainDrainagePlanned : undefined,
              isServiced: this.selectedMatching.land ? this.isServiced : undefined,
              isClayZone: this.selectedMatching.land ? isClayZone : undefined,
              postalCodeInseeNumber: this.selectedPostalCodeInseeNumber,
              customerId: this.customer ? this.customer.customerId : undefined,
            },
          });
        } else if (this.selectedMatching) {
          this.$router.push({
            name: 'configuration',
            query: {
              versionId: this.selectedMatching.version.versionId,
              landId: this.selectedMatching.land && this.selectedMatching.land.landId ? this.selectedMatching.land.landId : undefined,
              landWidth: this.selectedMatching.land ? this.landWidth : undefined,
              landSurface: this.selectedMatching.land ? this.landSurface : undefined,
              landPrice: this.selectedMatching.land && (this.landPrice || this.landPrice === 0) ? utils.formatEuroToCent(this.landPrice) : undefined,
              isMainDrainagePlanned: this.selectedMatching.land ? this.isMainDrainagePlanned : undefined,
              isServiced: this.selectedMatching.land ? this.isServiced : undefined,
              isClayZone: this.selectedMatching.land ? isClayZone : undefined,
              postalCodeInseeNumber: this.selectedPostalCodeInseeNumber,
              customerId: this.$route.query.customerId,
              ...(this.$route.query.modelId && { modelId: this.$route.query.modelId }),
              ...(this.$route.query.orderBy && { orderBy: this.$route.query.orderBy }),
              ...(this.$route.query.priceMin && { priceMin: this.$route.query.priceMin }),
              ...(this.$route.query.priceMax && { priceMax: this.$route.query.priceMax }),
              ...(this.$route.query.roomCount && { roomCount: this.$route.query.roomCount }),
              ...(this.$route.query.bathroomCount && { bathroomCount: this.$route.query.bathroomCount }),
              ...(this.$route.query.floorId && { floorId: this.$route.query.floorId }),
              ...(this.$route.query.selectedRange && { selectedRange: this.$route.query.selectedRange }),
              ...(this.$route.query.surfaceMin && { surfaceMin: this.$route.query.surfaceMin }),
              ...(this.$route.query.surfaceMax && { surfaceMax: this.$route.query.surfaceMax }),
              ...(this.$route.query.garage && { garage: this.$route.query.garage }),
            },
          });
        } else {
          this.$router.push({
            name: 'home',
          });
        }
      } catch (er) {
        if (er.name !== 'NavigationDuplicated') {
          throw er;
        }
      }
    },
    async getInterestRate() {
      try {
        const rates = await calculatorApi.getRates();
        const zoneRate = rates.find((rate) => this.$route.query.postalCodeInseeNumber.startsWith(rate.zone));
        const interestRate = zoneRate.rates.find((rate) => rate.duration === 25); // 25 ans
        this.interestRate = interestRate.avgRate;
      } catch (e) {
        this.interestRate = 2.35;
      }
    },
  },
};
</script>

<style lang='sass'>
.matchings
  margin-top: 70px
  .list-container
    position: relative
    height: calc(100vh - 150px)
    .cover
      background: black
      position: absolute
      opacity: 0.2
      height: 100%
      width: 100%
      top: 0
      left: 0
      z-index: 1
      @include breakpoint(large down)
        display: none
    .list
      z-index: 0
      min-height: 100vh
    .page-body
      @media (min-width: 768px)
        @include screen
      @media (max-width: 768px)
        @include screen-mobile
      overflow-y: auto
      height: calc(100vh - 233px)
      padding-top: 24px
      background-color: $medium-bg
      @include breakpoint(large down)
        height: calc(100vh - 272px)
      .back-container
        margin-bottom: 20px
        .cell
          display: flex
          align-items: center
          justify-content: space-between
    .map
      @include breakpoint(large down)
        visibility: hidden
        height: 0
        overflow: hidden
    .map.is-open
      @include breakpoint(large down)
        visibility: initial
        height: initial
        overflow: initial
    .list
      @include breakpoint(large down)
        visibility: hidden
        height: 0
        overflow: hidden
    .list.is-open
      @include breakpoint(large down)
        visibility: initial
        height: initial
        overflow: initial
  .header
    @include header
    padding-bottom: 40px
  .row
    @include row
  .card
    @include card
    border-radius: 8px
    .card-content
      display: flex
      flex-direction: column
      gap: 0.25rem
      padding: 1rem
      .model-version-container
        display: flex
        flex-direction: column
        gap: 0.15rem
        h3, h4
          margin: 0 !important
      .price-container
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: center
        gap: 0.5rem
  .customer
    cursor: pointer
  h2
    @include subtitle
    font-weight: bold
    margin-bottom: 16px
  .matching-link
    text-decoration: none
  .matching
    padding: 0
    overflow: hidden
    cursor: pointer
    position: relative
    text-decoration: none
    display: block
    &.is-ad
      display: flex
      flex-direction: column
      align-items: center
      justify-content: space-between
      min-height: 240px
      .grid-x
        height: 100%
        width: 100%
      .cell
        position: relative
      img
        height: 170px
        min-height: 150px
        object-fit: cover
      div img
        position: absolute
        height: 100%
        margin-left: -19px
        margin-bottom: -5px
        object-fit: contain
      .cell.auto
        display: flex
      h4
        color: white
        padding: 10px
        align-self: center
        margin: 0
        text-align: center
      .title-container
        width: 100%
        height: 100%
        display: flex
        justify-content: center
        align-items: center
        max-height: 83px
        background-color: white
        min-height: 60px
        h5
          padding: 0 15px
          text-align: center
          font-size: 13px
          margin: 0
      .image-only
        img
          width: 100%
          object-fit: cover
          margin-left: 0
    &.blueprint
      img
        display: block
        margin: 10px auto 0
        width: calc( 100% - 20px )
    &.selected
      border: solid $primary 2px
      margin-left: -2px
      margin-right: -2px
    .spinner-container
      margin: 16px
    &.no-land
      filter: grayscale(5%)
      opacity: 0.45
    .card-content
      display: flex
      flex-direction: column
      gap: 0.75rem
      padding: 1rem
    .model-version-price
      display: flex
      flex-direction: row
      gap: 0.25rem
      justify-content: space-between
      align-items: center
      > div:first-child
        display: flex
        flex-direction: column
        gap: 0.15rem
        max-width: 60%
    .version-count
      @include infos
      color: $primary
      &.land
        color: $warning
    .monthly-payment
      &.card-version
        width: 50%
      display: flex
      p
        @include details
      strong
        @include details
        font-weight: 500
        color: $pastille-yellow
        &.land
          color: $marine
      .loading
        display: flex
        flex-direction: row
        gap: 0.75rem
        .app-spinner
          span
            border-color: $pastille-yellow
          &.land > span
            border-color: $marine
    h4
      margin-top: 15px
      &.land
        margin: 0 0 0 16px
      &.no-land
        color: $error-light
        font-weight: 900
    h5
      margin: 0 0 0 16px
      line-height: 1.4
      color: black
      small
        display: inline-block
        padding-left: 3px
        border-left: 2px solid #2A2A2A
        font-size: 60%
        line-height: 1
        vertical-align: 2px
    img
      min-height: 150px
      width: 100%
      object-fit: cover
    h3
      @include body-bold
      margin-bottom: 0
      color: black
    .price
      background-color: $warning
      padding: 0.15rem 0.75rem
      min-width: 50px
      width: fit-content
      height: fit-content
      border-radius: 1rem
      margin: 0 !important
      text-align: center
      .white-spinner
        display: flex
        flex-direction: row
        gap: 0.5rem
        span
          border-color: $white
      h3
        color: white
        font-weight: 500
        margin: 0
  .model
    @include label
    color: $secondary
    font-weight: 500
    margin: 0 !important
  .model-name
    margin: 0 !important
  .image-not-found
    display: flex
    align-items: center
    justify-content: center
    height: 181px
    width: 100%
    border-bottom: 1px solid $line
    .icon-upload
      width: 100px
  .favorite
    position: absolute
    z-index: 2
    top: 10px
    right: 8px
  .favorite-active
    fill: #FF5959
    path
      stroke: #FF5959
  .land
    color: $secondary
    @include infos
  .filter-header-container
    top: 70px
    background-color: $white
    border-top: 1px solid $line
    border-bottom: 1px solid $line
    > .cell
      height: fit-content
      position: relative
      @include breakpoint(large down)
        width: 100%
      &:not(:last-child)
        border-right: 1px solid $line
        @include breakpoint(large down)
          border-right: none
    .filter-header
      cursor: pointer
      height: 50px
      padding: 16px
      position: relative
      overflow: hidden
      @include breakpoint(large down)
        padding: 5px 16px
      .header-column
        height: inherit
        display: flex
        flex-direction: column
        justify-content: center
      .main
        padding-left: 10px
        padding-right: 10px
        display: flex
        flex-direction: column
        justify-content: center
    .location, .layer, .filter
      width: 24px
    .arrow
      width: 12px
      transition: transform 0.3s
    .location, .layer, .filter
      path
        fill: $primary
    .arrow, .filter
      path, circle
        stroke: $primary
    .icon
      @include centered-container
    .filter-name
      @include infos
      color: $primary
      margin: 0
    .filter-value
      @include body
      color: $subtitle
      text-overflow: ellipsis
      overflow: hidden
      max-height: 27px
      white-space: nowrap
  .dropdown-filter, .dropdown-filter-version
    position: absolute
    width: calc(200% - 47px)
    background-color: $medium-bg
    margin-top: 1px
    padding: 24px
    border-bottom-right-radius: 8px
    border-bottom-left-radius: 8px
    z-index: 2
    max-height: calc(100vh - 242px)
    overflow: auto
    h3
      @include body
      margin-bottom: 16px
    &.land
      @include breakpoint(large up)
        left: calc(-100% - 16px)
      @include breakpoint(large down)
        form > .grid-x
          width: calc(100% - 24px)
    &.city
      overflow: initial
    @include breakpoint(large down)
      position: initial
      width: initial
      overflow: auto
      margin: 0
      &.city
        overflow: scroll
      .matching-map
        height: calc(100vh - 300px)
        min-height: 300px
  .dropdown-filter
    @include breakpoint(large down)
      height: calc(100vh - 242px)
  .is-open
    .arrow
      transform: rotate(90deg)
  .dropdown-filter-version
    width: 100%
    padding: 0
    @include breakpoint(large down)
      height: calc(100% - 70px)
      position: fixed
      top: 70px
      max-height: 100vh
    .filter-body
      padding: 24px
      .filter-garage
        padding-top: 10px
    .filter-bottom
      z-index: 5
      position: sticky
      bottom: 0
      background-color: white
      padding: 24px
      .cell
        @include centered-container
      .init
        cursor: pointer
        text-decoration: underline
  // Le filtre des terrains
  .land-choice
    box-shadow: $global-box-shadow
    margin: 8px
    padding: 11px
    border-radius: 8px
    text-align: center
    background-color: $white
    cursor: pointer
    display: flex
    align-items: center
    border: solid 1px $white
    &:first-child
      margin-left: 0
    &:last-child
      margin-right: 0
    &.is-selected
      border: solid 1px $primary
    .land-icon
      width: 30px
    .cell.auto
      padding: 0 5px
    .land-count
      @include label
      color: $body-color
    @include breakpoint(large down)
      margin-left: 0
      margin-right: 0
  .land-detail
    margin-top: 10px
    h3
      margin-bottom: 10px
    .label
      @include label
      color: $body-color
    .app-checkbox span
      font-weight: 400
    &.custom-land
      > h3
        font-weight: 500
        @include body-bold
        color: black
        text-align: center
      > p
        @include small-body
        color: $subtitle
        margin-bottom: 25px
        text-align: center
      .row
        margin-bottom: 10px
      form > .app-button
        margin: 0 auto
    &.no-land, &.land
      @include centered-container
      text-align: center
      flex-direction: column
      h3
        font-weight: 500
        @include body-bold
        color: black
      p
        @include small-body
        color: $subtitle
        margin-bottom: 16px
  // Choix des terrains
  .allotment-container
    background-color: white
    box-shadow: $global-box-shadow
    border-radius: 8px
    margin: 16px 0
    .allotment-header
      padding: 16px
    .allotment-lands > .grid-x
      padding: 16px
      border-top: solid 1px $line
    .company
      margin-top: 8px
      p
        @include infos
        color: $body-color
      p.company-name
        color: $primary
        text-decoration: underline
        @include link
        cursor: inherit
    .download
      display: flex
      justify-content: center
      align-items: center
      flex-direction: column
      cursor: pointer
      svg
        width: 24px
        path
          fill: $primary
      p
        @include label
        color: $primary
        line-height: 14px
        margin-top: 5px
    h3
      color: $title
      font-weight: 500
      margin-bottom: 0
    .land-name p
      @include body
      color: $subtitle
    .land-radio
      align-items: center
      justify-content: center
      display: flex
      margin-left: 10px
  .filter-header-container .filter-header .bubbles-container
    height: inherit
    overflow: hidden
    display: flex
    flex-wrap: nowrap
    align-items: center
    padding: 0 16px
    .bubble
      @include tag
      @include tag-primary
      margin-left: 5px
      display: inline-block
      padding: 3px 10px
      height: fit-content
      font-size: 14px
      &:first-letter
        text-transform: uppercase
      .remove-option
        width: 10px
        margin-left: 5px
        path
          stroke: $primary
    .arrow-container
      padding-left: 16px
  .link
    display: flex
    cursor: pointer
    height: 24px
    width: fit-content
    border: 1px solid $blue-button
    background-color: $blue-button
    border-radius: 16px
    padding: 2px 10px
    transition: background, border 0.3s ease-out
    &:hover
      background-color: lighten($blue-button, 10%)
      border: 1px solid lighten($blue-button, 10%)
      transition: all 0.3s ease-in
      span
        transition: all 0.3s ease-in
      svg path
        stroke: $white
        transition: all 0.3s ease-in
    span
      display: inline-block
      font-size: $font-xxs
      color: $white
      font-weight: 400
      margin-left: 4px
    svg
      width: 13px
      vertical-align: middle
      path
        stroke: $white
  .card.model-card
    cursor: pointer
    .price-container
      display: flex
      flex-direction: column
      justify-content: space-between
      align-items: flex-end
      gap: 0.5rem
      h4
        text-align: right
      .price
        margin-top: 2px
  .card.flash-news-card
    cursor: default
    padding: 26px 24px 16px 24px
    box-sizing: border-box
    display: flex
    flex-direction: column
    position: relative
    overflow: hidden
    .flash-news-banner
      text-transform: uppercase
      position: absolute
      color: white
      background-color: $primary
      right: -5.3rem
      top: -2rem
      transform-origin: top left
      transform: rotate(45deg)
      font-size: 14px
      line-height: 20px
      font-weight: 700
      padding: 0 50px
    img
      width: 15%
      min-height: none
    h5
      text-transform: none
      color: $warning
      margin: 10px 0 8px
    .card-description
      color: white
      font-size: 12px
      font-weight: 500
      font-family: 'Infos'
      line-height: 16px
      flex-grow: 2
    .card-fine-prints
      font-size: 8px
      color: white
      opacity: 0.6
      line-height: 10px
      margin-top: 8px

  .main-loading .spinner-container, .empty-list p
    margin: 30px
  .edit-configuration
    position: sticky
    bottom: 0
    z-index: 2
    background-color: $colored-bg
    color: white
    padding: clamp(1rem, 2dvh, 2.5rem) 20px
    display: flex
    justify-content: space-between
    align-items: center
    flex-direction: row
    gap: 2rem
    flex-wrap: wrap
    &-actions
      display: flex
      flex-direction: row
      align-items: center
      gap: 1rem
    &-left,
    &-right
      display: flex
      flex-direction: row
      gap: 2rem
    &-price
      @include body-bold
      color: $white
    &-version
      @include cta
      color: $white
    &-land
      @include infos
      color: $white
    &-logo
      display: flex
      flex-direction: row
      align-items: center
      gap: 0.75rem
      svg
        width: 1.5rem
        path, rect
          stroke: $primary
    &-surface
      @include infos
      color: $white
    &-metrics
      display: flex
      flex-direction: column
      gap: 0.25rem
      text-align: right
    &-selection
      display: flex
      flex-direction: column
      justify-content: center
      gap: 0.25rem
    &-loader
      display: flex
      align-items: center
    &-status
      @include label
      color: $primary
  .cta
    @include cta
    color: $subtitle
  .checkbox-value
    @include small-body
    color: $subtitle
  .city-toggle
    margin-bottom: 16px

  // cacher / afficher des éléments en desktop ou mobile
  @include breakpoint(large down)
    .desktop
      display: none!important
    .app-search-city
      margin-bottom: 30px
    .version-filters
      position: fixed
      right: 20px
      bottom: 20px
      padding: 9px 11px
      border-radius: 27px
      background-color: $blue-button
      &.edition
        bottom: 120px
      svg
        width: 25px
        margin-top: 2px
    .toggle-land-container
      display: flex
      align-items: center
      justify-content: center
      margin: 5px 0 20px 0
    .mobile-big-button
      width: 100%
      margin-top: 20px
  @include breakpoint(large up)
    .mobile
      display: none
</style>
