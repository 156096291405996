<template>
  <component :is="ad.link.url ? 'a' : 'div'" v-if="!ad.isFlashNews" class="mobile-matching-vignette ad"
    :href="ad.link.url || null" :target="ad.link.url ? '_blank' : null" :style="{ backgroundColor: ad.backgroundColor }">
    <div v-if="ad.description" class="grid-x">
      <div class="cell small-6">
        <img v-if="ad.image && ad.image.filename" :src="ad.image.filename" :alt="ad.image && ad.image.alt" />
      </div>
      <div class="cell auto">
        <h4 v-html="ad.description"></h4>
      </div>
    </div>
    <div v-else class="grid-x image-only">
      <div class="cell auto">
        <img v-if="ad.image && ad.image.filename" :src="ad.image.filename" :alt="ad.image && ad.image.alt" />
      </div>
    </div>
    <div class="title-container">
      <h5>{{ ad.name }}</h5>
    </div>
  </component>
  <component :is="ad.link.url ? 'a' : 'div'" v-else class="mobile-matching-vignette flash-news"
    :href="ad.link.url || null" :target="ad.link.url ? '_blank' : null" :style="{ backgroundColor: ad.backgroundColor }">
    <div class="flash-news-banner">flash info</div>
    <img :src="ad.image.filename" :alt="ad.image.alt" />
    <h5>{{ ad.name }}</h5>
    <p class="card-description">{{ ad.description }}</p>
    <p class="card-fine-prints">{{ ad.finePrints }}</p>
  </component>
</template>

<script>
export default {
  name: 'mobile-matching-vignette',
  props: {
    ad: {
      type: Object,
      default: () => { },
    },
  },
};
</script>

<style lang="sass">
.mobile-matching-vignette
  text-decoration: none!important
  border-radius: 8px
  &.ad
    display: flex
    flex-direction: column
    justify-content: space-between
    box-sizing: border-box
    overflow: hidden
    .grid-x
      min-height: 185px
    .cell
      position: relative
    img
      object-fit: cover
    div img
      position: absolute
      width: 100%
      height: 100%
      margin-left: -19px
      margin-bottom: -5px
      object-fit: contain
    .cell.auto
      display: flex
    h4
      color: white
      padding: 10px
      align-self: center
      margin: 0
      text-align: center
    .title-container
      width: 100%
      height: 100%
      display: flex
      justify-content: center
      align-items: center
      background-color: white
      min-height: 70px
      h5
        padding: 0 15px
        text-align: center
        font-size: 13px
        margin: 0
        color: black
    .image-only
      img
        width: 100%
        object-fit: cover
        margin-left: 0
  &.flash-news
    padding: 26px 24px 16px 24px
    display: flex
    flex-direction: column
    position: relative
    overflow: hidden
    .flash-news-banner
      text-transform: uppercase
      position: absolute
      color: white
      background-color: $primary
      right: -5.3rem
      top: -2rem
      transform-origin: top left
      transform: rotate(45deg)
      font-size: 14px
      line-height: 20px
      font-weight: 700
      padding: 0 50px
    img
      width: 15%
      min-height: none
    h5
      text-transform: none
      color: $warning
      margin: 10px 0 8px
    .card-description
      color: white
      font-size: 12px
      font-weight: 500
      font-family: 'Infos'
      line-height: 16px
      flex-grow: 2
    .card-fine-prints
      font-size: 8px
      color: white
      opacity: 0.6
      line-height: 10px
      margin-top: 8px
</style>
