<template>
  <ul class="app-progress">
    <li class="step-container"
      v-for="(step, index) in steps" :key="index"
      :class="{ done: index <= currentStep - 1 }" @click="handleStepClick(index)" role="button">
      <button>
        <div class="step-circle">
          <p>{{ index + 1 }}</p>
        </div>
        <p class="step-name">{{ step }}</p>
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    steps: [Array], // array de label (string)
    currentStep: {
      type: [Number],
      default: 0,
    },
  },
  methods: {
    handleStepClick(index) {
      if (this.currentStep > index + 1) {
        this.$emit('update:step', index + 1);
      }
    },
  },
};
</script>

<style lang="sass">
.app-progress
  display: flex
  justify-content: stretch
  padding-left: 0
  width: 100%

  .step-container
    list-style-type: none
    width: fit-content
    flex-grow: 2
    flex-basis: 0
    &:not(:last-child)
      button:after
        content: ''
        display: inline-block
        width: calc(100% - 1rem)
        height: 1px
        background: $body-color
        position: absolute
        top: 1rem
        left: calc(50% + 1rem)

    p
      @include infos
      color: $white
    button
      display: flex
      flex-direction: column
      align-items: center
      width: 100%
      background: transparent
      border: none
      position: relative

    .step-circle
      width: 2rem
      height: 2rem
      border-radius: 50%
      background-color: $body-color
      display: flex
      justify-content: center
      align-items: center

    .step-name
      margin: 0.5rem auto
      width: fit-content
      position: relative

    &.done
      .step-circle
        background-color: $primary
</style>
