import axios from 'axios';

const { VUE_APP_API_URL, VUE_APP_BRAND_ID } = process.env;

/**
 * Récupère la liste des codes postaux d'un style via son ID
 * @param {string} search: texte de recherche par code postal ou par nom de ville
 */
const getAll = async (search) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/public/postal-codes`, {
      params: { ...(search && { search }) },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Récupère un code postal
 * @param {string} postalCodeInseeNumber: code postal à récupérer
 */
const getOne = async (postalCodeInseeNumber) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/postal-codes/${postalCodeInseeNumber}`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Récupère la liste des codes postaux d'une agence via son ID
 * @param {string} postalCodeInseeNumber: code postal
 * @param {string} search: texte de recherche par code postal ou par nom de ville
 */
const getByPostalCode = async (postalCodeInseeNumber, radius, latitude, longitude) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/public/brands/${VUE_APP_BRAND_ID}/city-lands/`, {
      params: {
        ...(postalCodeInseeNumber && { postalCodeInseeNumber }),
        ...(radius && { radius }),
        ...(latitude && { latitude }),
        ...(longitude && { longitude }),
      },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const postalCode = {};
postalCode.getAll = getAll;
postalCode.getOne = getOne;
postalCode.getByPostalCode = getByPostalCode;

export default postalCode;
