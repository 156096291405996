<template>
  <div class="app-scrollable">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>
.app-scrollable
  overflow-y: auto
  scrollbar-color: $line
  scrollbar-width: 4px

  &::-webkit-scrollbar
    border-radius: 10px
    height: 10px
    width: 4px

  &::-webkit-scrollbar-thumb
    visibility: hidden
    border-radius: 4px
    background-color: $line

  &::-webkit-scrollbar-track
    border-radius: 10px

  &:hover::-webkit-scrollbar-thumb
    visibility: visible
</style>
