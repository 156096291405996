<template>
  <div id="app" :class="{ 'pdf-route': $route.name && $route.name.includes('pdf') }">
    <div class="grid-x" v-if="!$route.meta.print">
      <!-- Main side navigation -->
      <div class="page-container cell auto" :class="{ 'no-scroll': noScroll }">
        <top-nav :isDark="isDark" ref="topNav" />
        <div class="page with-nav">
          <keep-alive include="Matchings">
            <router-view />
          </keep-alive>
          <Footer v-if="!noScroll" />
        </div>
      </div>

      <!-- Emplacement pour une modale -->
      <!-- TODO: delete news-modal ? (désactivé le 24/01/2023)
      <app-modal class="news-modal"
        :show="isNewsModalOpen"
        @update:show="closeNewsModal"
        size="medium"
      >

        <img src="@/assets/img/news-modal.jpg"/>
      </app-modal>
      -->
    </div>
    <div v-else class="print">
      <router-view />
    </div>
    <!-- bouton de partage -->
    <button class="share-container" id="Share-Home" v-if="!$route.meta.hideShare" @click="openShare">
      <img src="@/assets/gifs/share-transparent.gif"/><span class="share-text">Envoyer à un ami</span>
    </button>

    <!-- Popup de partage -->
    <social-shares />

    <!-- Popup pour trigger Google Analytics -->
    <analytics-modal />

    <!-- Global basics components integration -->
    <app-message />
    <app-notifications />
  </div>
</template>
<script>
import TopNav from '@/components/layouts/TopNav.vue';
import Footer from '@/components/layouts/Footer.vue';
import SocialShares from '@/components/general/SocialShares.vue';
import AnalyticsModal from '@/components/general/AnalyticsModal.vue';

// import ls from 'local-storage'; TODO: delete news-modal ? (désactivé le 24/01/2023)

export default {
  name: 'App',
  components: {
    TopNav,
    Footer,
    SocialShares,
    AnalyticsModal,
  },
  data() {
    return {
      isDark: false,
      observer: null,
      pageChange: false,
      noScroll: false,
      // isNewsModalOpen: false, TODO: delete news-modal ? (désactivé le 24/01/2023)
    };
  },
  mounted() {
    this.isDark = this.$route.meta.isDark;
    this.noScroll = this.$route.meta.noScroll;
    this.observer = new IntersectionObserver(this.handleIntersection);
    this.setObserver();

    // check if news modal should open
    /** TODO: delete news-modal ? (désactivé le 24/01/2023)
    if (ls('NEWSMODAL01122022')) {
      const lastSavedDate = ls('NEWSMODAL01122022');
      const formatedLastSavedDate = this.$dayjs(this.$dayjs(lastSavedDate).add(7, 'd'));

      const today = new Date();
      const formatedDate = this.$dayjs(this.$dayjs(today).format('YYYY-M-D'));
      if (formatedDate.isAfter(formatedLastSavedDate)) {
        this.isNewsModalOpen = true;
      } else {
        this.isNewsModalOpen = false;
      }
    } else {
      this.isNewsModalOpen = true;
    }
    */
  },
  watch: {
    $route() {
      this.pageChange = true;
      this.isDark = this.$route.meta.isDark;
      this.noScroll = this.$route.meta.noScroll;
      setTimeout(() => {
        this.setObserver();
        if (this.$route.hash) {
          this.isDark = false;
        }
      }, 100);
    },
  },
  methods: {
    setObserver() {
      const target = document.querySelector('.hero-small');
      const target2 = document.querySelector('.hero');
      if (target) {
        this.observer.observe(target);
      }
      if (target2) {
        this.observer.observe(target2);
      }
    },
    handleIntersection(entries) {
      if (this.$route.meta.isDark === true) {
        entries.map((entry) => {
          if (entry.isIntersecting) {
            this.isDark = true;
          } else if (!this.pageChange) {
            this.isDark = false;
          } else {
            this.pageChange = false;
          }
          return null;
        });
      }
    },
    onQueryChange(query) {
      this.$router.push({ name: this.$route.name, query: { ...query } });
    },
    /** TODO: delete news-modal ? (désactivé le 24/01/2023)
    closeNewsModal() {
      const today = new Date();
      const formatedDate = this.$dayjs(today).format('YYYY-M-D').toString();
      ls('NEWSMODAL01122022', formatedDate);
      this.isNewsModalOpen = false;
    },
    */
    openShare() {
      this.$socialShares.show({});
    },
  },
};
</script>

<style lang="sass">
  @import "@/assets/sass/_foundation.scss"
  #app
    color: $body-color
    background-color: $white
    .news-modal
      img
        max-width: 100%
      .modal-wrapper
        padding: 0
  html, body, #app
    margin: 0
    padding: 0
    color: $body-color
    background-color: $white
    overflow-x: auto
  body
    height: 100%
    overflow-x: hidden
  html, body, #app, p, a, span, ul li, button, input, textarea
    font-family: 'Ubuntu', sans-serif !important
  figure
    margin: 0
  ul
    margin: 0
    li
      list-style: none
  .print
    height: 100%
    width: 100%
  .page-container
    width: 100%
    height: 100vh
    overflow-y: scroll!important
    overflow-x: auto
    position: relative
    .page
      height: 100%
      margin-top: -70px
  h1, h2, h3, h5, h6
    margin: 0
    color: $black
  p, span
    margin: 0
    @include body
    color: $body-color
    &.infos
      @include infos
    &.label
      @include label
  strong
    margin: 0
    @include body-bold
  a:not(.imdw-js)
    @include link
    color: $body-color
  label
    @include label
  .share-container
    position: fixed
    background: transparent
    border-radius: 32px
    bottom: 24px
    right: 24px
    height: 60px
    z-index: 1
    cursor: pointer
    animation: share-container 15s infinite
    .share-text
      color: #111
      font-weight: bold
      margin-left: 55px
      animation: share-text 15s infinite
    img
      width: 76px
      height: 70px
      position: absolute
      left: -7px
      top: -9px
  @keyframes share-container
    0%
      width: 220px
      border: 3px solid darken(#af4bc6, 10%)
      background-color: #FFF
    17%
      width: 220px
      border: 3px solid darken(#af4bc6, 10%)
      background-color: #FFF
    20%
      width: 76px
      border: none
      background-color: transparent
    95%
      width: 76px
      border: none
      background-color: transparent
    98%
      width: 220px
      border: 3px solid darken(#af4bc6, 10%)
      background-color: #FFF
    100%
      width: 220px
      border: 3px solid darken(#af4bc6, 10%)
      background-color: #FFF
  @keyframes share-text
    0%
      width: 60%
      visibility: show
    17%
      width: 60%
      visibility: hidden
    20%
      width: 0
      visibility: hidden
    95%
      width: 0
      visibility: hidden
    98%
      width: 60%
      visibility: hidden
    100%
      width: 60%
      visibility: show
  @media (min-width: 768px)
    h1, .h1
      @include heading-xl
    h2, .h2
      @include heading-lg
    h3, .h3
      @include heading-md
    h5, .h5
      @include heading-sm
      color: $primary
      text-transform: uppercase
    h6, .h6
      @include subtitle
  @media (max-width: 768px)
    h1, .h1
      @include heading-xl-mobile
    h2, .h2
      @include heading-lg-mobile
    h3, .h3
      @include heading-md-mobile
    h5, .h5
      @include heading-sm-mobile
      color: $primary
      text-transform: uppercase
    h6, .h6
      @include subtitle-mobile
  .no-scroll
    overflow-y: hidden!important
</style>
