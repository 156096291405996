<template>
  <section class="mobile-matchings">
    <app-progress :steps="['Ville', 'Chambres', 'Maisons']" :currentStep="currentStep" @update:step="currentStep = $event"/>

    <MobileMatchingsList v-if="currentStep === 3" />
    <MobileMatchingsSearch v-else :currentStep="currentStep" @update:step="currentStep = $event"/>
  </section>
</template>

<script>
import MobileMatchingsSearch from './MobileMatchingsSearch.vue';
import MobileMatchingsList from './MobileMatchingsList.vue';

export default {
  name: 'mobile-matchings',
  components: {
    MobileMatchingsList,
    MobileMatchingsSearch,
  },
  data() {
    return {
      currentStep: 2,
    };
  },
  watch: {
    currentStep(value) {
      if (value === 1) {
        this.$router.push({ name: 'home' });
      }
    },
  },
  mounted() {
    const { postalCodeInseeNumber, nbRooms } = this.$route.query;
    // Si on a déjà tous les paramètres, on entre direct dans la liste des matchings
    if (postalCodeInseeNumber && nbRooms) {
      this.currentStep = 3;
      // Si pas de code postal, on revient en arrière car il nous en faut un
    } else if (!postalCodeInseeNumber) {
      this.$router.go(-1);
    }
  },
};
</script>

<style lang="sass">
.mobile-matchings
  height: calc(100% - 70px)
  overflow-y: scroll
  margin-top: 70px
  padding-top: 5vw
  box-sizing: border-box
  background-size: cover
  background-image: url(~@/assets/img/pages/configurator/config-mobile-background.png)
  background-attachement: fixed
  display: flex
  flex-direction: column
  gap: 3vh
  &-list, &-search
    flex-grow: 2

</style>
