import axios from 'axios';
import ls from 'local-storage';

const auth = {};

// Utilisation de require pour contourner un bug de webpack
const router = require('../router');

const { VUE_APP_API_URL } = process.env;
let TOKEN = ls('TOKEN') || false;

// Token du local storage
if (TOKEN) {
  axios.defaults.headers.common.Authorization = `Bearer ${TOKEN}`;
}

// Récupération par axios d'une 401 (unauthorized) et redirection login
axios.interceptors.response.use(null, (error) => {
  if (error.response && error.response.status === 401) {
    auth.logout(false);

    // router.default.currentRoute.path === '/accueil' &&
    // Remettre un truc du genre (cf ci-dessus) (redirection)
    if (error.response.data.message === 'Wrong username/password combination') {
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }

  return Promise.reject(error);
});

// Récupération du token
function getToken() {
  return ls('TOKEN');
}

// L'utilisateur est-il loggué
function isLoggedIn() {
  return !!getToken();
}

// Login
const login = async (email, password) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/auth/public/login`, { email, password });
    TOKEN = response.data.token;
    ls('TOKEN', TOKEN);
    axios.defaults.headers.common.Authorization = `Bearer ${TOKEN}`;
    return response.data;
  } catch (er) {
    throw er;
  }
};

// Register
const register = async (email, password, firstname, lastname, phone, brandId, agencyId) => {
  try {
    const newCustomer = {
      email,
      password,
      firstname,
      lastname,
      phone,
      agencyId,
    };
    const response = await axios.post(`${VUE_APP_API_URL}/auth/public/brands/${brandId}/register`, newCustomer);

    return response.data;
  } catch (er) {
    throw er;
  }
};

// Create Password
const createPassword = async (email) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/auth/public/create-password`, { email });

    return response.data;
  } catch (er) {
    throw er;
  }
};

// Forgotten Password
const lostPassword = async (email) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/auth/public/lost-password`, { email });

    return response.data;
  } catch (er) {
    throw er;
  }
};

// New Password
const newPassword = async (password, token) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/auth/public/lost-password/new-password?token=${token}`, { password });

    return response.data;
  } catch (er) {
    throw er;
  }
};

// Register Finalization
const registerFinalization = async (token, password, firstname, lastname, phone) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/auth/customer-leads/register`, {
      password,
      firstname,
      lastname,
      phone,
      token,
    });
    return response.data;
  } catch (er) {
    throw er;
  }
};

// Logout
const logout = (redirectToHome = true) => {
  ls.remove('TOKEN');
  axios.defaults.headers.common.Authorization = null;
  if (router.default.currentRoute.meta.loginRequired) {
    router.default.push({ name: redirectToHome ? 'home' : 'login' });
  }
};

function getBrandId() {
  return ls('BRAND');
}
// Sauvegarde des brand du member
function saveBrand(brand) {
  return ls.set('BRAND', brand);
}

auth.login = login;
auth.register = register;
auth.createPassword = createPassword;
auth.lostPassword = lostPassword;
auth.newPassword = newPassword;
auth.logout = logout;
auth.getToken = getToken;
auth.isLoggedIn = isLoggedIn;
auth.getBrandId = getBrandId;
auth.saveBrand = saveBrand;
auth.registerFinalization = registerFinalization;

export default auth;
