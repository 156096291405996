import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupère une version
 * @param {string} versionId: id de la version à récupérer
 */
const getPublicById = async (versionId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/versions/${versionId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const version = {};
version.getPublicById = getPublicById;

export default version;
