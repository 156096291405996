import slugify from 'slugify';
import { create, all } from 'mathjs';

const mathjs = create(all);
mathjs.config({ number: 'number', precision: 14 });

/**
 * Similaire au _debounce de lodash: Retarde l'exécution de la fonction de 'wait'ms à chaque appel afin d'appeler la fonction seulement quand il n'y a plus de changement
 * @param {function} func : fonction dont la fréquence d'exécution est à limiter
 * @param {Int} wait : temps d'attente (ms) sans appel à la fonction avant de l'exécuter
 * @returns {Function} fonction qui exécute la fonction en argument, mais à fréquence limitée
 */
const debounce = (func, wait) => {
  let timeout;
  return function executedFunc(...args) {
    const later = () => {
      timeout = null;
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

// Formate les options pour le composant app-select
const formatOptions = (options, nameFunc, labelFunc, addValue) => {
  const formattedOptions = options.map((option) => ({ name: nameFunc(option), label: labelFunc(option) }));

  if (addValue === 'choose') {
    formattedOptions.unshift({ name: null, label: 'Choisir' });
  }

  return formattedOptions;
};

// Format les variables techniques
const formatVariableKey = (value) => {
  let formattedValue = slugify(
    value,
    {
      replacement: '_',
      remove: undefined,
      lower: true,
      strict: true,
      locale: 'fr',
    },
  );

  formattedValue = [formattedValue.slice(0, 0), '$', formattedValue.slice(0)].join('');
  return formattedValue;
};

// Convertit une string en valeur booléenne ou null
const parseBoolean = (value) => {
  if (value === 'true') return true;
  if (value === 'false') return false;
  return null;
};

// On ne peut finalement pas faire les changements avec toLocalString, ça ne fonctionne pas sur Safari
const numberToPrice = (n) => {
  const parts = n.toString().split('.');
  return `${parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}${(parts[1] ? `.${parts[1].slice(0, 2)}` : '')} €`;
};

// Formate les prix en centime en euro, possibilité de formater le résultat pour l'affichage utilisateur
const formatCentToEuro = (value, viewerFormat, round) => {
  if (value || value === 0) {
    let formattedPrice = null;
    if (!round) {
      formattedPrice = parseFloat(mathjs.format(mathjs.chain(value).divide(100).done(), { precision: mathjs.config.precision, notation: 'fixed' }));
    } else {
      formattedPrice = parseInt(mathjs.format(Math.round(mathjs.chain(value).divide(100).done()), { precision: mathjs.config.precision, notation: 'fixed' }), 10);
    }
    if (viewerFormat) {
      if (!round) {
        return numberToPrice(formattedPrice);
      }
      return numberToPrice(formattedPrice);
    }
    return formattedPrice;
  }
  return 0;
};

// Formate les prix en euro en centimes, possibilité de formater le résultat pour l'affichage utilisateur
const formatEuroToCent = (value, viewerFormat) => {
  const formattedPrice = parseInt(mathjs.format(mathjs.chain(value).multiply(100).done(), { precision: mathjs.config.precision, notation: 'fixed' }), 10);

  if (viewerFormat) {
    return formattedPrice.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' });
  }

  return formattedPrice;
};

// Formate les prix en euro en centimes, possibilité de formater le résultat pour l'affichage utilisateur
const formatEuro = (value, viewerFormat) => {
  const formattedPrice = parseFloat(mathjs.format(mathjs.chain(value).done(), { precision: mathjs.config.precision, notation: 'fixed' }));

  if (viewerFormat) {
    return formattedPrice.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' });
  }

  return formattedPrice;
};

// Estimation du prix d'un matching
const estimatePrice = (pricePrimary, landPrice) => {
  const percentageDiff = 9; // Pourcentage d'écart entre le pré-prix et le prix final (c'est une approximation), il est ajouté pour que le filtre de prix soit plus précis lorsque les prix n'ont pas encore été chargés

  if (landPrice) {
    const landFees = landPrice * 0.08; // Frais de notaire sur terrain : Provision de 8% du coût du terrain dans le calcul de coût total du projet.
    const damagesInsurance = pricePrimary * 0.018; // Assurance Dommages-Ouvrage : Provision de 1,8% du coût de la maison dans le calcul de coût total du projet.
    const fees = damagesInsurance + landFees + 1000000; // Ajout des coûts annexes lorsqu'on sélectionne un terrain afin que le filtre de prix soit à peu près vrai (les 10000€ sont une apporximation de frais dont le montant dépend de la version. Ex : branchement 5m, implantation géomètre...)

    return pricePrimary + pricePrimary * (percentageDiff / 100) + landPrice + fees;
  }
  return pricePrimary + pricePrimary * (percentageDiff / 100);
};

const utils = {};
utils.debounce = debounce;
utils.formatOptions = formatOptions;
utils.formatVariableKey = formatVariableKey;
utils.parseBoolean = parseBoolean;
utils.formatCentToEuro = formatCentToEuro;
utils.formatEuroToCent = formatEuroToCent;
utils.formatEuro = formatEuro;
utils.estimatePrice = estimatePrice;

export default utils;
