<template>
  <datepicker class="app-datepicker" format="MM-dd-yyyy" :value="input" @selected="onSelect" clear-button></datepicker>
</template>

<script>
import Datepicker from 'vuejs-datepicker';

export default {
  components: {
    Datepicker,
  },
  props: {
    value: [Date],
    type: {
      default: 'text',
      validator: (val) => ['text', 'number', 'email', 'password'].indexOf(val) !== -1,
    },
  },
  computed: {
    input() {
      return this.value;
    },
  },
  methods: {
    onSelect(val) {
      this.$emit('input', val);
    },
  },
};
</script>

<style lang="sass">
.app-datepicker
  position: relative
  display: inline-block
  width: 100%
  max-width: 400px
  input
    width: 100%
    padding: 0.75rem 1rem 0.6rem
    background: white
    font-size: $global-font-size
    border-radius: $global-border-radius
    border: 1px solid $primary-translucent
    transition: all 0.2s ease-in-out
    &:hover
      border-color: darken($primary-translucent, 10%)
    &:focus
      outline: 0
      border-color: $primary

  .vdp-datepicker__calendar .cell.selected
    background: $primary
  .vdp-datepicker__clear-button, .vdp-datepicker__calendar-button
    position: absolute
    top: 50%
    right: 10px
    margin-top: -10px
</style>
