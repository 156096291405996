<template>
  <div class="mobile-matchings-filters" :class="{ open: isOpen }">
    <div class="close-btn-container">
      <button title="Fermer les filtres" @click="$emit('update:isOpen')"><close /></button>
    </div>

    <div class="filters-container">
      <app-radio-button
        v-if="ranges.length"
        class="filter"
        label="Gammes"
        name="range"
        v-model="filters.selectedRange"
        :options="ranges" />

      <hr />

      <app-radio-button
        class="filter"
        label="Trier par :"
        name="orderBy"
        v-model="filters.orderBy"
        :options="[
          { name: 'price', label: 'Prix croissant' },
          { name: '-price', label: 'Prix décroissant' },
        ]" />

      <hr />

      <app-radio-button
        class="filter"
        label="Votre budget"
        name="priceRange"
        v-model="filters.priceRange"
        :options="[
          { name: '0-150000', label: '-150 000 €' },
          { name: '150000-175000', label: '150 000 € - 175 000 €' },
          { name: '175000-200000', label: '175 000 € - 200 000 €' },
          { name: '200000-infinite', label: '+ 200 000 €' },
        ]" />

      <hr />

      <app-radio-button
        class="filter"
        label="Surface habitable"
        name="habitableSurfaceRange"
        v-model="filters.habitableSurfaceRange"
        :options="[
          { name: '0-80', label: 'moins de 80 m²' },
          { name: '80-100', label: 'de 80 à 100 m²' },
          { name: '100-120', label: 'de 80 à 120 m²' },
          { name: '120-infinite', label: 'plus de 120 m²' },
        ]" />

      <hr />

      <app-radio-button
        class="filter"
        label="Niveau"
        name="floors"
        v-model="filters.floorId"
        :options="floors" />

      <hr />

      <app-select
        class="filter"
        label="Modèles"
        v-model="filters.modelId"
        :options="models"
      />
    </div>

    <div class="cta-container">
      <app-button @click="applyFilters">Valider</app-button>
      <button @click="resetFilters">Réinitialiser</button>
    </div>
  </div>
</template>

<script>
import floorApi from '@/services/api/floor';
import modelApi from '@/services/api/model';
import utils from '@/services/utils/utils';
import rangeApi from '@/services/api/range';

import close from '@/assets/img/close.svg?inline';

export default {
  name: 'mobile-matchings-filters',
  components: {
    close,
  },
  props: {
    isOpen: Boolean,
  },
  data() {
    return {
      filters: {
        orderBy: this.$route.query.orderBy || undefined,
        priceRange: this.$route.query.priceRange || undefined,
        selectedRange: this.$route.query.selectedRange || undefined,
        habitableSurfaceRange: this.$route.query.habitableSurfaceRange || undefined,
        floorId: this.$route.query.floorId || undefined,
        modelId: this.$route.query.modelId || undefined,
      },
      floors: [],
      models: [],
      ranges: [],
    };
  },
  mounted() {
    this.getFloors();
    this.getModels();
    this.getRanges();
  },
  methods: {
    applyFilters() {
      this.$router.push({ query: { ...this.$route.query, ...this.filters } });
      this.$emit('update:isOpen');
    },
    resetFilters() {
      this.filters = {
        orderBy: undefined,
        priceRange: undefined,
        selectedRange: undefined,
        habitableSurfaceRange: undefined,
        floorId: undefined,
        modelId: undefined,
      };
      this.applyFilters();
    },
    async getFloors() {
      try {
        const floors = await floorApi.getAll();

        // On remplace "rez-de-chaussée", par "plein pied", pour faire varier les conditionsdu filtre
        if (floors && floors.length) {
          const index = floors.findIndex((floor) => floor.floorId === 'd0e1fedc-c9a8-4f59-8f30-66d897b0a951');
          if (index !== -1) {
            floors[index] = {
              ...floors[index],
              name: 'Plain-pied',
            };
          }
          this.floors = utils.formatOptions(
            floors,
            (option) => option.floorId,
            (option) => option.name,
          );
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des niveaux',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getRanges() {
      try {
        const ranges = await rangeApi.getAll();
        this.ranges = utils.formatOptions(
          ranges,
          (option) => option.rangeId,
          (option) => option.name,
        ).filter((range) => range.label.toUpperCase() !== 'PRIME');
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des gammes',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getModels() {
      try {
        const models = await modelApi.getAll(null, null, null, null, true, this.$route.query.packId);
        this.models = utils.formatOptions(
          models.data,
          (option) => option.modelId,
          (option) => option.name,
          'choose',
        );
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des modèles',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
  },
};
</script>

<style lang="sass">
.mobile-matchings-filters
  position: fixed
  top: 0
  width: 100%
  height: 100vh
  max-height: 100%
  background: $loading
  box-sizing: border-box
  z-index: 4
  transform: translateX(100%)
  transition: 0.3s ease-in transform
  display: flex
  flex-direction: column
  &.open
    transform: translateX(0)

  .close-btn-container
    text-align: right
    margin: 3vw 3vw 0 0
    button
      background: transparent
      border: none
      svg
        width: 24px
        path
          stroke: $black

  .filters-container
    padding: 3vw
    flex-grow: 2
    overflow: scroll
    .filter
      &.app-radio-button, &.app-select
        padding: 16px 0
        > p, > label > span
          @include cta
          color: $subtitle
          margin-bottom: 16px
      &.app-select
        svg
          bottom: unset
        ul
          top: calc(100% - 32px)
          margin-bottom: 6vw

    hr
      background-color: $line
      height: 1px
      border: none

  .cta-container
    width: 100%
    background: $white
    padding: 16px 24px
    box-sizing: border-box
    display: flex
    justify-content: space-between
    align-items: center

    button:not(.app-button)
      background: transparent
      border: none
      @include link
      color: $body-color
</style>
