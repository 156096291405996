<template>
  <div class="app-modal">
    <transition name="modal">
      <div class="modal-wrapper" :class="size" v-if="visible">
        <div class="close-modal">
          <close @click="$emit('update:show', false)" />
        </div>
        <div class="title">
          <h2>{{ title }}</h2>
        </div>
        <slot></slot>
      </div>
    </transition>
    <transition name="screen">
      <div class="modal-screen" v-if="visible" @click="$emit('update:show', false)"></div>
    </transition>
  </div>
</template>

<script>
import close from '@/assets/img/close.svg?inline';

export default {
  name: 'app-modal',
  components: {
    close,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      validator: (val) => ['small', 'medium', 'large'].indexOf(val) !== -1,
      default: 'small',
    },
    title: {
      type: String,
    },
  },
  computed: {
    visible() {
      return this.show;
    },
  },
};
</script>

<style lang='sass' scoped>
.app-modal
  .modal-wrapper
    position: fixed
    top: 50%
    left: 50%
    height: fit-content
    max-width: calc(100% - 100px)
    background: $off-white
    box-shadow: $drop-max
    border-radius: 4px
    z-index: 10
    transform: translate(-50%, -50%)
    max-height: calc(100vh - 100px)
    overflow: auto

    .title
      display: flex
      justify-content: center
      padding-bottom: 1.5rem

    .close-modal
      position: absolute
      top: 24px
      right: 24px

      svg
        cursor: pointer
        width: 14px
        height: 14px
        stroke: $title

  .small
    width: 440px
    padding: 40px

  .medium
    width: 500px
    padding: 40px

  .large
    width: 700px
    padding: 40px

  .modal-screen
    position: fixed
    top: 0
    bottom: 0
    left: 0
    right: 0
    background-color: rgba($body, 0.7)
    z-index: 5

  .screen-enter-active
    transition: opacity .9s

  .screen-leave-active
    transition: opacity .3s

  .screen-enter, .screen-leave-to
    opacity: 0

  .modal-enter-active, .modal-leave-active
    transition: transform .2s, opacity .2s, scale 0.15s ease-in-out

  .modal-enter, .modal-leave-to
    opacity: 0
    transform: translateY(-130px) translateX(-50%) scale(0.9)
</style>
