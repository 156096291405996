<template>
  <section class="mobile-matchings-search">
    <!-- Étape : nombre de chambres -->
    <div v-if="currentStep === 2" class="rooms-content content">
      <img src="~@/assets/img/pages/configurator/bed.svg" />
      <h2>Combien de chambres souhaitez-vous ?</h2>
      <div class="choice-container">
        <app-radio-button
          :options="nbRoomsChoice.map(opt => ({ label: opt, name: opt }))"
          required
          v-model="nbRooms" />
      </div>
    </div>

    <div class="validate-btn-container">
      <app-button :disabled="isNextButtonDisabled" @click="validateStep">Valider</app-button>
    </div>
  </section>
</template>

<script>
export default {
  name: 'mobile-matchings',
  props: {
    currentStep: [Number],
  },
  data() {
    return {
      postalCodeInseeNumber: this.$route.query.postalCodeInseeNumber,
      nbRooms: this.$route.query.nbRooms,
      nbRoomsChoice: [1, 2, 3, 4, 5],
    };
  },
  computed: {
    isNextButtonDisabled() {
      switch (this.currentStep) {
        case 2:
          return !this.nbRooms;
        default:
          return false;
      }
    },
  },
  methods: {
    validateStep() {
      switch (this.currentStep) {
        case 2:
          this.$router.push({ query: { ...this.$route.query, nbRooms: this.nbRooms } });
          this.$emit('update:step', this.currentStep + 1);
          break;
        default:
          this.$emit('update:step', this.currentStep + 1);
      }
    },
  },
};
</script>

<style lang="sass">
.mobile-matchings-search
  display: flex
  flex-direction: column
  width: 90vw
  margin: 0 auto 6rem auto
  overflow-y: visible
  .content
    flex-grow: 2
  .validate-btn-container
    position: fixed
    bottom: 0
    left: 0
    width: 100vw
    padding: 5vw
    background: linear-gradient(180deg, rgba(32, 32, 32, 0) 0%, #202020 83.85%)
    .app-button
      width: 90vw

  // Etape Nb Chambres
  .rooms-content
    display: flex
    flex-direction: column
    gap: 2rem
    margin-top: 5vh
    align-items: center
    > img
      width: 2rem
    > h2
      color: $white
      text-align: center
    .app-radio-button
      > div
        justify-content: center
      input[type="radio"] ~ div
        background-color: $white
        width: 2rem
        height: 2rem
        display: flex
        align-items: center
        justify-content: center
        > span
          color: $subtitle
</style>
