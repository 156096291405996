<template>
  <div class="app-switch" :class="{ 'disabled': disabled }">
      <label>
        <span class="switch">
          <input type="checkbox"
            :checked="input"
            :disabled="disabled"
            @change="$emit('input', $event.target.checked)"/>
          <span class="slider"></span>
        </span>
        <slot></slot>
      </label>
  </div>
</template>

<script>

export default {
  model: {
    prop: 'input',
    event: 'input',
  },
  props: {
    input: [Boolean],
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="sass" scoped>
.app-switch
  display: inline-flex

  label
    @include paragraph
    position: relative
    user-select: none
    cursor: pointer
    display: flex
    align-items: center

  .switch
    position: relative
    display: inline-block
    width: 48px
    height: 24px
    margin-right: 8px

  input[type="checkbox"]
    position: absolute
    top: 0
    left: 0
    height: 0
    width: 0

    &:not(:disabled):focus + .slider
      @include drop-color($primary)

    &:not(:disabled):checked + .slider
      background-color: $primary

      &::before
        transform: translateX(24px)

  .slider
    position: absolute
    cursor: pointer
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-color: $info
    border-radius: 16px
    transition: 0.3s ease-in-out

    &::before
      position: absolute
      content: ""
      height: 20px
      width: 20px
      left: 2px
      bottom: 2px
      background-color: $off-white
      border-radius: 50%
      transition: 0.3s ease-in-out

.disabled
  label
    color: $info
    cursor: not-allowed
    pointer-events: none

  .checkmark
    background-color: $background !important

  .slider
    background-color: $line !important

    &::before
      background-color: $background
</style>
