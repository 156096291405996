import axios from 'axios';

const { VUE_APP_API_URL, VUE_APP_BRAND_ID } = process.env;

/**
 * Récupère tous les thèmes
 * @param {string} search: terme de recherche
 * @param {string} categoryId: catégorie à rechercher
 * @param {string} lotId: lot à rechercher
 */
const getAll = async (search, categoryId, lotId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/public/brands/${VUE_APP_BRAND_ID}/themes/`, {
      params: {
        ...(search && { search }),
        ...(categoryId && { categoryId }),
        ...(lotId && { lotId }),
      },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const theme = {};
theme.getAll = getAll;

export default theme;
