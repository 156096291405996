<template>
  <button
    class="app-button"
    :class="[theme, size]"
    :type="type"
    @click="handleClick"
  >
    <span v-if="icon !== ''" class="icon">
      <img class="img" :class="{ 'img-small': size }" :src='"@/assets/img/" + icon + ".svg"' alt="icon" />
    </span>
    <span class="text">
      <slot></slot>
    </span>
    <span v-if="hasArrow" class="icon arrow">
      <img class="img" :class="{ 'img-small': size }" src="@/assets/img/arrow-right.svg" alt="icon" />
    </span>
  </button>
</template>

<script>
export default {
  props: {
    type: {
      default: 'button',
      validator: (val) => ['button', 'submit'].indexOf(val) !== -1,
    },
    size: {
      type: String,
      validator: (val) => ['round', 'round-small', 'small', 'large'].indexOf(val) !== -1,
      default: 'large',
    },
    theme: {
      type: String,
      default: 'primary',
      validator: (val) => ['primary', 'secondary', 'warning', 'error', 'project'].indexOf(val) !== -1,
    },
    icon: {
      type: String,
      default: '',
      validator: (val) => ['', 'add', 'remove', 'filter', 'import', 'download', 'edit', 'archive', 'document'].indexOf(val) !== -1,
    },
    hasArrow: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick(evt) {
      this.$emit('click', evt);
    },
  },
};
</script>

<style lang='sass' scoped>
.app-button
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  padding: 11px 20px 12px
  outline: none
  color: $off-white
  border-radius: 4px
  border-width: 0
  height: auto
  transition: all 0.3s ease-in-out
  user-select: none
  cursor: pointer

  span.text
    @include button
    margin: 0
    color: $white
  .icon
    display: flex
    padding-right: 8px
    .img
      width: 14px
      height: 14px
    &.arrow
      padding-right: 0 !important
      padding-left: 8px

  &.round, &.round-small
    padding: 0
    border-radius: 50%
    box-shadow: $drop-min
    .icon
      padding-right: 0

  &.round
    width: 32px
    height: 32px
    .img-small
      width: 12px
      height: 12px

  &.round-small
    width: 20px
    height: 20px
    .img-small
      width: 10px
      height: 10px

  &.small
    padding: 8px 12px 7px 12px
    span
      @include label

  // Button primary
  &.primary
    background-color: $primary
    &:hover
      background-color: $primary-light
    &:active
      background-color: $primary-dark

  // Button secondary
  &.secondary
    background-color: transparent
    border: 1px solid currentcolor
    .text
      color: currentcolor
    &:hover
      background-color: rgba(255, 255, 255, 0.15)
    &:active
      background-color: rgba(255, 255, 255, 0.15)
      outline: 1px solid currentcolor

  // Button warning
  &.warning
    background-color: $error
    &:hover
      background-color: $error-light
    &:active
      background-color: $error-dark

  // Button error
  &.error
    background-color: $warning
    &:hover
      background-color: $warning-light
    &:active
      background-color: $warning-dark

  // Button project
  &.project
    background-color: $green-project
    &:hover
      background-color: lighten($green-project, 5)
    &:active
      background-color: darken($green-project, 5)

  // Disabled
  &.warning:disabled,
  &.primary:disabled,
  &.secondary:disabled,
  &.project:disabled
    background-color: $line
    pointer-events: none
    cursor: not-allowed
    span
      color: $body-color
</style>
