<template>
  <div class="hedifys-21 social-shares">
    <transition name="message">
      <div class="message-wrapper" v-if="visible">
        <waveGradient class="wave-gradient" />
        <div class="cell shrink close-modal">
          <close @click="hide" />
        </div>
        <div class="favorite-header" v-if="isFavorite">
          <img class="heart-gif" id="heart-gif" src="~@/assets/img/heart-animation.gif" alt="heart gif" />
          <h2 class="favorite-title">Cette version est maintenant en favori.</h2>
        </div>
        <div v-if="isFavorite" class="favorite-subtitle">
          <h3>Votre coup de coeur mérite d'être partagé !</h3>
        </div>
        <div v-if="isConfiguration">
          <h2 class="title">Envoyez à vos proches la maison<br/> de vos rêves.</h2>
        </div>
          <h2 v-if="!isFavorite && !isConfiguration" class="title">Faites découvrir à vos amis<br/> notre incroyable configurateur.</h2>
        <div class="social-network-container">
          <a id="share-btn-facebook" class="grid-x row facebook social-network" :href="getFacebookLink()" target="_blank">
            <div class="cell shrink">
              <facebook />
            </div>
            <div class="cell shrink">
              <p>Partager sur Facebook</p>
            </div>
          </a>
          <a id="share-btn-email" class="grid-x row email social-network" :href="getEmailLink()" target="_blank">
            <div class="cell shrink">
              <email />
            </div>
            <div class="cell shrink">
              <p>Partager via email</p>
            </div>
          </a>
          <a id="share-btn-whatsapp" class="grid-x row whatsapp social-network" :href="getWhatsAppLink()" target="_blank">
            <div class="cell shrink">
              <whatsapp />
            </div>
            <div class="cell shrink">
              <p>Partager via WhatsApp</p>
            </div>
          </a>
          <a id="share-btn-messenger" class="grid-x row messenger social-network" :href="getMessengerLink()" target="_blank">
            <div class="cell shrink">
              <messenger />
            </div>
            <div class="cell shrink">
              <p>Partager via Messenger</p>
            </div>
          </a>
          <a id="share-btn-snapchat" @click.prevent="globalMobileShare()" class="grid-x row snapchat social-network">
            <div class="cell shrink">
              <snapchat />
            </div>
            <div class="cell shrink">
              <p>Partager via Snapchat</p>
            </div>
          </a>
          <a id="share-btn-sms" class="grid-x row sms social-network" :href="getSMSLink()" target="_blank">
            <div class="cell shrink">
              <phone />
            </div>
            <div class="cell shrink">
              <p>Partager via SMS</p>
            </div>
          </a>
          <div class="grid-x row">
            <div class="cell auto">
              <app-input v-model="shareLink" ref="sharelink" disabled />
            </div>
            <div class="cell shrink">
              <app-button id="share-btn-copy" class="copy" @click="copy">Copier</app-button>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="screen">
      <div class="message-screen" v-if="visible" @click="hide" />
    </transition>
  </div>
</template>

<script>
import close from '@/assets/img/close.svg?inline';
import facebook from '@/assets/img/facebook.svg?inline';
import email from '@/assets/img/email.svg?inline';
import whatsapp from '@/assets/img/whatsapp.svg?inline';
import snapchat from '@/assets/img/snapchat.svg?inline';
import messenger from '@/assets/img/messenger.svg?inline';
import phone from '@/assets/img/phone.svg?inline';
import waveGradient from '@/assets/img/wave-gradient.svg?inline';
import AppEvent from '../../basics/utils/AppEvent';

export default {
  components: {
    facebook,
    email,
    whatsapp,
    snapchat,
    messenger,
    phone,
    close,
    waveGradient,
  },
  data() {
    return {
      visible: false,
      shareLink: null,
      isFavorite: false,
      isConfiguration: false,
      versionId: null,
    };
  },
  mounted() {
    this.shareLink = this.getUrl();
  },
  updated() {
    if (this.isFavorite) {
      this.shareLink = this.shareLink.replace('matchings?', `configuration/version?versionId=${this.versionId}&`);
      const img = document.getElementById('heart-gif');
      img.src = `${img.src.replace(/\?.*/, '')}?${Math.random()}`;
    }
    if (window.innerWidth < 768) this.shareLink = this.shareLink.replace('mobile/configuration', 'configuration/version');
  },
  methods: {
    copy() {
      navigator.clipboard.writeText(this.shareLink);
      this.$notification.show({ text: 'Lien copié !' });
    },
    getUrl() {
      return window.location.href;
    },
    getFacebookLink() {
      return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.shareLink)}`;
    },
    getEmailLink() {
      return `mailto:?subject=Maisons Alysia&body=Partage de Maisons Alysia: ${encodeURIComponent(this.shareLink)}`;
    },
    getWhatsAppLink() {
      return `https://api.whatsapp.com/send?phone=&text=Partage de Maisons Alysia :%20${encodeURIComponent(this.shareLink)}`;
    },
    getSnapchatLink() {
      return 'snapchat://';
    },
    getMessengerLink() {
      return `fb-messenger://share?link=${encodeURIComponent(this.shareLink)}`;
    },
    getSMSLink() {
      return `sms:?&body=Partage de Maisons Alysia: ${encodeURIComponent(this.shareLink)}`;
    },
    hide() {
      this.visible = false;
      this.isFavorite = false;
      this.isConfiguration = false;
    },
    show() {
      this.shareLink = this.getUrl();
      this.visible = true;
    },
    async globalMobileShare() {
      if (navigator.share) {
        await navigator.share({
          title: document.title,
          text: 'Regarde ce lien chez Maisons Alysia !',
          url: window.location.href,
        });
      }
    },
  },
  beforeMount() {
    AppEvent.$on('social-shares-show', (params) => {
      if (params.isFavorite) {
        this.isFavorite = true;
        this.versionId = params.versionId;
      }
      if (params.isConfiguration) {
        this.isConfiguration = true;
      }
      this.show(params);
    });
  },
  beforeDestroy() {
    AppEvent.$off('social-shares-show', (params) => {
      this.show(params);
    });
  },
};
</script>

<style lang="sass" scoped>
.social-shares
  @media (min-width: 1024px)
    .snapchat, .messenger
      display: none
  .row
    @include row
    margin-bottom: 15px
  h2
    font-size: 24px
    color: #FFF
    text-align: center
  .copy
    padding: 0 20px
    margin-left: 20px
  .facebook
    background-color: #3b5998
    path
      fill: #3b5998
    path:last-child
      fill: white
  .email
    background-color: lighten($secondary, 20%)
    svg path
      fill: white
  .whatsapp
    background-color: #25D366
    svg
      fill: white
  .messenger
    background-color: #018fff
  .snapchat
    background-color: #fdfc03
    border: 1px solid black
    div>p
      color: #000!important
  .sms
    background-color: lighten($secondary, 20%)
    svg
      fill: white
  .social-network-container
    .social-network
      padding: 10px
      border-radius: 10px
      text-decoration: none
      .cell
        display: flex
        align-items: center
      p
        color: white
        text-decoration: none
      svg
        margin-right: 20px
        width: 30px
        height: 30px
  .message-wrapper
    position: fixed
    top: 50%
    left: 50%
    width: 440px
    max-width: 500px
    max-height: 100vh
    margin: 0 auto
    padding: 0px 48px 16px 48px
    border-radius: $global-border-radius
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)
    background: white
    z-index: 1000
    transform: translate(-50%, -50%)
    overflow-y: scroll
    @include breakpoint(large down)
      top: initial
      left: 0
      right: 0
      bottom: 0
      width: initial
      padding: 20px
      border-radius: 0
      transform: none
    .close-modal
      position: absolute
      top: 4vh
      left: calc(50% - 3vw - 15px)
      z-index: 2
      svg
        cursor: pointer
        width: 14px
        height: 14px
        stroke: $title
        stroke-width: 2px
    .heart-gif
      width: 120px
      height: 120px
      filter: brightness(3)
    .favorite-title
      font-size: 20px
      text-align: center
      position: absolute
      left: 50
      top: 103px
    .favorite-subtitle
      h3
        font-size: 18px
        text-align: center
        margin-top: 4rem
    .wave-gradient
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 270px
      z-index: -1
      display: block
      margin-bottom: 2rem
  .message-screen
    position: fixed
    top: 0
    bottom: 0
    left: 0
    right: 0
    background: rgba(0,0,0,0.4)
    z-index: 800
  .favorite-header
    display: flex
    flex-direction: column
    align-items: center
    position: relative
  .message-buttons
    display: flex
    margin-top: 2rem
    > .app-button
      flex-grow: 1
  .text
    text-align: center
    @include body
    color: $body-color
  .title
    display: flex
    justify-content: center
    align-items: center
    margin-bottom: 4.5rem
    margin-top: 1.5rem
  .cell
    justify-content: center
    display: flex

  .screen-enter-active, .screen-leave-active
    transition: opacity .9s

  .screen-enter, .screen-leave-to
    opacity: 0

  .message-enter-active, .message-leave-active
    transition: transform .5s, opacity .3s

  .message-enter, .message-leave-to
    opacity: 0
    transform: translateY(-130px) translateX(-50%)

</style>
