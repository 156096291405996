<template>
  <div class="app-spinner" :class="{ small }">
    <span :style="spinnerStyle"></span>
  </div>
</template>

<script>
export default {
  props: {
    small: Boolean,
  },

  computed: {
    spinnerStyle() {
      return {
        '--border': `${this.small ? 2 : 4}px solid`,
        '--width': `calc(100% - ${this.small ? 4 : 8})px`,
        '--height': `calc((100% - ${this.small ? 4 : 8}px) / 2)`,
      };
    },
  },
};
</script>

<style lang="sass">
.app-spinner
  position: relative
  margin: auto
  width: 60px
  height: 60px

  &.small
    width: 1rem
    height: 1rem

  span
    display: block
    width: var(--width)
    height: var(--height)
    background-color: transparent
    border-top-left-radius: 100px
    border-top-right-radius: 100px
    border: var(--border)
    border-color: $primary
    border-bottom: 0
    animation: rotate-animation 1s linear infinite
    transform-origin: bottom

@keyframes rotate-animation
  0%
    transform: rotate(0deg)

  100%
    transform: rotate(360deg)
</style>
