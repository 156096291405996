import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

const getRates = async () => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/calculator/rates`);
    return response.data.data;
  } catch (er) {
    throw (er);
  }
};

const getBorrowingCapacity = async (data) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/calculator/borrowing-capacity`, data);
    return response.data.data;
  } catch (er) {
    throw (er);
  }
};

const getFundingPlan = async (data) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/calculator/funding-plan`, data);
    return response.data.data;
  } catch (er) {
    throw (er);
  }
};

const api = {};
api.getRates = getRates;
api.getBorrowingCapacity = getBorrowingCapacity;
api.getFundingPlan = getFundingPlan;

export default api;
